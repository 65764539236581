import {getSizedPropValue} from "@/containers/Constructor/containers/Editor/utils";

const syncFontStyle = (fontFamilyStringName, availableFonts, activeComponent, size, valueType, updateActiveComponentProp) => {
  const currentFontWeight =
    getSizedPropValue(activeComponent.props.fontWeight, size, false, valueType === 'general' ? undefined : valueType)
  const currentFontStyle =
    getSizedPropValue(activeComponent.props.fontStyle, size, false, valueType === 'general' ? undefined : valueType)
  const chosenFont = availableFonts.filter(f => f.fontFamily === fontFamilyStringName)
  if (chosenFont.length && !chosenFont.some((font) => font.fontWeight === currentFontWeight && font.fontStyle === currentFontStyle)) {
    updateActiveComponentProp({
      prop: {...activeComponent.props.fontStyle, key: 'fontStyle'},
      updatedProp: {
        ...activeComponent.props.fontStyle,
        value: {
          ...(!activeComponent.props.fontStyle.value
          || typeof activeComponent.props.fontStyle.value === 'string' ? {
            desktop: activeComponent.props.fontStyle.value
              || activeComponent.props.fontStyle.defaultValue
          } : activeComponent.props.fontStyle.value || {}),
          [size]: chosenFont[0].fontStyle
        }
      }
    })
  }
}

export default syncFontStyle
