import {bus} from "@/app/main";
import {getSizedPropValue} from "@/containers/Constructor/containers/Editor/utils";

const validateFormItem = (value, component, size, errorChildIndex = 0) => {
  const errors = []

  const rules = getSizedPropValue(component.props.rules, size)
  const type = getSizedPropValue(component.props.type, size)
  const trimmedValue = ['string', 'number'].includes(typeof value) ? String(value).trim() : value;
  if (rules) {
    rules.forEach(rule => {
      if (
        (rule.type === 'required' && (!trimmedValue || (trimmedValue instanceof Array && !trimmedValue.length)))
        || (rule.type === 'email' && !/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/.test(trimmedValue))
        || (rule.type === 'min' && (type === 'number' ? !Number.isNaN(Number(trimmedValue)) && Number(trimmedValue) < Number(rule.min) : trimmedValue.length < Number(rule.min)))
        || (rule.type === 'max' && (type === 'number' ? !Number.isNaN(Number(trimmedValue)) && Number(trimmedValue) > Number(rule.max) : trimmedValue.length > Number(rule.max)))
        || (rule.type === 'regex' && !new RegExp(rule.regex).test(value))
      ) {
        errors.push(rule.message)
      }
    })
  }

  const errorChild = component.children[errorChildIndex]
  bus.$emit(`update-form-errors-${errorChild.id}`, errors)
}

export default validateFormItem
