var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isHidden)?_c(`Constructor${_vm.component.name}`,_vm._b({tag:"component",staticClass:"stream-base-component",class:[
    `stream-component-id--${_vm.component.id}`,
    {
      'stream--empty': _vm.isEmpty,
      [`stream-component-id--${_vm.component.id}-${_vm.component.dynamicOrder}`]: _vm.component.dynamicOrder > 0,
    }
  ],style:(!_vm.component.passStyle ? _vm.style : undefined),attrs:{"passedStyle":_vm.component.passStyle ? _vm.style : undefined,"component":_vm.component,"empty":_vm.isEmpty,"data-dynamic-index":_vm.component.dynamicIndex,"data-dynamic-provider-index":_vm.component.dynamicProviderIndex},on:{"click":_vm.onSetActive,"mouseenter":_vm.onMouseEnter,"mouseleave":_vm.onMouseLeave}},'component',_vm.attributes,false),[_vm._t("default")],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }