<template>
  <Accordion class="stream-editor-components" expanded-by-default block>
    <template #top>
      Components
    </template>
    <nested-draggable class="stream-editor-components__body" :children="childrenComponents">
      <components-item
        v-for="component in childrenComponents"
        :key="component.id"
        :component="component"
        :inner-expanded-by-default="!initialized"
        @scroll-to-active="onScrollToActive"
      />
    </nested-draggable>
  </Accordion>
</template>

<script>
import {mapGetters} from "vuex";
import Accordion from '@/containers/Constructor/containers/Editor/components/Accordion'
import ComponentsItem from './containers/ComponentsItem'
import NestedDraggable from './containers/NestedDraggable'

const getOffsetTopRelativeToParents = (el, elMustHaveClass, stopEl) => {
  if (el && el !== stopEl) {
    if (elMustHaveClass)
      if (el.classList.contains(elMustHaveClass))
        return el.offsetTop + getOffsetTopRelativeToParents(el.parentElement, stopEl)
    else return el.offsetTop + getOffsetTopRelativeToParents(el.parentElement, stopEl)
  }
  return 0
}

export default {
  name: "Components",

  components: {
    Accordion,
    ComponentsItem,
    NestedDraggable
  },

  props: {
    initialized: {
      type: Boolean,
      default: undefined
    }
  },

  computed: {
    ...mapGetters({
      nestedComponents: "constructor2/nestedComponents"
    }),

    childrenComponents () {
      return this.nestedComponents
    }
  },

  methods: {
    onScrollToActive () {
      this.$nextTick(() => {
        const elementToScrollTo = this.$el.querySelector(`.stream-editor-components-item--exact-active`)
        if (elementToScrollTo) {
          elementToScrollTo.scrollIntoView({
            behavior: "smooth",
            block: "center"
          })
        }
      })
    }
  }
};
</script>

<style lang="scss" src="./index.scss"></style>
