<template>
  <div class="constructor2-data-sources-table">
    <draggable-table
      with-search
      :with-select="withSelect"
      with-selected-only
      with-parse-media
      :with-paginating="withPaginating"
      parse-links
      parse-media
      drag-with-selected-only
      :draggable="draggable"
      :table-columns="formattedColumns"
      :table-data="data"
      :selected-ids="selectedDataIds"
      :no-data-text="noDataText"
      :loading="loading"
      :with-pagination="{ page, perPage: 10 }"
      @input-page="page = $event"
      @toggle-selected="onToggleSelected"
      @reorder="onReorder"
    />
  </div>
</template>

<script>
import DraggableTable from "@/components/draggable-table";

export default {
  name: "Constructor2DataSourcesTable",

  components: {
    DraggableTable,
  },

  props: {
    data: {
      type: Array,
      default: () => []
    },
    selectedDataIds: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    isDataSourceSelected: {
      type: Boolean,
      default: false
    },
    draggable: {
      type: Boolean,
      default: false
    },
    withSelect: {
      type: Boolean,
      default: false
    },
    withPaginating: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      page: 1,
    }
  },

  computed: {
    formattedColumns() {
      if (this.isDataSourceSelected && this.data.length) {
        return [
          ...Object.keys(this.data[0]).filter(column => column !== 'id').map(column => {
            let width
            let minWidth
            let maxWidth

            switch (column) {
              case 'price':
                width = '80px'
                minWidth = '80px'
                maxWidth = '80px'
                break;
              case 'url':
                width = '130px'
                minWidth = '130px'
                maxWidth = '130px'
                break;
              case 'name':
                width = '100%'
                minWidth = '300px'
                break;
              case 'image':
              case 'picture':
                width = '150px'
                minWidth = '150px'
                maxWidth = '150px'
                break;
              default:
                width = '200px'
                minWidth = '200px'
            }

            return ({
              key: column,
              title: column,
              width,
              minWidth,
              maxWidth
            })
          })
        ]
      }
      return []
    },

    noDataText() {
      return this.isDataSourceSelected ? 'No Data Available' : 'No Data Source Selected'
    }
  },

  methods: {
    onToggleSelected (item) {
      this.$emit('select-data-source-item', item)
    },

    onReorder (reorderedArray) {
      this.$emit('reorder', reorderedArray)
    }
  }
}
</script>

