<template>
  <Accordion block expanded-by-default>
    <template #top>
      Form Button Settings
    </template>

    <div class="stream-editor-parameter-panel__prop">
      <div class="stream-editor-parameter-panel__prop-title">Type</div>
      <C2PSelect :prop="{...activeComponent.props.type, key: 'type'}" :items="buttonTypes" :size="size" :active-size="size" :value-type="valueType" />
    </div>

    <div class="stream-editor-parameter-panel__prop">
      <div class="stream-editor-parameter-panel__prop-title">Connected form</div>
      <C2PSelect :prop="{...activeComponent.props.connectedForm, key: 'connectedForm'}" :items="allForms" :size="size" :active-size="size" :value-type="valueType" />
    </div>
  </Accordion>
</template>

<script>
import Accordion from '@/containers/Constructor/containers/Editor/components/Accordion'
import settings from "@/modules/constructor2/editor/parameters-panel/mixins/settings";
import C2PSelect from "@/modules/constructor2/editor/parameters-panel/molecules/select";
import {mapGetters} from "vuex";

export default {
  name: "ParametersPanelSettingsFormButton",

  mixins: [settings],

  components: {
    C2PSelect,
    Accordion
  },

  data() {
    return {
      buttonTypes: [
        { id: 'submit', label: 'Submit' },
        { id: 'button', label: 'Button' },
      ]
    }
  },

  computed: {
    ...mapGetters({
      addedComponents: "constructor2/addedComponents"
    }),

    allForms() {
      return this.addedComponents
        .filter(component => component.componentKey === 'Form')
        .map(component => ({ id: component.id, label: `[${ component.id }] ${ component.displayName || component.name }` }))
    },
  }
}
</script>
