<template>
  <div>
    <parameters-panel-multi-input
      v-for="(rule, index) in value"
      :key="`${activeComponent.id}-${rule.type}-${index}`"
      no-add
      :value="[rule]"
      :value-template="getValueTemplate(rule.type)"
      @input="onChange(index, $event)"
    />
    <button class="parameters-panel-multi-input__button" @click="onAddNewRule">
      <Plus />
    </button>
  </div>
</template>

<script>
// NOTE: form-rules DO NOT DEPEND ON valueType

import multiParameterItem from "@/modules/constructor2/editor/parameters-panel/mixins/multiParameterItem";
import ParametersPanelMultiInput
  from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/components/MultiInput";
import {mapState} from "vuex";
import Plus from "@/components/ui/icons/constructor/Plus.vue";

const getValueModelFromTemplate = template => template.reduce((obj, item) => ({ ...obj, [item.prop]: '' }), {})

export default {
  name: "ParametersPanelParametersFormRulesParameter",

  mixins: [multiParameterItem],

  props: {
    ruleTypes: Object
  },

  components: {
    ParametersPanelMultiInput,
    Plus
  },

  computed: {
    ...mapState({
      activeComponent: state => state.constructor2.activeComponent
    }),

    formattedValueType() {
      return 'general'
    },

    computedRuleTypes() {
      const types = []

      if (!this.ruleTypes || this.ruleTypes.required) {
        types.push({
          id: 'required',
          label: 'Required'
        })
      }
      if (!this.ruleTypes || this.ruleTypes.email) {
        types.push({
          id: 'email',
          label: 'Email'
        })
      }
      if (!this.ruleTypes || this.ruleTypes.regex) {
        types.push({
          id: 'regex',
          label: 'Regex'
        })
      }
      if (!this.ruleTypes || this.ruleTypes.min) {
        types.push({
          id: 'min',
          label: 'Minimum'
        })
      }
      if (!this.ruleTypes || this.ruleTypes.max) {
        types.push({
          id: 'max',
          label: 'Maximum'
        })
      }

      return types
    },

    valueTemplates() {
      const typeSelect = {
        type: 'select',
        options: this.computedRuleTypes,
        prop: 'type',
        name: 'Type',
        placeholder: 'Choose type'
      }
      const message = {
        prop: 'message',
        name: 'Message',
        placeholder: 'Error message'
      }

      return {
        required: [
          typeSelect,
          message
        ],
        email: [
          typeSelect,
          message
        ],
        regex: [
          typeSelect,
          {
            prop: 'regex',
            name: 'Regex',
            placeholder: 'Enter the regex'
          },
          message
        ],
        min: [
          typeSelect,
          {
            prop: 'min',
            name: 'Min',
            placeholder: 'Enter the minimum'
          },
          message
        ],
        max: [
          typeSelect,
          {
            prop: 'max',
            name: 'Max',
            placeholder: 'Enter the maximum'
          },
          message
        ],
        noType: [
          typeSelect
        ]
      }
    }
  },

  methods: {
    getValueTemplate(ruleType = undefined) {
      if (!ruleType) {
        return this.valueTemplates.noType
      }
      return this.valueTemplates[ruleType]
    },

    onAddNewRule() {
      this.onMultiInput([...this.value, getValueModelFromTemplate(this.valueTemplates.noType)])
    },

    onChange(index, value) {
      if (value.length) {
        let updatedValue = _.cloneDeep(value[0]);
        // compare the new value to see if the type has been changed
        if (this.value[index].type !== updatedValue.type) {
          updatedValue = _.cloneDeep(getValueModelFromTemplate(this.getValueTemplate(updatedValue.type)))
          Object.entries(value[0]).forEach(([key, value]) => {
            if (updatedValue.hasOwnProperty(key)) {
              updatedValue[key] = value
            }
          })
        }
        // spread values in one object and update the value
        const values = _.cloneDeep(this.value)
        values[index] = updatedValue;
        // save the new values
        this.onMultiInput(values)
      } else {
        // if value.length === 0, it means the rule was removed
        this.onMultiInput(this.value.filter((_, vIndex) => vIndex !== index))
      }
    }
  }
}
</script>
