<template>
  <div
    class="stream-components-form-radio-button"
    @click.stop="$emit('click')"
    @mouseenter="$emit('mouseenter')"
    @mouseleave="$emit('mouseleave')"
    :style="radioStyle"
  >
    <div class="stream-components-form-radio-button__content">
      <input
        class="stream-components-form-radio-button__input"
        type="radio"
        :name="name"
        :value="value"
      />
      <div
        class="stream-components-form-radio-button__dot"
        :style="dotStyle"
      />
      <workarea-component
        :component="{...component.children[0], customRender: false}"
        :id="`stream-components-form-error-${component.children[0].id}`"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {getSizedPropValue} from "@/containers/Constructor/containers/Editor/utils";
import {debounce} from "lodash";
import validateFormItem from "@/modules/constructor2/editor/shared/utils/validate-form-item";

export default {
  name: "FormRadioButtonElement",

  components: {
    WorkareaComponent: () => import("@/containers/Constructor/containers/Editor/containers/EditorPanel/containers/WorkareaComponents/containers/Component")
  },

  props: {
    component: {
      type: Object,
      default: () => {}
    },
    passedStyle: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      localValue: false
    };
  },

  computed: {
    ...mapState({
      activeSize: state => state.constructor2.activeSize,
    }),
    ...mapGetters({
      addedComponents: "constructor2/addedComponents"
    }),

    radioGroupComponent() {
      return this.component.radioGroupIndex !== undefined ? this.addedComponents[this.component.radioGroupIndex] : undefined
    },

    name () {
      if (this.radioGroupComponent) {
        return getSizedPropValue(this.radioGroupComponent.props?.name, this.activeSize) || undefined
      }
      return ''
    },

    value () {
      return getSizedPropValue(this.component?.props?.value, this.activeSize) || undefined
    },

    activeColor () {
      return getSizedPropValue(this.component?.props?.activeColor, this.activeSize) || undefined
    },

    inactiveColor () {
      return getSizedPropValue(this.component?.props?.inactiveColor, this.activeSize) || undefined
    },

    radioStyle() {
      return {
        '--radio-active-color': this.activeColor,
        '--radio-inactive-color': this.inactiveColor,
      }
    },

    dotStyle() {
      return {
        border: getSizedPropValue(this.component?.props?.radioBorder, this.activeSize) || undefined,
        width: getSizedPropValue(this.component?.props?.radioWidth, this.activeSize) || undefined,
        height: getSizedPropValue(this.component?.props?.radioHeight, this.activeSize) || undefined,
        '--dot-color': getSizedPropValue(this.component?.props?.dotColor, this.activeSize) || undefined,
        '--dot-width': getSizedPropValue(this.component?.props?.dotWidth, this.activeSize) || undefined,
        '--dot-height': getSizedPropValue(this.component?.props?.dotHeight, this.activeSize) || undefined,
      }
    }
  },

  watch: {
    activeSize() {
      this.debouncedValidate()
    }
  },
}
</script>

<style lang="scss" src="./index.scss"></style>
