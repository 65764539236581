<template>
  <Accordion block :expanded-by-default="accordionExpandedByDefault">
    <template #top>
      Row Settings
    </template>

    <div class="stream-editor-parameter-panel__prop">
      <div class="stream-editor-parameter-panel__prop-title">Space between children</div>
      <ParameterInput :prop="{...activeComponent.props.size, key: 'size'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
    </div>

    <div class="stream-editor-parameter-panel__prop">
      <div class="stream-editor-parameter-panel__prop-title">Align</div>
      <ParameterAlign :prop="{...activeComponent.props.align, key: 'align'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
    </div>

    <div class="stream-editor-parameter-panel__prop">
      <div class="stream-editor-parameter-panel__prop-title">Direction</div>
      <ParameterTabSelect :prop="{...activeComponent.props.direction, key: 'direction'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
    </div>

    <div class="stream-editor-parameter-panel__prop">
      <div class="stream-editor-parameter-panel__prop-title">Wrap</div>
      <ParameterSelect :prop="{...activeComponent.props.flexWrap, key: 'flexWrap'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
    </div>
  </Accordion>
</template>

<script>
import Accordion from '@/containers/Constructor/containers/Editor/components/Accordion'

import ParameterInput from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Input";
import ParameterTabSelect
  from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/TabSelect";
import ParameterAlign from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Align";
import ParameterSelect from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Select";
import settings from "@/modules/constructor2/editor/parameters-panel/mixins/settings";

export default {
  name: "ParametersPanelSettingsRow",

  mixins: [settings],

  components: {
    ParameterSelect,
    ParameterAlign,
    ParameterTabSelect,
    ParameterInput,
    Accordion
  },
}
</script>
