<template>
  <Accordion block expanded-by-default>
    <template #top>
      Table Column Settings
    </template>

    <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.padding">
      <div class="stream-editor-parameter-panel__prop-title">Padding</div>
      <ParameterInput :prop="{...activeComponent.props.padding, key: 'padding'}" :size="size" :active-size="size" :value-type="valueType" />
    </div>

    <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.colspan">
      <div class="stream-editor-parameter-panel__prop-title">Conspan</div>
      <ParameterInput :prop="{...activeComponent.props.colspan, key: 'colspan'}" :size="size" :active-size="size" :value-type="valueType" />
    </div>

    <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.border">
      <div class="stream-editor-parameter-panel__prop-title">Border</div>
      <parameter-border :prop="{...activeComponent.props.border, key: 'border'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
    </div>
    <div class="stream-editor-parameter-panel__props" v-if="activeComponent.props.borderTop || activeComponent.props.borderRight">
      <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.borderTop">
        <div class="stream-editor-parameter-panel__prop-title">Border top</div>
        <ParameterInput :prop="{...activeComponent.props.borderTop, key: 'borderTop'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
      </div>
      <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.borderRight">
        <div class="stream-editor-parameter-panel__prop-title">Border right</div>
        <ParameterInput :prop="{...activeComponent.props.borderRight, key: 'borderRight'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
      </div>
    </div>

    <div class="stream-editor-parameter-panel__props" v-if="activeComponent.props.borderBottom || activeComponent.props.borderLeft">
      <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.borderBottom">
        <div class="stream-editor-parameter-panel__prop-title">Border bottom</div>
        <ParameterInput :prop="{...activeComponent.props.borderBottom, key: 'borderBottom'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
      </div>
      <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.borderLeft">
        <div class="stream-editor-parameter-panel__prop-title">Border left</div>
        <ParameterInput :prop="{...activeComponent.props.borderLeft, key: 'borderLeft'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
      </div>
    </div>
  </Accordion>
</template>

<script>
import settings from "@/modules/constructor2/editor/parameters-panel/mixins/settings";
import Accordion from '@/containers/Constructor/containers/Editor/components/Accordion'
import ParameterInput from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Input";
import ParameterBorder from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Border";

export default {
  name: "ParametersPanelSettingsTableColumn",

  mixins: [settings],

  components: {
    Accordion,
    ParameterInput,
    ParameterBorder,
  },
}
</script>
