<template>
  <div
    class="stream-components-youtube"
    @click.stop="$emit('click')"
    @mouseenter="$emit('mouseenter')"
    @mouseleave="$emit('mouseleave')"
  >
    <iframe
      :src="`https://www.youtube.com/embed/${youtubeId}?enablejsapi=1&rel=0&autoplay=0`"
      frameborder="0"
    ></iframe>
    <slot />
  </div>
</template>

<script>
import {mapState} from "vuex";
import {getSizedPropValue} from "@/containers/Constructor/containers/Editor/utils";

export default {
  name: "YoutubeElement",

  props: {
    component: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    ...mapState({
      activeSize: state => state.constructor2.activeSize
    }),

    youtubeId () {
      return getSizedPropValue(this.component?.props?.youtubeId, this.activeSize)
    }
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
