<template>
  <div class="stream-constructor-sync-content-selects">
    <vn-select-add
      label="Locale"
      :value="selectedLocale"
      :options="availableLocales"
      @input="$emit('select-locale', $event)"
      :loading="loading"
      no-clear
      no-remove-option
    />
    <vn-select-add
      label="Template"
      :value="selectedTemplate"
      :options="availableTemplates"
      key-by="id"
      text-by="name"
      @input="$emit('select-template', $event)"
      :loading="loading"
      no-clear
      no-remove-option
    />
    <vn-select-add
      label="Page"
      :value="selectedPage"
      :options="availablePages"
      key-by="id"
      text-by="name"
      @input="$emit('select-page', $event)"
      :loading="loading"
      no-clear
      no-remove-option
    />
  </div>
</template>

<script>
import VnSelectAdd from "@/components/ui/vn-select-add";

export default {
  name: "SyncContent",

  components: {
    VnSelectAdd,
  },

  props: {
    epackData: Object,
    availableLocales: Array,
    selectedLocale: String,
    selectedTemplate: String,
    selectedPage: String,
    loading: Boolean
  },

  computed: {
    availableTemplates () {
      if (!this.selectedLocale)
        return []
      return Object.keys(this.epackData.components?.[this.selectedLocale] || {})
    },

    availablePages () {
      if (!this.selectedTemplate)
        return []
      return Object.keys(this.epackData.components?.[this.selectedLocale]?.[this.selectedTemplate] || {})
    },
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
