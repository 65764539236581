<template>
  <div class="c2-change-sizes">
    <button class="c2-change-sizes__button" @click="toggleModal">
      <svg fill="#000000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M4,13.743l-1,.579a1,1,0,0,0-.366,1.366l1.488,2.578a1,1,0,0,0,1.366.366L6.5,18.05a1.987,1.987,0,0,1,1.986,0l.02.011a1.989,1.989,0,0,1,1,1.724V21a1,1,0,0,0,1,1h3a1,1,0,0,0,1-1V19.782a1.985,1.985,0,0,1,.995-1.721l.021-.012a1.987,1.987,0,0,1,1.986,0l1.008.582a1,1,0,0,0,1.366-.366l1.488-2.578A1,1,0,0,0,21,14.322l-1-.579a1.994,1.994,0,0,1-1-1.733v-.021a1.991,1.991,0,0,1,1-1.732l1-.579a1,1,0,0,0,.366-1.366L19.876,5.734a1,1,0,0,0-1.366-.366L17.5,5.95a1.987,1.987,0,0,1-1.986,0L15.5,5.94a1.989,1.989,0,0,1-1-1.724V3a1,1,0,0,0-1-1h-3a1,1,0,0,0-1,1V4.294A1.856,1.856,0,0,1,8.57,5.9l-.153.088a1.855,1.855,0,0,1-1.853,0L5.49,5.368a1,1,0,0,0-1.366.366L2.636,8.312A1,1,0,0,0,3,9.678l1,.579A1.994,1.994,0,0,1,5,11.99v.021A1.991,1.991,0,0,1,4,13.743ZM12,9a3,3,0,1,1-3,3A3,3,0,0,1,12,9Z"/></svg>
    </button>

    <the-modal class="c2-change-sizes__modal" v-show="modalOpen" @close="toggleModal">
      <div class="the-modal__title">Change sizes</div>

      <div class="c2-change-sizes__list">
        <div class="c2-change-sizes__list-item" v-for="item in localSizes" :key="item.slug">
          <img v-if="item.withIcon" svg-inline :src="require(`@/containers/Constructor/icons/sizes/${item.slug}.svg`)" alt="" class="c2-change-sizes__list-item-icon">
          <div class="c2-change-sizes__list-item-label">{{ item.name }}</div>
          <input class="the-modal__input c2-change-sizes__list-item-input" type="number" :value="item.value" @change="onChange($event.target.value, item)">
        </div>
      </div>

      <div class="the-modal__buttons">
        <button class="the-modal__button the-modal__button--black" @click="onSave">Save</button>
        <button class="the-modal__button" @click="toggleModal">Close</button>
      </div>
    </the-modal>
  </div>
</template>

<script>
import TheModal from "@/components/TheModal";
import {bus} from "@/app/main";
import {mapMutations, mapState} from "vuex";
import {AVAILABLE_SIZES} from "@/store/modules/constructor2/consts";

export default {
  name: "C2ChangeSizes",

  components: {
    TheModal
  },

  data() {
    return {
      modalOpen: false,

      localSizes: [
        { slug: 'desktop', name: 'Desktop', value: 0, withIcon: true },
        { slug: 'laptop', name: 'Laptop', value: 0, withIcon: true },
        { slug: 'tablet', name: 'Tablet', value: 0, withIcon: true },
        { slug: 'mobile', name: 'Mobile', value: 0, withIcon: true },
      ]
    }
  },

  created() {
    this.syncSizes()
    bus.$on('change-sizes-sync', () => {
      this.syncSizes()
    })
  },

  computed: {
    ...mapState({
      epackData: state => state.constructor2.epackData
    })
  },

  methods: {
    ...mapMutations({
      updateSizes: "constructor2/updateSizes"
    }),

    toggleModal() {
      this.modalOpen = !this.modalOpen
    },

    onChange(value, size) {
      this.$set(this.localSizes, this.localSizes.findIndex(item => item.slug === size.slug), { ...size, value})
    },

    syncSizes() {
      this.localSizes.forEach((size, index) => {
        this.$set(this.localSizes, index, { ...size, value: this.epackData.sizes?.[size.slug] || AVAILABLE_SIZES[size.slug] || 0})
      })
    },

    onSave() {
      this.updateSizes(this.localSizes.reduce((sizes, size) => ({ ...sizes, [size.slug]: Number(size.value || 0) }), {}))
      this.toggleModal()
    }
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
