<template>
  <Accordion block expanded-by-default>
    <template #top>
      Form Input Settings
    </template>

    <div class="stream-editor-parameter-panel__prop">
      <div class="stream-editor-parameter-panel__prop-title">Type</div>
      <ParameterSelect :prop="{...activeComponent.props.type, key: 'type'}" :size="size" :active-size="size"
                      :value-type="valueType"/>
    </div>

    <div class="stream-editor-parameter-panel__prop">
      <div class="stream-editor-parameter-panel__prop-title">Name</div>
      <ParameterInput :prop="{...activeComponent.props.name, key: 'name'}" :size="size" :active-size="size"
                      :value-type="valueType"/>
    </div>

    <div class="stream-editor-parameter-panel__prop">
      <div class="stream-editor-parameter-panel__prop-title">Placeholder</div>
      <ParameterInput :prop="{...activeComponent.props.placeholder, key: 'placeholder'}" :size="size"
                      :active-size="size" :value-type="valueType"/>
    </div>

    <div class="stream-editor-parameter-panel__prop" :style="{ marginTop: '12px', marginBottom: '12px' }">
      <parameters-panel-parameter-checkbox
        :prop="{...activeComponent.props.autocomplete, key: 'autocomplete'}"
        :true-value="''"
        :false-value="'new-password'"
        :size="size"
        :active-size="size"
        :value-type="valueType"
      />
    </div>

    <div class="stream-editor-parameter-panel__props">
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Color</div>
        <ParameterColorPick :prop="{...activeComponent.props.color, key: 'color'}" :size="size" :active-size="size" :value-type="valueType" />
      </div>
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Font style</div>
        <ParameterFontStyle :prop="{...activeComponent.props.fontStyle, key: 'fontStyle'}" :size="size" :active-size="size" :value-type="valueType" />
      </div>
    </div>

    <div class="stream-editor-parameter-panel__props">
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Font family</div>
        <ParameterFontFamily :prop="{...activeComponent.props.fontFamily, key: 'fontFamily'}" :size="size" :active-size="size" :value-type="valueType" />
      </div>
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Font weight</div>
        <ParameterFontWeight :prop="{...activeComponent.props.fontWeight, key: 'fontWeight'}" :size="size" :active-size="size" :value-type="valueType" />
      </div>
    </div>

    <div class="stream-editor-parameter-panel__props">
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Font size</div>
        <ParameterFontSize :prop="{...activeComponent.props.fontSize, key: 'fontSize'}" :size="size" :active-size="size" :value-type="valueType" />
      </div>
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Line height</div>
        <ParameterLineHeight :prop="{...activeComponent.props.lineHeight, key: 'lineHeight'}" :size="size" :active-size="size" :value-type="valueType" />
      </div>
    </div>

    <div class="stream-editor-parameter-panel__prop">
      <div class="stream-editor-parameter-panel__prop-title">Text align</div>
      <ParameterAlign :prop="{...activeComponent.props.textAlign, key: 'textAlign'}" :size="size" :active-size="size" :value-type="valueType" />
    </div>
  </Accordion>
</template>

<script>
import settings from "@/modules/constructor2/editor/parameters-panel/mixins/settings";
import ParameterInput from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Input.vue";
import Accordion from "@/containers/Constructor/containers/Editor/components/Accordion/index.vue";
import ParameterLineHeight
  from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/LineHeight";
import ParameterFontSize
  from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/FontSize";
import ParameterFontWeight
  from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/FontWeight";
import ParameterFontFamily
  from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/FontFamily";
import ParameterFontStyle
  from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/FontStyle";
import ParameterColorPick
  from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/ColorPick";
import ParameterAlign from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Align";
import ParametersPanelParameterCheckbox from "@/modules/constructor2/editor/parameters-panel/atoms/parameter-checkbox";
import ParameterSelect from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Select";

export default {
  name: "ParametersPanelSettingsFormInput",

  mixins: [settings],

  components: {
    ParameterSelect,
    ParametersPanelParameterCheckbox,
    ParameterInput,
    ParameterAlign,
    ParameterLineHeight,
    ParameterFontSize,
    ParameterFontWeight,
    ParameterFontFamily,
    ParameterFontStyle,
    ParameterColorPick,
    Accordion,
  },
}
</script>
