<template>
  <div class="stream-editor-components-panel">
    <components :initialized="initialized" />
    <add-component title="Add component" :components="addElements" />
    <add-component title="Saved components" :components="customComponents" custom />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Components from './containers/Components'
import AddComponent from './containers/AddComponent'

export default {
  name: "ComponentsPanel",

  components: {
    Components,
    AddComponent,
  },

  props: {
    initialized: {
      type: Boolean,
      default: undefined
    }
  },

  computed: {
    ...mapGetters({
      addElements: 'constructor2/addElements',
      customComponents: 'constructor2/customComponents',
    })
  }
};
</script>
