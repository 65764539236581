<template>
  <component
    v-if="!isHidden"
    :is="`Constructor${component.name}`"
    class="stream-base-component"
    :class="[
      `stream-component-id--${component.id}`,
      {
        'stream--empty': isEmpty,
        [`stream-component-id--${component.id}-${component.dynamicOrder}`]: component.dynamicOrder > 0,
      }
    ]"
    :style="!component.passStyle ? style : undefined"
    :passedStyle="component.passStyle ? style : undefined"
    :component="component"
    :empty="isEmpty"
    :data-dynamic-index="component.dynamicIndex"
    :data-dynamic-provider-index="component.dynamicProviderIndex"
    v-bind="attributes"
    @click="onSetActive"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <slot />
  </component>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import {computeComponentStyle, getSizedPropValue} from "@/containers/Constructor/containers/Editor/utils";
import ConstructorRow from './components/Row'
import ConstructorColumn from './components/Column'
import ConstructorText from './components/Text'
import ConstructorImage from './components/Image'
import ConstructorVideo from './components/Video'
import ConstructorYoutube from './components/Youtube'
import ConstructorAccordion from './components/Accordion'
import ConstructorBubbles from './components/Bubbles'
import ConstructorTable from './components/Table'
import ConstructorSlider from './components/Slider'
import ConstructorLink from './components/Link'
import ConstructorPopover from './components/Popover'
import ConstructorScript from './components/Script'
import ConstructorThe360Viewer from './components/360Viewer'
import ConstructorForm from './components/Form'
import ConstructorFormError from './components/FormError'
import ConstructorFormInput from './components/FormInput'
import ConstructorFormButton from './components/FormButton'
import ConstructorFormCheckbox from './components/FormCheckbox'
import ConstructorFormRadioButton from './components/FormRadioButton'
import ConstructorFormRadioGroup from './components/FormRadioGroup'
import ConstructorFormSelect from './components/FormSelect'

export default {
  name: "BaseComponent",

  components: {
    ConstructorRow,
    ConstructorColumn,
    ConstructorText,
    ConstructorImage,
    ConstructorVideo,
    ConstructorYoutube,
    ConstructorAccordion,
    ConstructorBubbles,
    ConstructorTable,
    ConstructorSlider,
    ConstructorLink,
    ConstructorPopover,
    ConstructorScript,
    ConstructorThe360Viewer,
    ConstructorForm,
    ConstructorFormError,
    ConstructorFormInput,
    ConstructorFormSelect,
    ConstructorFormButton,
    ConstructorFormCheckbox,
    ConstructorFormRadioButton,
    ConstructorFormRadioGroup,
  },

  props: {
    component: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      hovered: false
    }
  },

  computed: {
    ...mapState({
      activeSize: state => state.constructor2.activeSize,
      vw: state => state.constructor2.windowWidth,
      vh: state => state.constructor2.windowHeight,
    }),
    ...mapGetters({
      addedComponents: 'constructor2/addedComponents'
    }),

    isEmpty () {
      return this.component?.withChildren && !(this.component?.children?.length > 0 || !!this.$slots.default) && !this.style.backgroundImage
    },

    isHidden () {
      return getSizedPropValue(this.component.props.hide, this.activeSize) === 'on'
    },

    style () {
      return computeComponentStyle(this.component, this.activeSize, this.vw, this.vh, this.hovered ? 'hover' : undefined)
    },

    attributes: {
      deep: true,
      cache: false,
      get () {
        if (this.component.props?.attributes) {
          return getSizedPropValue(this.component.props.attributes, this.activeSize)
              ?.filter(v => v.key)
              ?.map(v => ({[v.key]: v.value || ''}))
              || []
        }
        return []
      }
    }
  },

  methods: {
    ...mapMutations({
      setActiveComponent: 'constructor2/setActiveComponent'
    }),

    onMouseEnter () {
      this.hovered = true
    },

    onMouseLeave () {
      this.hovered = false
    },

    onSetActive() {
      this.setActiveComponent({
        component: this.addedComponents.find(c => c.id === this.component.id),
        dynamicOrder: this.component.dynamicOrder
      })
    }
  },
};
</script>
