<template>
  <Accordion block :expanded-by-default="accordionExpandedByDefault">
    <template #top>
      Video Settings
    </template>

    <div class="stream-editor-parameter-panel__prop">
      <div class="stream-editor-parameter-panel__prop-title">Video</div>
      <parameter-file :prop="{...activeComponent.props.src, fileType: 'video', key: 'src'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
    </div>

    <div class="stream-editor-parameter-panel__prop">
      <div class="stream-editor-parameter-panel__prop-title">Thumbnail</div>
      <parameter-file :prop="{...activeComponent.props.poster, fileType: 'image', key: 'poster'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
    </div>

    <div class="stream-editor-parameter-panel__props">
      <div class="stream-editor-parameter-panel__prop">
        <parameters-panel-parameter-checkbox :prop="{...activeComponent.props.loop, key: 'loop'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
      </div>
      <div class="stream-editor-parameter-panel__prop">
        <parameters-panel-parameter-checkbox :prop="{...activeComponent.props.autoplay, key: 'autoplay'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
      </div>
    </div>
  </Accordion>
</template>

<script>
import Accordion from '@/containers/Constructor/containers/Editor/components/Accordion'

import ParameterFile from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/File";
import ParametersPanelParameterCheckbox from "@/modules/constructor2/editor/parameters-panel/atoms/parameter-checkbox";
import settings from "@/modules/constructor2/editor/parameters-panel/mixins/settings";

export default {
  name: "ParametersPanelSettingsVideo",

  mixins: [settings],

  components: {
    Accordion,
    ParameterFile,
    ParametersPanelParameterCheckbox,
  },
}
</script>
