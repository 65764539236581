<template>
  <the-modal
    class="dynamic-configurator-modal"
    v-if="component"
    @close="onClose"
  >
    <div class="the-modal__title">Configure {{ componentType }}</div>

    <vn-select-add
      class="dynamic-configurator-modal__data-source-select"
      label="Data Source"
      :value="dataSource"
      :options="dataSources"
      key-by="id"
      text-by="name"
      @input="onSelectDataSource"
      no-clear
      no-remove-option
    />

    <div class="dynamic-configurator-modal__toggles" v-if="componentType === 'Receiver'">
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Content</div>
        <Input :value="content" @input="onUpdateProp($event, component.props.content, 'content')" />
        <div class="dynamic-configurator-modal__buttons" v-if="dataSourceItemKeys.length">
          <span class="dynamic-configurator-modal__buttons-text">Add</span>
          <button
            class="dynamic-configurator-modal__buttons-item"
            v-for="key in dataSourceItemKeys"
            :key="key"
            @click="onAddKeyToContent(key)"
          >
            {{ key }}
          </button>
        </div>
      </div>
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Hide on empty data</div>
        <TabSelect
          :value="{ id: hideOnEmptyData, content: hideOnEmptyData }"
          :items="[{ id: 'on', content: 'on' }, { id: 'off', content: 'off' }]"
          @input="onUpdateProp($event.id, component.props.hideOnEmptyData, 'hideOnEmptyData')"
        />
      </div>
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Fallback on empty data</div>
        <TabSelect
          :value="{ id: fallbackOnEmptyData, content: fallbackOnEmptyData }"
          :items="[{ id: 'on', content: 'on' }, { id: 'off', content: 'off' }]"
          @input="onUpdateProp($event.id, component.props.fallbackOnEmptyData, 'fallbackOnEmptyData')"
        />
      </div>
    </div>

    <constructor2-data-sources-table
      draggable
      with-select
      :is-data-source-selected="isDataSourceSelected"
      :data="dataSourceList"
      :selected-data-ids="selectedDataIds"
      @select-data-source-item="onSelectDataSourceItem"
      @reorder="onReorder"
    />
  </the-modal>
</template>

<script>
import TheModal from "@/components/TheModal";
import {bus} from "@/app/main";
import {mapGetters, mapMutations, mapState} from "vuex";
import VnSelectAdd from "@/components/ui/vn-select-add";
import {getPropValueBySize} from "@/containers/Constructor/containers/Editor/utils";
import dcopy from "deep-copy";
import Constructor2DataSourcesTable from "@/components/constructor2/DataSourcesTable";
import TabSelect from "@/containers/Constructor/components/TabSelect";
import Input from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/components/Input";

export default {
  name: "DynamicConfiguratorModal",

  components: {
    TheModal,
    TabSelect,
    Input,
    VnSelectAdd,
    Constructor2DataSourcesTable,
  },

  data() {
    return {
      component: null
    }
  },

  computed: {
    ...mapState({
      activeSize: state => state.constructor2.activeSize
    }),
    ...mapGetters({
      addedComponents: 'constructor2/addedComponents',
      selectedDataSources: 'constructor2/selectedDataSources',
    }),

    componentType() {
      if (this.component) {
        if (this.component.name === 'DynamicDataProvider') return 'Provider'
        if (this.component.name === 'DynamicDataReceiver') return 'Receiver'
      }
      return ''
    },

    dataProvider() {
      if (this.componentType === 'Provider')
        return this.component
      if (this.componentType === 'Receiver' && this.component?.relatedDynamicId)
        return this.addedComponents.find(component => component.id === this.component.relatedDynamicId)
      return undefined
    },

    dataSources () {
      if (!Object.keys(this.selectedDataSources || {}).length) return []
      return Object.entries(this.selectedDataSources).map(([id, source]) => ({id, ...source}))
    },

    dataSourceId() {
      if (this.dataProvider)
        return getPropValueBySize(this.dataProvider.props.dataSource?.value, this.activeSize)
      return undefined
    },

    dataSource() {
      if (!Object.keys(this.selectedDataSources || {}).length) return {}
      return (this.dataSourceId
          && Object
            .entries(this.selectedDataSources)
            .find(([id]) => id.toString() === (this.dataSourceId).toString())?.[1])
        || {}
    },

    isDataSourceSelected() {
      return Object.keys(this.dataSource).length > 0;
    },

    dataSourceList() {
      return Object.values(this.dataSource.items || {})
    },

    selectedDataIds() {
      return (this.dataProvider?.props.providedDataSourceItems?.value &&
        getPropValueBySize(this.dataProvider.props.providedDataSourceItems.value, this.activeSize)) || []
    },

    // for receiver
    content() {
      if (this.componentType === 'Receiver')
        return getPropValueBySize(this.component.props.content?.value, this.activeSize) || ''
      return ''
    },

    hideOnEmptyData() {
      if (this.componentType === 'Receiver')
        return getPropValueBySize(this.component.props.hideOnEmptyData.value, this.activeSize)
          || this.component.props.hideOnEmptyData.defaultValue
      return undefined
    },

    fallbackOnEmptyData() {
      if (this.componentType === 'Receiver')
        return getPropValueBySize(this.component.props.fallbackOnEmptyData.value, this.activeSize)
          || this.component.props.fallbackOnEmptyData.defaultValue
      return undefined
    },

    dataSourceItemKeys() {
      return this.dataSourceList.length ? Object.keys(this.dataSourceList[0]) : []
    }
  },

  mounted() {
    bus.$on('dynamic-configurator', this.onConfigure)
  },

  methods: {
    ...mapMutations({
      updateComponentProp: "constructor2/updateComponentProp"
    }),

    onClose() {
      this.component = null
    },

    onConfigure(component) {
      this.component = component
    },

    onSelectDataSource(newDataSource) {
      if (this.dataProvider) {
        this.updateComponentProp({
          component: this.dataProvider,
          prop: {...this.dataProvider.props.dataSource, key: 'dataSource'},
          updatedProp: {
            ...this.dataProvider.props.dataSource,
            value: {
              ...(this.dataProvider.props.dataSource?.value || {}),
              [this.activeSize]: newDataSource.id
            }
          }
        })
        // resetting provided source items once the source changes
        const providedDataSourceItems = dcopy(this.dataProvider.props.providedDataSourceItems)
        if (providedDataSourceItems?.value?.[this.activeSize]) {
          delete providedDataSourceItems.value[this.activeSize]
          if (!Object.keys(providedDataSourceItems).length)
            delete providedDataSourceItems.value
          this.updateComponentProp({
            component: this.dataProvider,
            prop: providedDataSourceItems,
            updatedProp: providedDataSourceItems
          })
        }
      }
    },

    onSelectDataSourceItem(item) {
      if (this.dataProvider) {
        const prop = {...this.dataProvider.props.providedDataSourceItems, key: 'providedDataSourceItems'}
        const propValue = getPropValueBySize(prop.value, this.activeSize)?.slice() || []
        const dataIndexInProvided = propValue.findIndex(v => v === item.id)
        if (dataIndexInProvided === -1) {
          propValue.push(item.id)
        } else {
          propValue.splice(dataIndexInProvided, 1)
        }
        this.updateComponentProp({
          component: this.dataProvider,
          prop,
          updatedProp: {
            ...prop,
            value: {
              ...(prop.value || {}),
              [this.activeSize]: propValue
            }
          }
        })
      }
    },

    onReorder(reorderedArray) {
      if (this.dataProvider) {
        const prop = {...this.dataProvider.props.providedDataSourceItems, key: 'providedDataSourceItems'}
        this.updateComponentProp({
          component: this.dataProvider,
          prop,
          updatedProp: {
            ...prop,
            value: {
              ...(prop.value || {}),
              [this.activeSize]: reorderedArray.map(item => item.id)
            }
          }
        })
      }
    },

    // for receiver
    onUpdateProp(value, prop, propName) {
      this.updateComponentProp({
        component: this.component,
        prop: {...prop, key: propName},
        updatedProp: {
          ...prop,
          value: {
            ...(prop.value || {}),
            [this.activeSize]: value
          }
        }
      })
    },

    onAddKeyToContent(key) {
      this.onUpdateProp((this.content || '') + `{{${key}}}`, this.component.props.content, 'content')
    }
  },

  beforeDestroy() {
    bus.$off('dynamic-configurator', this.onConfigure)
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
