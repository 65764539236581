<template>
  <button
    class="parameters-panel-button"
    :class="[styleTypeClass]"
    @click="$emit('click')"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "ParametersPanelButton",

  props: {
    styleType: {
      type: String,
      default: null
    }
  },

  computed: {
    styleTypeClass () {
      return this.styleType ? `parameters-panel-button--${this.styleType}` : null
    }
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
