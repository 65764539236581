<template>
  <div
    class="stream-editor-tooltip__icon-button stream-editor-tooltip__icon-button--configure"
    @click="onConfigure"
    v-if="dynamicIndex"
  >
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 458.317 458.317" style="enable-background:new 0 0 458.317 458.317;" xml:space="preserve" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'">
		<path d="M446.185,179.159h-64.768c-2.536-7.702-5.636-15.15-9.26-22.29l45.818-45.818c4.737-4.737,4.737-12.416,0-17.152
			L364.416,40.34c-4.737-4.737-12.416-4.737-17.152,0l-45.818,45.818c-7.14-3.624-14.587-6.724-22.289-9.26V12.131
			c0.001-6.699-5.429-12.129-12.128-12.129h-75.743c-6.698,0-12.129,5.43-12.129,12.128v64.768
			c-7.702,2.535-15.149,5.636-22.29,9.26L111.05,40.341c-4.737-4.737-12.416-4.737-17.152,0L40.339,93.9
			c-4.737,4.736-4.737,12.416,0,17.152l45.817,45.817c-3.624,7.14-6.725,14.588-9.26,22.29H12.129C5.43,179.159,0,184.59,0,191.288
			v75.743c0,6.698,5.43,12.128,12.129,12.128h64.768c2.536,7.702,5.636,15.149,9.26,22.29L40.34,347.266
			c-4.737,4.736-4.737,12.416,0,17.152l53.559,53.559c4.737,4.736,12.416,4.736,17.152,0l45.817-45.817
			c7.14,3.624,14.587,6.725,22.29,9.26v64.768c0,6.698,5.43,12.128,12.129,12.128h75.743c6.698,0,12.129-5.43,12.129-12.128v-64.768
			c7.702-2.535,15.149-5.636,22.289-9.26l45.818,45.817c4.737,4.736,12.416,4.736,17.152,0l53.559-53.559
			c4.737-4.737,4.737-12.416,0-17.152l-45.817-45.817c3.624-7.14,6.724-14.587,9.26-22.289h64.768
			c6.698,0,12.129-5.43,12.129-12.128v-75.743C458.314,184.59,452.884,179.159,446.185,179.159z M229.157,289.542
			c-33.349,0-60.384-27.035-60.384-60.384s27.035-60.384,60.384-60.384s60.384,27.035,60.384,60.384
			S262.506,289.542,229.157,289.542z"/>
</svg>
  </div>
</template>

<script>
import {bus} from "@/app/main";
import {mapGetters} from "vuex";

export default {
  name: "DynamicConfigure",

  data () {
    return {
      dynamicIndex: undefined
    }
  },

  computed: {
    ...mapGetters({
      addedComponents: "constructor2/addedComponents"
    })
  },

  methods: {
    reinit(componentEl) {
      this.dynamicIndex = componentEl.getAttribute('data-dynamic-index')
      this.dynamicProviderIndex = componentEl.getAttribute('data-dynamic-provider-index')
    },

    onConfigure() {
      const dynamicComponent = this.addedComponents[this.dynamicIndex]
      bus.$emit('dynamic-configurator', {
        ...dynamicComponent,
        relatedDynamicId: this.dynamicProviderIndex >= 0 && this.dynamicIndex !== this.dynamicProviderIndex
          ? this.addedComponents[this.dynamicProviderIndex].id : undefined
      })
    }
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
