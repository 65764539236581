import {getPropValueBySize} from "@/containers/Constructor/containers/Editor/utils";
import parameterItem from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/mixins/parameterItem";

export default {
  mixins: [ parameterItem ],

  computed: {
    value () {
      if (this.prop?.value) {
        return (!!this.prop.value[this.size])
          && (this.prop.value[this.size] || this.prop.value) || []
      }
      return []
    },

    placeholderValue () {
      if (this.prop && (this.prop.value || this.prop.defaultValue)) {
        let value = getPropValueBySize(this.prop.value, this.size) || (this.prop.value) || []
        if (value) return value
        value = getPropValueBySize(this.prop.defaultValue, this.size) || (this.prop.defaultValue) || []
        if (value) return value
      }
      return []
    }
  },

  methods: {
    onMultiInput (v) {
      this.updateActiveComponentProp({
        prop: this.prop,
        updatedProp: {
          ...this.prop,
          value: {
            ...(this.prop.value instanceof Array ? {
              desktop: this.prop.value
            } : this.prop.value || {}),
            [`${this.formattedValueType !== 'general' ? `${this.formattedValueType}_`: ''}${this.size}`]: v
          }
        }
      })
    }
  }
}
