<template>
  <Select
    :value="value || placeholderValue"
    @input="updateProp($event)"
    :placeholder="prop.placeholder"
    :noOptionsText="noOptionsText"
    :multiple="multiple"
    :options="items"
  />
</template>

<script>
import Select from '@/modules/shared/atoms/select';
import parameterItem from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/mixins/parameterItem";

export default {
  name: "C2PSelect",

  components: {
    Select
  },

  props: {
    items: Array,
    noOptionsText: String,
    multiple: Boolean
  },

  mixins: [ parameterItem ],
}
</script>
