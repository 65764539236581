<template>
  <div
    class="stream-components-slider"
    :class="{ 'stream-components-slider--empty': empty }"
    @click.stop="$emit('click')"
    @mouseenter="$emit('mouseenter')"
    @mouseleave="$emit('mouseleave')"
  >
    <template v-if="slides.length">
      <div class="stream-components-slider__container" :style="containerStyle">
        <div class="stream-components-slider__wrapper" :style="wrapperStyle">
          <div
            class="stream-components-slider__slide stream-base-component"
            :class="[
              `stream-component-id--${slide.id}`,
              {'stream-base-component--active': isSlideActive(activeComponent, slide)}
            ]"
            :style="getStyle(slide, activeSize)"
            v-for="(slide) in slides"
            :key="`slide-${component.id}-${slide.id}-${slide.dynamicDataId}`"
            @click.stop="makeSlideActive(slide)"
            @mouseenter="onMouseEnterSlide(slide)"
            @mouseleave="onMouseLeaveSlide()"
          >
            <workarea-component
              v-for="(innerComponent) in getChildren(slide)"
              :key="`slide-inner-${component.id}-${slide.id}-${slide.dynamicDataId}-${innerComponent.id}`"
              :component="innerComponent"
              :dynamic-data-id="slide.dynamicDataId"
              :dynamic-order="slide.dynamicOrder"
            />
          </div>
        </div>
        <template v-if="!navigationArrowSvgCode">
          <button
            class="stream-components-slider__navigation-button stream-components-slider__navigation-button--prev stream-components-slider__navigation-button--default"
            :style="navigationButtonStyle"
            v-show="!isBeginning"
            @click.stop="slidePrev"
          >
            <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M30 0C13.4575 0 0 13.4575 0 30C0 46.5425 13.4575 60 30 60C46.5425 60 60 46.5425 60 30C60 13.4575 46.5425 0 30 0ZM39.2675 31.7675L26.7675 44.2675C26.28 44.755 25.64 45 25 45C24.36 45 23.7199 44.755 23.2324 44.2675C22.255 43.29 22.255 41.71 23.2324 40.7325L33.965 30L23.2325 19.2675C22.2551 18.29 22.2551 16.71 23.2325 15.7325C24.21 14.7551 25.79 14.7551 26.7675 15.7325L39.2675 28.2326C40.245 29.21 40.245 30.79 39.2675 31.7675Z" fill="#000000"></path></svg>
          </button>
          <button
            class="stream-components-slider__navigation-button stream-components-slider__navigation-button--next stream-components-slider__navigation-button--default"
            :style="navigationButtonStyle"
            v-show="!isEnd"
            @click.stop="slideNext"
          >
            <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M30 0C13.4575 0 0 13.4575 0 30C0 46.5425 13.4575 60 30 60C46.5425 60 60 46.5425 60 30C60 13.4575 46.5425 0 30 0ZM39.2675 31.7675L26.7675 44.2675C26.28 44.755 25.64 45 25 45C24.36 45 23.7199 44.755 23.2324 44.2675C22.255 43.29 22.255 41.71 23.2324 40.7325L33.965 30L23.2325 19.2675C22.2551 18.29 22.2551 16.71 23.2325 15.7325C24.21 14.7551 25.79 14.7551 26.7675 15.7325L39.2675 28.2326C40.245 29.21 40.245 30.79 39.2675 31.7675Z" fill="#000000"></path></svg>
          </button>
        </template>
        <template v-else>
          <button
            class="stream-components-slider__navigation-button stream-components-slider__navigation-button--prev"
            :style="navigationButtonStyle"
            v-show="!isBeginning"
            @click.stop="slidePrev"
            v-html="navigationArrowSvgCode"
          />
          <button
            class="stream-components-slider__navigation-button stream-components-slider__navigation-button--next"
            :style="navigationButtonStyle"
            v-show="!isEnd"
            @click.stop="slideNext"
            v-html="navigationArrowSvgCode"
          />
        </template>
      </div>
      <div class="stream-components-slider__pagination" :style="bulletsStyle" v-if="showBullets">
        <div
          class="stream-components-slider__pagination-bullet"
          :class="{'stream-components-slider__pagination-bullet--active': currentSlideIndex === i}"
          v-for="(slide, i) in slides.slice(0, this.slides.length - this.slidesPerView + 1)"
          :key="`slider-bullet-${component.id}-${slide.id}-${i}`"
          @click.stop="slideTo(i)"
        />
      </div>
    </template>
    <slot/>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {computeComponentStyle, getSizedPropValue} from "@/containers/Constructor/containers/Editor/utils";

export default {
  name: "SliderElement",

  components: {
    WorkareaComponent: () => import('../../../../index')
  },

  props: {
    empty: {
      type: Boolean,
      default: false
    },
    component: {
      type: Object,
      default: () => {
      }
    }
  },

  data() {
    return {
      currentSlideIndex: 0,
      hoveredSlide: null
    }
  },

  watch: {
    slidesPerView () {
      this.currentSlideIndex = 0
    },

    'slides.length' () {
      this.currentSlideIndex = 0
    }
  },

  computed: {
    ...mapState({
      activeComponent: state => state.constructor2.activeComponent,
      activeSize: state => state.constructor2.activeSize,
      vw: state => state.constructor2.windowWidth,
      vh: state => state.constructor2.windowHeight,
    }),

    bulletsStyle() {
      return {
        '--bullet-color': getSizedPropValue(this.component?.props?.bulletColor, this.activeSize),
        '--active-bullet-color': getSizedPropValue(this.component?.props?.activeBulletColor, this.activeSize)
      }
    },

    wrapperStyle() {
      return {
        transform: `translateX(-${
          Math.max(Math.min(this.currentSlideIndex * this.slideWidth, (this.slides.length - this.slidesPerView) * this.slideWidth), 0)
        }%)`
      }
    },

    containerStyle () {
      return {
        '--navigation-arrow-color': getSizedPropValue(this.component?.props?.arrowColor, this.activeSize),
      }
    },

    navigationButtonStyle () {
      return {
        width: getSizedPropValue(this.component?.props?.arrowWidth, this.activeSize) || '46px',
        height: getSizedPropValue(this.component?.props?.arrowHeight, this.activeSize) || '46px',
      }
    },

    navigationArrowSvgCode () {
      return getSizedPropValue(this.component?.props?.leftArrowSvgCode, this.activeSize) || ''
    },

    slides() {
      return this.getChildren(this.component)
    },

    isBeginning() {
      return this.currentSlideIndex <= 0
    },

    isEnd() {
      return this.currentSlideIndex >= this.slides.length - this.slidesPerView
    },

    showBullets () {
      return getSizedPropValue(this.component?.props?.showBullets, this.activeSize) === 'on' || false
    },

    slidesPerView () {
      return getSizedPropValue(this.component?.props?.slidesPerView, this.activeSize) || 1
    },

    slideWidth () {
      return 100 / this.slidesPerView
    }
  },

  methods: {
    ...mapMutations({
      remove: 'constructor2/removeComponent',
      setActiveComponent: 'constructor2/setActiveComponent'
    }),

    onResize () {
      setTimeout(() => {
        this.vw = document.querySelector('.stream-editor-editor-panel__container')?.offsetWidth
        this.vh = document.querySelector('.stream-editor-editor-panel__container')?.offsetHeight
      }, 1000)
    },

    slideNext() {
      if (!this.isEnd) {
        this.currentSlideIndex++
      }
    },

    slidePrev() {
      if (!this.isBeginning) {
        this.currentSlideIndex--
      }
    },

    slideTo(index) {
      this.currentSlideIndex = index
    },

    makeSlideActive(slide) {
      this.setActiveComponent({component: slide, dynamicOrder: slide.dynamicOrder})
    },

    getStyle(component, activeSize) {
      const width = `${this.slideWidth}%`
      return {
        ...computeComponentStyle(component, activeSize, this.vw, this.vh, this.hoveredSlide === component.id ? 'hover' : undefined),
        width,
        flex: `0 0 ${width}`
      }
    },

    isSlideActive(activeComponent, slide) {
      return slide && activeComponent && slide.id === activeComponent.id
    },

    getChildren(component) {
      return component?.children || []
    },

    onMouseEnterSlide (slide) {
      this.hoveredSlide = slide.id
    },

    onMouseLeaveSlide () {
      this.hoveredSlide = null
    },
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
