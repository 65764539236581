<template>
  <div class="the-modal">
    <div class="the-modal__bg" @click="onClose"/>
    <div class="the-modal__card">
      <div class="the-modal__card-content">
        <div class="the-modal__close" @click="onClose">
          <Close/>
        </div>
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
import Close from "@/components/TheModal/resources/Close";

export default {
  name: "TheModal",
  components: {Close},
  methods: {
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.the-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000000;

  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000000;
    opacity: 0.68;
  }

  &__header {
    display: flex;

    > * {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    .the-modal {
      &__button {
        font-size: 12px;
        padding: 2px 8px;
      }
    }
  }

  &__close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 21px;
    right: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      width: 14px;
      height: 14px;
    }

    &:hover {
      svg {
        path {
          fill: #000000;
        }
      }
    }
  }

  &__card {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    border-radius: 6px;
    width: 518px;
    max-width: calc(100% - 40px);
    overflow: hidden;
    cursor: default;

    &-content {
      padding: 20px 40px 40px;
      max-height: 80vh;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  &__title {
    margin-top: 25px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 32px;
  }

  &__input {
    background: #ffffff;
    border: 1px solid #d6dee2;
    border-radius: 6px;
    width: 100%;
    padding: 13px 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
  }

  &__buttons {
    display: flex;
    margin-top: 32px;

    > *:not(:last-child) {
      margin-right: 12px;
    }
  }

  &__button {
    border: 1px solid #d6dee2;
    border-radius: 6px;
    padding: 13px 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    background: #ffffff;
    cursor: pointer;

    &--black {
      background: #000000;
      border-color: #000000;
      color: #ffffff;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
</style>
