<template>
  <div
    class="stream-components-row"
    :class="{
      'stream-components-row--empty': empty,
      'stream-components-row--vertical': direction === 'vertical',
      'stream-components-row--horizontal': direction === 'horizontal',
      'stream-components-row--align-start': align === 'start',
      'stream-components-row--align-center': align === 'center',
      'stream-components-row--align-end': align === 'end'
    }"
    :style="{
      '--stream-components-row-size': size,
    }"
    @click.stop="$emit('click')"
    @mouseenter="$emit('mouseenter')"
    @mouseleave="$emit('mouseleave')"
  >
    <slot />
  </div>
</template>

<script>
import {mapState} from "vuex";
import {getSizedPropValue} from "@/containers/Constructor/containers/Editor/utils";

export default {
  name: "RowElement",

  props: {
    empty: {
      type: Boolean,
      default: false
    },
    component: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    ...mapState({
      activeSize: state => state.constructor2.activeSize
    }),

    direction () {
      return getSizedPropValue(this.component?.props?.direction, this.activeSize)
    },

    align () {
      return getSizedPropValue(this.component?.props?.align, this.activeSize)
    },

    size () {
      return getSizedPropValue(this.component?.props?.size, this.activeSize)
    },
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
