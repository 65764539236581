<template>
  <div />
</template>

<script>
import {getSizedPropValue} from "@/containers/Constructor/containers/Editor/utils";
import {bus} from "@/app/main";
import {mapState} from "vuex";

const setInnerHTML = function(elm, html) {
  elm.innerHTML = html;
  Array.from(elm.querySelectorAll("script")).forEach( oldScript => {
    const newScript = document.createElement("script");
    Array.from(oldScript.attributes)
      .forEach( attr => newScript.setAttribute(attr.name, attr.value) );
    newScript.appendChild(document.createTextNode(oldScript.innerHTML));
    oldScript.parentNode.replaceChild(newScript, oldScript);
  });
}

export default {
  name: "ScriptElement",

  props: {
    component: {
      type: Object,
      default: () => {}
    }
  },

  mounted () {
    bus.$on('constructor-run-script', this.onRunEvent)
    if (this.auto) {
      this.runCode()
    }
  },

  computed: {
    ...mapState({
      activeComponent: state => state.constructor2.activeComponent,
    }),

    auto () {
      return getSizedPropValue(this.component?.props?.auto, 'desktop') === 'on' || false
    },

    code () {
      return getSizedPropValue(this.component?.props?.code, 'desktop') || ''
    }
  },

  watch: {
    code () {
      if (this.auto) {
        this.runCode()
      }
    },

    auto (n, o) {
      // if old value was false and new value is true
      if (n && !o) {
        this.runCode()
      }
    }
  },

  methods: {
    onRunEvent () {
      if (this.component.id === this.activeComponent?.id) {
        this.runCode()
      }
    },

    runCode () {
      if (this.$el)
        setInnerHTML(this.$el, this.code)
    }
  },

  beforeDestroy() {
    bus.$off('constructor-run-script', this.onRunEvent)
  }
}
</script>
