<template>
  <Accordion block :expanded-by-default="accordionExpandedByDefault">
    <template #top>
      Link Settings
    </template>

    <div class="stream-editor-parameter-panel__prop">
      <div class="stream-editor-parameter-panel__prop-title">URL</div>
      <ParameterUrl :prop="{...activeComponent.props.url, key: 'url'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
    </div>

    <div class="stream-editor-parameter-panel__prop">
      <div class="stream-editor-parameter-panel__prop-title">Target</div>
      <ParameterTabSelect :prop="{...activeComponent.props.target, key: 'target'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
    </div>
  </Accordion>
</template>

<script>
import Accordion from '@/containers/Constructor/containers/Editor/components/Accordion'

import ParameterTabSelect
  from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/TabSelect";
import ParameterUrl from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Url";
import settings from "@/modules/constructor2/editor/parameters-panel/mixins/settings";

export default {
  name: "ParametersPanelSettingsLink",

  mixins: [settings],

  components: {
    ParameterUrl,
    ParameterTabSelect,
    Accordion
  },
}
</script>
