<template>
  <div class="parameter-font-size">
    <number-input-select
      class="parameter-font-size__input-area"
      :input-value="fontSize.number"
      :select-value="fontSize.suffix"
      :select-items="['px', '%', 'rem', 'em']"
      @change="onNumberInput"
      @select-change="onSuffixChange"
    />
  </div>
</template>

<script>
import parameterItem from "../../mixins/parameterItem";
import NumberInputSelect from '../../components/NumberInputSelect'

export default {
  name: "ParameterFontSize",

  components: {
    NumberInputSelect
  },

  mixins: [ parameterItem ],

  computed: {
    fontSize () {
      const fullValue = this.value || this.placeholderValue
      return {
        number: fullValue.match(/(\d+)/)?.[0] || '',
        suffix: fullValue.match(/%|rem|px|em/)?.[0] || ''
      }
    }
  },

  methods: {
    onNumberInput (value) {
      const numberValue = value?.match(/(\d+)/)?.[0] || ''
      let suffixValue = this.fontSize.suffix
      if (value.includes('p')) suffixValue = 'px'
      else if (value.includes('%')) suffixValue = '%'
      else if (value.includes('r')) suffixValue = 'rem'
      else if (value.includes('e')) suffixValue = 'em'
      else if (!suffixValue) suffixValue = 'px'
      this.updateProp(`${numberValue}${suffixValue}`)
    },

    onSuffixChange (suffix) {
      this.updateProp(`${this.fontSize.number}${suffix}`)
    }
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
