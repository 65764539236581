<template>
  <parameters-panel-settings-bubble :size="size" :value-type="valueType" />
</template>

<script>
import ParametersPanelSettingsBubble from "@/modules/constructor2/editor/parameters-panel/molecules/settings-bubble";

export default {
  name: "ParametersPanelParametersBubble",

  props: {
    size: {
      type: String,
      default: 'desktop'
    },
    valueType: {
      type: String,
      default: 'general'
    }
  },

  components: {
    ParametersPanelSettingsBubble,
  }
}
</script>
