<template>
  <the-modal class="save-component-modal" v-if="component" @close="onClose">
    <div class="the-modal__title">Save component</div>
    <input type="text" v-model="componentName" placeholder="Custom name" class="the-modal__input">
    <div class="the-modal__buttons">
      <div class="the-modal__button the-modal__button--black" @click="onSave">Save</div>
      <div class="the-modal__button" @click="onClose">Cancel</div>
    </div>
  </the-modal>
</template>

<script>
import TheModal from "@/components/TheModal";
import {mapMutations} from "vuex";
import {showToastError} from "@/services/Helpers/HelperToast";
import {bus} from "@/app/main";

export default {
  name: "SaveComponentModal",

  components: {
    TheModal
  },

  data () {
    return {
      componentName: '',
      component: null
    }
  },

  mounted () {
    bus.$on('save-component', (component) => {
      this.component = component
    })
  },

  methods: {
    ...mapMutations({
      saveCustomComponent: "constructor2/saveCustomComponent"
    }),

    onSave () {
      if (this.componentName.trim()) {
        try {
          this.saveCustomComponent({...this.component, customName: this.componentName.trim()})
          this.onClose()
        } catch (e) {
          if (e === 'already-exists')
            showToastError(`Custom component with name ${this.componentName.trim()} already exists`, this.$toast)
          else {
            showToastError(e, this.$toast)
            console.error(e)
          }
        }
      } else {
        showToastError('You must give a name to the custom component', this.$toast)
      }
    },

    onClose () {
      this.componentName = ''
      this.component = null
    }
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
