<template>
  <Accordion block :expanded-by-default="accordionExpandedByDefault">
    <template #top>
      Dimension
    </template>

    <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.width">
      <div class="stream-editor-parameter-panel__prop-title">Width</div>
      <ParameterCurMinMaxInput :prop="{...activeComponent.props.width, key: 'width'}" :size="size" :active-size="size" :value-type="valueType"  @has-value="onHaveValues" />
    </div>

    <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.height">
      <div class="stream-editor-parameter-panel__prop-title">Height</div>
      <ParameterCurMinMaxInput :prop="{...activeComponent.props.height, key: 'height'}" :size="size" :active-size="size" :value-type="valueType"  @has-value="onHaveValues" />
    </div>

    <div class="stream-editor-parameter-panel__props" v-if="activeComponent.props.margin || activeComponent.props.padding">
      <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.margin">
        <div class="stream-editor-parameter-panel__prop-title">Margin</div>
        <ParameterInput :prop="{...activeComponent.props.margin, key: 'margin'}" :size="size" :active-size="size" :value-type="valueType"  @has-value="onHaveValues" />
      </div>

      <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.padding">
        <div class="stream-editor-parameter-panel__prop-title">Padding</div>
        <ParameterInput :prop="{...activeComponent.props.padding, key: 'padding'}" :size="size" :active-size="size" :value-type="valueType"  @has-value="onHaveValues" />
      </div>
    </div>

    <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.position">
      <div class="stream-editor-parameter-panel__prop-title">Position</div>
      <ParameterSelect :prop="{...activeComponent.props.position, key: 'position'}" :size="size" :active-size="size" :value-type="valueType"  @has-value="onHaveValues" />
    </div>

    <div class="stream-editor-parameter-panel__props" v-if="activeComponent.props.top || activeComponent.props.right">
      <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.top">
        <div class="stream-editor-parameter-panel__prop-title">Top</div>
        <ParameterInput :prop="{...activeComponent.props.top, key: 'top'}" :size="size" :active-size="size" :value-type="valueType"  @has-value="onHaveValues" />
      </div>
      <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.right">
        <div class="stream-editor-parameter-panel__prop-title">Right</div>
        <ParameterInput :prop="{...activeComponent.props.right, key: 'right'}" :size="size" :active-size="size" :value-type="valueType"  @has-value="onHaveValues" />
      </div>
    </div>

    <div class="stream-editor-parameter-panel__props" v-if="activeComponent.props.bottom || activeComponent.props.left">
      <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.bottom">
        <div class="stream-editor-parameter-panel__prop-title">Bottom</div>
        <ParameterInput :prop="{...activeComponent.props.bottom, key: 'bottom'}" :size="size" :active-size="size" :value-type="valueType"  @has-value="onHaveValues" />
      </div>
      <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.left">
        <div class="stream-editor-parameter-panel__prop-title">Left</div>
        <ParameterInput :prop="{...activeComponent.props.left, key: 'left'}" :size="size" :active-size="size" :value-type="valueType"  @has-value="onHaveValues" />
      </div>
    </div>

    <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.zIndex">
      <div class="stream-editor-parameter-panel__prop-title">Z-index</div>
      <ParameterInput :prop="{...activeComponent.props.zIndex, key: 'zIndex'}" :size="size" :active-size="size" :value-type="valueType"  @has-value="onHaveValues" />
    </div>
  </Accordion>
</template>

<script>
import Accordion from '@/containers/Constructor/containers/Editor/components/Accordion'
import ParameterInput from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Input";
import ParameterCurMinMaxInput
  from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/CurMinMaxInput";
import ParameterSelect from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Select";
import settings from "@/modules/constructor2/editor/parameters-panel/mixins/settings";

export default {
  name: "ParametersPanelParametersDimension",

  mixins: [settings],

  components: {
    ParameterSelect,
    Accordion,
    ParameterInput,
    ParameterCurMinMaxInput,
  },
}
</script>
