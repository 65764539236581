<template>
  <button class="c2-button" @click="$emit('click')">
    <slot />
  </button>
</template>

<script>
export default {
  name: "C2Button"
}
</script>

<style lang="scss" src="./index.scss"></style>
