<template>
  <Accordion block :expanded-by-default="accordionExpandedByDefault">
    <template #top>
      Accordion Settings
    </template>

    <div class="stream-editor-parameter-panel__prop">
      <div class="stream-editor-parameter-panel__prop-title">Toggle button icon's svg code (collapsed)</div>
      <ParameterTextarea :prop="{...activeComponent.props.iconSvgCodeCollapsed, key: 'iconSvgCodeCollapsed'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
    </div>

    <div class="stream-editor-parameter-panel__prop">
      <div class="stream-editor-parameter-panel__prop-title">Toggle button icon's svg code (expanded)</div>
      <ParameterTextarea :prop="{...activeComponent.props.iconSvgCodeExpanded, key: 'iconSvgCodeExpanded'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
    </div>
  </Accordion>
</template>

<script>
import Accordion from '@/containers/Constructor/containers/Editor/components/Accordion'

import ParameterTextarea
  from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Textarea";
import settings from "@/modules/constructor2/editor/parameters-panel/mixins/settings";

export default {
  name: "ParametersPanelSettingsAccordion",

  mixins: [settings],

  components: {
    Accordion,
    ParameterTextarea
  },
}
</script>
