<template>
  <c2-button @click="onAddRow">Add Row</c2-button>
</template>

<script>
import {mapActions} from "vuex";
import C2Button from "@/modules/constructor2/shared/atoms/button";

export default {
  name: "ParametersPanelTitleParametersTable",

  components: {C2Button},

  methods: {
    ...mapActions({
      addTableRow: 'constructor2/addTableRow'
    }),

    onAddRow () {
      this.addTableRow()
    }
  }
}
</script>
