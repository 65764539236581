<template>
  <Accordion block expanded-by-default>
    <template #top>
      Form Select Settings
    </template>

    <div class="stream-editor-parameter-panel__prop" :style="{ marginBottom: '12px' }">
      <parameters-panel-parameter-checkbox
        :prop="{...activeComponent.props.multiple, key: 'multiple'}"
        :size="size"
        :active-size="size"
        :value-type="valueType"
      />
    </div>

    <div class="stream-editor-parameter-panel__prop">
      <div class="stream-editor-parameter-panel__prop-title">Name</div>
      <ParameterInput :prop="{...activeComponent.props.name, key: 'name'}" :size="size" :active-size="size"
                      :value-type="valueType"/>
    </div>

    <div class="stream-editor-parameter-panel__prop">
      <div class="stream-editor-parameter-panel__prop-title">Default value</div>
      <ParameterInput :prop="{...activeComponent.props.defaultValue, key: 'defaultValue'}" :size="size" :active-size="size"
                      :value-type="valueType"/>
    </div>

    <div class="stream-editor-parameter-panel__prop">
      <div class="stream-editor-parameter-panel__prop-title">Placeholder</div>
      <ParameterInput :prop="{...activeComponent.props.placeholder, key: 'placeholder'}" :size="size"
                      :active-size="size" :value-type="valueType"/>
    </div>
  </Accordion>
</template>

<script>
import settings from "@/modules/constructor2/editor/parameters-panel/mixins/settings";
import ParameterInput from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Input.vue";
import Accordion from "@/containers/Constructor/containers/Editor/components/Accordion/index.vue";
import ParametersPanelParameterCheckbox from "@/modules/constructor2/editor/parameters-panel/atoms/parameter-checkbox";

export default {
  name: "ParametersPanelSettingsFormInput",

  mixins: [settings],

  components: {
    ParametersPanelParameterCheckbox,
    ParameterInput,
    Accordion,
  },
}
</script>
