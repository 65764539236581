<template>
  <Accordion block :expanded-by-default="accordionExpandedByDefault">
    <template #top>
      Flex
    </template>

    <div class="stream-editor-parameter-panel__props">
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Direction</div>
        <ParameterSelect :prop="{...activeComponent.props.flexDirection, key: 'flexDirection'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
      </div>

      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Wrap</div>
        <ParameterSelect :prop="{...activeComponent.props.flexWrap, key: 'flexWrap'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
      </div>
    </div>

    <div class="stream-editor-parameter-panel__prop">
      <div class="stream-editor-parameter-panel__prop-title">Justify content</div>
      <ParameterAlign :prop="{...activeComponent.props.justifyContent, key: 'justifyContent'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
    </div>

    <div class="stream-editor-parameter-panel__prop">
      <div class="stream-editor-parameter-panel__prop-title">Align items</div>
      <ParameterAlign :prop="{...activeComponent.props.alignItems, key: 'alignItems'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
    </div>
  </Accordion>
</template>

<script>
import Accordion from '@/containers/Constructor/containers/Editor/components/Accordion'
import ParameterAlign from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Align";
import ParameterSelect from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Select";
import settings from "@/modules/constructor2/editor/parameters-panel/mixins/settings";

export default {
  name: "ParametersPanelParametersFlex",

  mixins: [settings],

  components: {
    Accordion,
    ParameterAlign,
    ParameterSelect
  },
}
</script>
