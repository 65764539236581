<template>
  <div>
    <parameters-panel-settings-form-select-dropdown :size="size" :value-type="valueType" />
    <parameters-panel-settings-text :size="size" :value-type="valueType" />
    <parameters-panel-parameters-dimension :size="size" :value-type="valueType" />
    <parameters-panel-parameters-decoration :size="size" :value-type="valueType" />
    <parameters-panel-parameters-extra :size="size" :value-type="valueType" />
  </div>
</template>

<script>
import ParametersPanelParametersDimension from "@/modules/constructor2/editor/parameters-panel/molecules/dimension";
import ParametersPanelParametersDecoration from "@/modules/constructor2/editor/parameters-panel/molecules/decoration";
import ParametersPanelParametersExtra from "@/modules/constructor2/editor/parameters-panel/molecules/extra";
import ParametersPanelSettingsText from "@/modules/constructor2/editor/parameters-panel/molecules/settings-text";
import ParametersPanelSettingsFormSelectDropdown
  from "@/modules/constructor2/editor/parameters-panel/molecules/settings-form-select-dropdown";

export default {
  name: "ParametersPanelParametersFormSelectDropdown",

  props: {
    size: {
      type: String,
      default: 'desktop'
    },
    valueType: {
      type: String,
      default: 'general'
    }
  },

  components: {
    ParametersPanelSettingsFormSelectDropdown,
    ParametersPanelSettingsText,
    ParametersPanelParametersExtra,
    ParametersPanelParametersDimension,
    ParametersPanelParametersDecoration,
  }
}
</script>
