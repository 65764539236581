<template>
  <CodeEditor
    class="parameter-code-editor"
    :value="value"
    v-debounce:1000ms="(v) => updateProp(v)"
    width="100%"
    font_size="14px"
    :copy_code="false"
    :languages="[['html', 'HTML']]"
  />
</template>

<script>
import parameterItem from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/mixins/parameterItem";
import CodeEditor from 'simple-code-editor'

export default {
  name: "ParameterCodeEditor",

  components: {
    CodeEditor,
  },

  mixins: [ parameterItem ],
}
</script>

<style lang="scss" src="./index.scss"></style>
