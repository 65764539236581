<template>
  <div
    class="stream-components-bubbles"
    @click.stop="$emit('click')"
    @mouseenter="$emit('mouseenter')"
    @mouseleave="$emit('mouseleave')"
  >
    <constructor-image :component="imageComponent" @click="$emit('click')" />
    <bubble-button
      class="stream-base-component"
      :class="[
        `stream-component-id--${bubble.id}`,
        {'stream-base-component--active': isBubbleSelected(activeComponent, bubble)}
      ]"
      v-for="bubble in bubbles"
      :key="`bubble-${component.id}-${bubble.id}`"
      :active="activeBubbleId === bubble.id"
      :selected="isBubbleSelected(activeComponent, bubble)"
      :top="getBubbleTop(bubble, activeSize)"
      :left="getBubbleLeft(bubble, activeSize)"
      @click="onSelectBubble(bubble)"
      @remove="onRemoveBubble(bubble)"
    />
    <bubble-modal
      class="stream-base-component stream-base-component--active"
      :position="modalPosition"
      :style="modalStyle"
      @close="onCloseBubbleModal"
      v-if="activeBubble && activeBubble.children"
    >
      <workarea-component
        v-for="innerComponent in activeBubble.children"
        :key="innerComponent.id"
        :component="innerComponent"
      />
    </bubble-modal>
    <slot />
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {getSizedPropValue} from "@/containers/Constructor/containers/Editor/utils";
import ConstructorImage from "../Image";
import BubbleButton from './components/Button';
import BubbleModal from './components/Modal';
import {bus} from "@/app/main";

export default {
  name: "BubblesElement",

  components: {
    ConstructorImage,
    BubbleButton,
    BubbleModal,
    WorkareaComponent: () => import('../../../../index'),
  },

  props: {
    component: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      activeBubbleId: null
    }
  },

  computed: {
    ...mapState({
      activeComponent: state => state.constructor2.activeComponent,
      activeSize: state => state.constructor2.activeSize
    }),

    imageComponent () {
      return {
        props: {
          src: {
            value: getSizedPropValue(this.component?.props?.image, this.activeSize),
            defaultValue: getSizedPropValue(this.component?.props?.image, this.activeSize, true),
          }
        }
      }
    },

    bubbles () {
      return this.component?.children || []
    },

    activeBubble () {
      return this.bubbles.find(b => b.id === this.activeBubbleId) || null
    },

    modalPosition () {
      return getSizedPropValue(this.activeBubble?.props?.modalPosition, this.activeSize) || null
    },

    modalStyle () {
      return { padding: getSizedPropValue(this.activeBubble?.props?.padding, this.activeSize) || '' }
    }
  },

  methods: {
    ...mapMutations({
      setActiveComponent: 'constructor2/setActiveComponent',
      remove: 'constructor2/removeComponent'
    }),

    onSelectBubble (bubble) {
      this.activeBubbleId = this.activeBubbleId === bubble.id ? null : bubble.id
      this.setActiveComponent({ component: bubble })
    },

    onRemoveBubble (bubble) {
      this.remove(bubble)
      if (this.activeBubbleId === bubble.id) {
        this.activeBubbleId = null
      }
    },

    isBubbleSelected (activeComponent, bubble) {
      return bubble && activeComponent && bubble.id === activeComponent.id && !bubble.noManualRemove
    },

    getBubbleTop (bubble, activeSize) {
      return getSizedPropValue(bubble?.props?.top, activeSize) || 0
    },

    getBubbleLeft (bubble, activeSize) {
      return getSizedPropValue(bubble?.props?.left, activeSize) || 0
    },

    onCloseBubbleModal() {
      this.activeBubbleId = null
    }
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
