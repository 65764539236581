<template>
  <div
    class="stream-components-bubbles-button"
    :class="{'stream-components-bubbles-button--active': active}"
    :style="{ top: `calc(${top}% - 20px)`, left: `calc(${left}% - 20px)` }"
    @click.stop="$emit('click')"
  />
</template>

<script>
export default {
  name: "BubblesButtonElement",

  props: {
    active: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    top: {
      type: [String, Number],
      default: 0
    },
    left: {
      type: [String, Number],
      default: 0
    }
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
