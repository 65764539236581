<template>
  <parameters-panel-settings-table-column :size="size" :value-type="valueType" />
</template>

<script>
import ParametersPanelSettingsTableColumn
  from "@/modules/constructor2/editor/parameters-panel/molecules/settings-table-column";

export default {
  name: "ParametersPanelParametersTableColumn",

  props: {
    size: {
      type: String,
      default: 'desktop'
    },
    valueType: {
      type: String,
      default: 'general'
    }
  },

  components: {
    ParametersPanelSettingsTableColumn,
  }
}
</script>
