<template>
  <div
    class="stream-components-accordion"
    :class="{ 'stream-components-accordion--expanded': expanded }"
    @click.stop="$emit('click')"
    @mouseenter="$emit('mouseenter')"
    @mouseleave="$emit('mouseleave')"
  >
    <slot />
    <button class="stream-components-accordion__toggle" v-if="!svgCode" @click="expanded = !expanded">
      <svg width="147" height="147" viewBox="0 0 147 147" xmlns="http://www.w3.org/2000/svg" class="injected-svg" xml:space="preserve" version="1.1" v-if="!expanded" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'">
 <g>
  <circle id="svg_1" fill=" rgb(0, 0, 0)" opacity="0.75" r="73.5" cy="73.5" cx="73.5"/>
  <circle id="svg_2" stroke-miterlimit="10" stroke-width="3" stroke=" rgb(255, 255, 255)" fill=" none" class="open-close-solid-stroke" r="61" cy="73.5" cx="73.5"/>
  <g id="svg_3" fill=" rgb(255, 255, 255)">
   <rect id="svg_4" height="4" width="24" y="71.5" x="61.5"/>
   <rect id="svg_5" height="24" width="4" y="61.5" x="71.5"/>
  </g>
 </g>
</svg>
      <svg style="transform: rotate(45deg);" width="147" height="147" viewBox="0 0 147 147" xmlns="http://www.w3.org/2000/svg" class="injected-svg" xml:space="preserve" version="1.1" v-else="" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'">
 <g>
  <circle id="svg_1" fill=" rgb(0, 0, 0)" opacity="0.75" r="73.5" cy="73.5" cx="73.5"/>
   <circle id="svg_2" stroke-miterlimit="10" stroke-width="3" stroke=" rgb(255, 255, 255)" fill=" none" class="open-close-solid-stroke" r="61" cy="73.5" cx="73.5"/>
   <g id="svg_3" fill=" rgb(255, 255, 255)">
   <rect id="svg_4" height="4" width="24" y="71.5" x="61.5"/>
     <rect id="svg_5" height="24" width="4" y="61.5" x="71.5"/>
  </g>
 </g>
</svg>
    </button>
    <button class="stream-components-accordion__toggle" v-else v-html="svgCode || ''" @click="expanded = !expanded" />
  </div>
</template>

<script>
import {mapState} from "vuex";
import {getSizedPropValue} from "@/containers/Constructor/containers/Editor/utils";

export default {
  name: "AccordionElement",

  props: {
    component: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      expanded: false
    }
  },

  computed: {
    ...mapState({
      activeSize: state => state.constructor2.activeSize
    }),

    svgCode () {
      return this.expanded
        ? getSizedPropValue(this.component?.props?.iconSvgCodeExpanded, this.activeSize)
        : getSizedPropValue(this.component?.props?.iconSvgCodeCollapsed, this.activeSize)
    }
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
