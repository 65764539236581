import {CSS_PROPS, FLEX_PROPS} from "@/store/modules/constructor2/components/consts";
import {AVAILABLE_SIZES_LIST} from "@/store/modules/constructor2/consts";

const flexProps = Object.keys(FLEX_PROPS)

const parsePropValue = (propKey, value) => {
  if (propKey === 'backgroundImage') return value ? `url(${process.env.VUE_APP_CONSTRUCTOR_2_BASE_URL + value})` : null
  return value
}

const getPropValueBySize = (propValue, size, prefix = undefined) => {
  if ((propValue || typeof propValue === 'boolean') && size) {
    if (propValue[`${prefix ? `${prefix}_`: ''}${size}`]) return propValue[`${prefix ? `${prefix}_`: ''}${size}`]
    return getPropValueBySize(
      propValue,
      AVAILABLE_SIZES_LIST[AVAILABLE_SIZES_LIST.findIndex(a => a.slug === size) - 1]?.slug
        ? `${prefix ? `${prefix}_`: ''}${AVAILABLE_SIZES_LIST[AVAILABLE_SIZES_LIST.findIndex(a => a.slug === size) - 1]?.slug}`
        : undefined
    )
  }
  return null
}

const getSizedPropValue = (prop, size, isDefault = undefined, prefix = undefined, defaultFallback) => {
  if (prop) {
    const value = isDefault === true ? prop.defaultValue : prop.value
    if (!!value && size) {
      const propValue = typeof value === 'string' ? value : getPropValueBySize(value, size, prefix)
      if (propValue) return propValue
    }
    if (isDefault === true) {
      return prop.defaultValue
    }
    if (isDefault === false) {
      return prop.value
    }
    if (isDefault === undefined) {
      if (defaultFallback === undefined)
        return getSizedPropValue(prop, size, true, prefix)
      else return defaultFallback
    }
  }
}

const parseWindowSize = (value, vw, vh) => {
  if (value && vw && vh && typeof value === 'string') {
    return value
      .replace(/\d+vw/g, (n) =>
        `${parseFloat(n.replace('vw', '')) / 100 * vw}px`)
      .replace(/\d+vh/g, (n) =>
        `${parseFloat(n.replace('vh', '')) / 100 * vh}px`)
  }
  return value
}

const computeComponentStyle = (component, activeSize, vw, vh, prefix) => {
  const style = {}

  if (component?.props) {
    Object.entries(component.props)
      .filter(([key]) => CSS_PROPS[key])
      .forEach(([key, prop]) => {
        if (flexProps.includes(key)) {
          style.display = 'flex'
        }
        const value = parseWindowSize(parsePropValue(
          key,
          getSizedPropValue(prop, activeSize, undefined, prefix, null) ||
            getSizedPropValue(prop, activeSize, undefined),
          vw,
          vh))
        if (value)
          style[key] = value
      })
  }

  return style
}

const extractFromJsonByString = (object, path, defaultValue) => path
  .split(/[.\[\]'"]/)
  .filter(p => p)
  .reduce((o, p) => o ? o[p] : defaultValue, object)

export {
  computeComponentStyle,
  getPropValueBySize,
  getSizedPropValue,
  parsePropValue,
  extractFromJsonByString
}
