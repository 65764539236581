<template>
  <parameters-panel-multi-input
    class="parameter-attributes"
    :value="value || placeholderValue"
    :value-template="[
      {
        prop: 'key',
        name: 'Key',
        placeholder: 'Enter key'
      },
      {
        prop: 'value',
        name: 'Value',
        placeholder: 'Enter value'
      },
    ]"
    @input="onMultiInput"
  />
</template>

<script>
// NOTE: ATTRIBUTES DO NOT DEPEND ON valueType

import ParametersPanelMultiInput
  from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/components/MultiInput";
import multiParameterItem from "@/modules/constructor2/editor/parameters-panel/mixins/multiParameterItem";

export default {
  name: "ParameterAttributes",

  components: {
    ParametersPanelMultiInput
  },

  mixins: [ multiParameterItem ],

  computed: {
    formattedValueType() {
      return 'general'
    }
  }
}
</script>
