<template>
  <div class="parameter-url">
    <Input
      class="parameter-url__input"
      :value="value"
      :placeholder="placeholderValue"
      :disabled="isInternalChosen"
      @debounced-input="onInputUrl"
    />
    <vn-select-add
      label="Pages"
      :value="selectPageValue"
      :options="pages"
      @input="onPageSelect"
      no-remove-option
    />
  </div>
</template>

<script>
import parameterItem from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/mixins/parameterItem";
import {mapGetters, mapState} from "vuex";
import {getPropValueBySize} from "@/containers/Constructor/containers/Editor/utils";
import VnSelectAdd from '@/components/ui/vn-select-add.vue'
import Input from '../../components/Input'

export default {
  name: "ParameterUrl",

  components: {
    VnSelectAdd,
    Input,
  },

  mixins: [ parameterItem ],

  props: {
    internalPropName: {
      type: String,
      default: 'internal'
    }
  },

  computed: {
    ...mapGetters({
      rawPages: "constructor2/pages"
    }),
    ...mapState({
      activeComponent: (state) => state.constructor2.activeComponent,
    }),

    selectPageValue () {
      return this.pages.find(v => v.id === this.value)?.text || ''
    },

    pages () {
      return this.rawPages.map((v) => ({ text: v, id: v }))
    },

    isInternalChosen () {
      return getPropValueBySize(this.activeComponent?.props?.[this.internalPropName]?.value, this.size)
    }
  },

  methods: {
    onPageSelect (page) {
      this.updateProp(page.id || '')
      this.updateProp(!!page.id, {...this.activeComponent?.props?.[this.internalPropName], key: this.internalPropName})
    },

    onInputUrl (url) {
      this.updateProp(url)
      this.updateProp(false, {...this.activeComponent?.props?.[this.internalPropName], key: this.internalPropName})
    }
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
