<template>
  <component
    :is="componentIs"
    v-if="link"
    :href="link"
    @click="onClickLink"
    target="_blank"
    class="stream-editor-tooltip__icon-button stream-editor-tooltip__icon-button--link"
    title="Open link"
  >
    <svg xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
  </component>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  name: "EditorTooltipOpenLinkAction",

  data() {
    return {
      link: '',
      isInternal: false
    }
  },

  computed: {
    componentIs () {
      if (this.isInternal) return 'div'
      return 'a'
    }
  },

  methods: {
    ...mapMutations({
      changePage: 'constructor2/changePage',
    }),

    updateLink(activeComponentEl) {
      this.link = activeComponentEl.getAttribute('data-link') || ''
      this.isInternal = activeComponentEl.getAttribute('data-internal') === 'true' || false
    },

    onClickLink() {
      if (this.isInternal) {
        this.changePage(this.link)
      }
    }
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
