<template>
  <div class="stream-editor">
    <components-panel class="stream-editor__side" :initialized="initialized" />
    <editor-panel class="stream-editor__main" />
    <parameters-panel class="stream-editor__side" />
  </div>
</template>

<script>
import ComponentsPanel from './containers/ComponentsPanel'
import EditorPanel from './containers/EditorPanel'
import ParametersPanel from './containers/ParametersPanel'

export default {
  name: "ConstructorEditor",

  components: {
    ComponentsPanel,
    EditorPanel,
    ParametersPanel,
  },

  props: {
    initialized: {
      type: Boolean,
      default: undefined
    }
  }
};
</script>

<style lang="scss" src="./index.scss"></style>
