<template>
  <div class="parameter-cur-min-max-input">
    <Input :value="values.current" :placeholder="placeholderValues.current || 'Current'" @change="onInput($event, 'current')" />
    <Input :value="values.min" :placeholder="placeholderValues.min || 'Min'" @change="onInput($event, 'min')" />
    <Input :value="values.max" :placeholder="placeholderValues.max || 'Max'" @change="onInput($event, 'max')" />
  </div>
</template>

<script>
import parameterItem from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/mixins/parameterItem";
import {mapState} from "vuex";
import {getPropValueBySize} from "@/containers/Constructor/containers/Editor/utils";
import Input from '../../components/Input'

export default {
  name: "ParameterCurMinMaxInput",

  components: {
    Input
  },

  mixins: [ parameterItem ],

  computed: {
    ...mapState({
      activeComponent: state => state.constructor2.activeComponent
    }),

    baseKey () {
      return `${this.prop.key?.[0]?.toUpperCase() + this.prop.key?.slice(1)}`
    },

    values () {
      let current, min, max
      if (this.valueType !== 'general') {
        current = this.activeComponent.props[this.prop.key]?.value ? (
          getPropValueBySize((this.activeComponent.props[this.prop.key].value), this.size, this.valueType)
          || getPropValueBySize((this.activeComponent.props[this.prop.key].value), this.size)
          || ''
        ) : ''
        min = this.activeComponent.props[`min${this.baseKey}`]?.value ? (
          getPropValueBySize((this.activeComponent.props[`min${this.baseKey}`].value), this.size, this.valueType)
          || getPropValueBySize((this.activeComponent.props[`min${this.baseKey}`].value), this.size)
          || ''
        ) : ''
        max = this.activeComponent.props[`max${this.baseKey}`]?.value ? (
          getPropValueBySize((this.activeComponent.props[`max${this.baseKey}`].value), this.size, this.valueType)
          || getPropValueBySize((this.activeComponent.props[`max${this.baseKey}`].value), this.size)
          || ''
        ) : ''
      }
      return {
        current: current || (this.activeComponent.props[this.prop.key]?.value
          ? getPropValueBySize((this.activeComponent.props[this.prop.key].value), this.size) || ''
          : ''),
        min: min || (this.activeComponent.props[`min${this.baseKey}`]?.value
          ? getPropValueBySize((this.activeComponent.props[`min${this.baseKey}`].value), this.size) || ''
          : ''),
        max: max || (this.activeComponent.props[`max${this.baseKey}`]?.value
          ? getPropValueBySize((this.activeComponent.props[`max${this.baseKey}`].value), this.size) || ''
          : ''),
      }
    },

    placeholderValues () {
      return {
        current: this.activeComponent.props[this.prop.key]?.defaultValue
          ? getPropValueBySize((this.activeComponent.props[this.prop.key].defaultValue), this.size) || ''
          : '',
        min: this.activeComponent.props[`min${this.baseKey}`]?.defaultValue
          ? getPropValueBySize((this.activeComponent.props[`min${this.baseKey}`].defaultValue), this.size) || ''
          : '',
        max: this.activeComponent.props[`max${this.baseKey}`]?.defaultValue
          ? getPropValueBySize((this.activeComponent.props[`max${this.baseKey}`].defaultValue), this.size) || ''
          : '',
      }
    }
  },

  methods: {
    onInput (v, t) {
      this.updateProp(v, t === 'current'
        ? {...this.activeComponent.props[this.prop.key], key: this.prop.key}
        : {...this.activeComponent.props[`${t}${this.baseKey}`], key: `${t}${this.baseKey}`})
    }
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
