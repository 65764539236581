<template>
  <div
    class="stream-components-360-viewer"
    @click.stop="$emit('click')"
    @mouseenter="$emit('mouseenter')"
    @mouseleave="$emit('mouseleave')"
  >
    <div :id="`the-360-viewer-${component.id}-container`" :key="`the-360-viewer-${component.id}-container-${renderCount}`">
      <template v-if="loaderComponent">
        <div
          v-if="!loaderComponent.children || !loaderComponent.children.length"
          :id="`the-360-viewer-loader-${loaderComponent.id}`"
          :key="`the-360-viewer-loader-${loaderComponent.id}-${renderCount}`"
        >
          Loading...
        </div>
        <workarea-component
          v-else
          :component="loaderComponent"
          :id="`the-360-viewer-loader-${loaderComponent.id}`"
          :key="`the-360-viewer-loader-${loaderComponent.id}-${renderCount}`"
        />
      </template>
    </div>
    <slot />
  </div>
</template>

<script>
import { viewerScript } from './viewer-script'
import {getSizedPropValue} from "@/containers/Constructor/containers/Editor/utils";
import {mapState} from "vuex";

export default {
  name: "The360ViewerElement",

  components: {
    WorkareaComponent: () => import("@/containers/Constructor/containers/Editor/containers/EditorPanel/containers/WorkareaComponents/containers/Component")
  },

  props: {
    component: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      renderCount: 1,
      domain: process.env.VUE_APP_CONSTRUCTOR_2_BASE_URL,
    }
  },

  mounted() {
    // add the 360 script to the DOM if it doesn't already exist
    if (!document.getElementById('the-360-viewer-script')) {
      const script = document.createElement('script')
      script.innerHTML = viewerScript
      document.body.appendChild(script)
    }
    // warn if there's no loader component
    if (!this.loaderComponent) {
      console.warn('WARNING: Loader component was not found (360Viewer)')
    }
  },

  computed: {
    ...mapState({
      activeSize: state => state.constructor2.activeSize
    }),

    images() {
      return getSizedPropValue(this.component?.props?.images, this.activeSize)
    },

    loaderComponent() {
      const loaderComponent = this.component.children[0]
      if (loaderComponent) {
        return {
          ...loaderComponent,
          customRender: false
        }
      }
      return undefined
    }
  },

  watch: {
    images: {
      immediate: true,
      handler() {
        this.initCircl()
      }
    }
  },

  methods: {
    initCircl() {
      this.renderCount++
      if (this.images.length) {
        this.$nextTick(() => {
          try {
            const containerId = `the-360-viewer-${this.component.id}-container`
            const loaderId = `the-360-viewer-loader-${this.loaderComponent.id}`
            let containerEl = this.$el.querySelector(`#${containerId}`)
            let loaderEl = this.$el.querySelector(`#${containerId} > #${loaderId}`)
            // create new images
            this.images.forEach(image => {
              const imgEl = document.createElement("img")
              imgEl.setAttribute('data-src', this.domain + image)
              containerEl.insertBefore(imgEl, loaderEl)
            })
            // initialize the circlr plugin
            window.circlr(containerId, {loader: loaderId});
          } catch (e) {
            console.error(e)
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
