<template>
  <div>
    <parameters-panel-settings-popover :size="size" :value-type="valueType" />
    <parameters-panel-parameters-dimension :size="size" :value-type="valueType" />
    <parameters-panel-parameters-decoration :size="size" :value-type="valueType" />
    <parameters-panel-parameters-extra :size="size" :value-type="valueType" />
  </div>
</template>

<script>
import ParametersPanelParametersDimension from "@/modules/constructor2/editor/parameters-panel/molecules/dimension";
import ParametersPanelParametersDecoration from "@/modules/constructor2/editor/parameters-panel/molecules/decoration";
import ParametersPanelParametersExtra from "@/modules/constructor2/editor/parameters-panel/molecules/extra";
import ParametersPanelSettingsPopover from "@/modules/constructor2/editor/parameters-panel/molecules/settings-popover";

export default {
  name: "ParametersPanelParametersPopover",

  props: {
    size: {
      type: String,
      default: 'desktop'
    },
    valueType: {
      type: String,
      default: 'general'
    }
  },

  components: {
    ParametersPanelSettingsPopover,
    ParametersPanelParametersExtra,
    ParametersPanelParametersDimension,
    ParametersPanelParametersDecoration,
  }
}
</script>
