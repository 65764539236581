<template>
  <vn-select-add
    class="parameter-select"
    :label="prop.name"
    :value="value || placeholderValue"
    :options="fonts"
    @input="onChangeFont"
    no-clear
    no-remove-option
    searchable
  />
</template>

<script>
// NOTE: FontFamily DOES NOT DEPEND ON valueType

import parameterItem from "../mixins/parameterItem";
import {mapState} from "vuex";
import {getSizedPropValue} from "@/containers/Constructor/containers/Editor/utils";
import syncFontStyle from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/utils/syncFontStyle";
import VnSelectAdd from '@/components/ui/vn-select-add.vue';

export default {
  name: "ParameterFontFamily",

  components: {
    VnSelectAdd,
  },

  mixins: [ parameterItem ],

  computed: {
    ...mapState({
      availableFonts: state => state.constructor2.availableFonts,
      activeComponent: state => state.constructor2.activeComponent
    }),

    fonts () {
      return [...(new Set(this.availableFonts
        .slice()
        .map(f => f.fontFamily)))]
        .sort()
    }
  },

  methods: {
    onChangeFont (value) {
      const currentFontFamily = getSizedPropValue(this.prop.value, this.size, false, this.valueType)
        || this.prop.value
      if (currentFontFamily !== value) {
        // updating font family
        this.updateActiveComponentProp({
          prop: this.prop,
          updatedProp: {
            ...this.prop,
            value: {
              ...(typeof this.prop.value === 'string' ? {
                desktop: this.prop.value
              } : this.prop.value || {}),
              [this.size]: value
            }
          }
        })
        // updating font weight if the chosen font family doesn't have the already chosen font weight (same for font style)
        const currentFontWeight =
          getSizedPropValue(this.activeComponent.props.fontWeight, this.size, false, this.valueType === 'general' ? undefined : this.valueType)
        const currentFontStyle =
          getSizedPropValue(this.activeComponent.props.fontStyle, this.size, false, this.valueType === 'general' ? undefined : this.valueType)
        const chosenFont = this.availableFonts.filter(f => f.fontFamily === value)
        if (chosenFont.length && !chosenFont.some((font) => font.fontWeight === currentFontWeight && font.fontStyle === currentFontStyle)) {
          this.updateActiveComponentProp({
            prop: {...this.activeComponent.props.fontWeight, key: 'fontWeight'},
            updatedProp: {
              ...this.activeComponent.props.fontWeight,
              value: {
                ...(!this.activeComponent.props.fontWeight.value
                || typeof this.activeComponent.props.fontWeight.value === 'string' ? {
                  desktop: this.activeComponent.props.fontWeight.value
                    || this.activeComponent.props.fontWeight.defaultValue
                } : this.activeComponent.props.fontWeight.value || {}),
                [this.size]: chosenFont[0].fontWeight
              }
            }
          })
        }
        syncFontStyle(value, this.availableFonts, this.activeComponent, this.size, this.valueType, this.updateActiveComponentProp)
      }
    }
  }
}
</script>
