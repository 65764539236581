<template>
  <Accordion block expanded-by-default>
    <template #top>
      Form Settings
    </template>

    <div
      class="stream-editor-parameter-panel__prop"
      style="margin-bottom: 12px;"
    >
      <ParametersPanelParameterCheckbox :prop="{...activeComponent.props.scrollToFirstValidationError, key: 'scrollToFirstValidationError'}" :size="size" :active-size="size" :value-type="valueType" />
    </div>

    <div class="stream-editor-parameter-panel__prop">
      <div class="stream-editor-parameter-panel__prop-title">Submission link</div>
      <ParameterInput :prop="{...activeComponent.props.submissionLink, key: 'submissionLink'}" :size="size" :active-size="size" :value-type="valueType" />
    </div>

    <div class="stream-editor-parameter-panel__prop">
      <div class="stream-editor-parameter-panel__prop-title">After submit action</div>
      <C2PSelect :prop="{...activeComponent.props.afterSubmitAction, key: 'afterSubmitAction'}" :items="afterSubmitActionItems" :size="size" :active-size="size" :value-type="valueType" />
    </div>

    <template v-if="afterSubmitAction === 'redirect'">
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Redirect link</div>
        <ParameterUrl :prop="{...activeComponent.props.redirectLink, key: 'redirectLink'}" internalPropName="redirectLinkInternal" :size="size" :active-size="size" :value-type="valueType" />
      </div>

      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Redirect link target</div>
        <ParameterTabSelect :prop="{...activeComponent.props.redirectLinkTarget, key: 'redirectLinkTarget'}" :size="size" :active-size="size" :value-type="valueType" />
      </div>
    </template>
    <template v-else>
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Hide elements on successful submit</div>
        <C2PSelect
          :prop="{...activeComponent.props.hideElementsOnSuccessfulSubmit, key: 'hideElementsOnSuccessfulSubmit'}"
          :items="availableElementsToForm"
          :noOptionsText="noOptionsText"
          multiple
          :size="size"
          :active-size="size"
          :value-type="valueType"
        />
      </div>

      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Show elements on successful submit</div>
        <C2PSelect
          :prop="{...activeComponent.props.showElementsOnSuccessfulSubmit, key: 'showElementsOnSuccessfulSubmit'}"
          :items="availableElementsToForm"
          :noOptionsText="noOptionsText"
          multiple
          :size="size"
          :active-size="size"
          :value-type="valueType"
        />
      </div>

      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Hide elements on failed submit</div>
        <C2PSelect
          :prop="{...activeComponent.props.hideElementsOnFailedSubmit, key: 'hideElementsOnFailedSubmit'}"
          :items="availableElementsToForm"
          :noOptionsText="noOptionsText"
          multiple
          :size="size"
          :active-size="size"
          :value-type="valueType"
        />
      </div>

      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Show elements on failed submit</div>
        <C2PSelect
          :prop="{...activeComponent.props.showElementsOnFailedSubmit, key: 'showElementsOnFailedSubmit'}"
          :items="availableElementsToForm"
          :noOptionsText="noOptionsText"
          multiple
          :size="size"
          :active-size="size"
          :value-type="valueType"
        />
      </div>
    </template>
  </Accordion>
</template>

<script>
import Accordion from '@/containers/Constructor/containers/Editor/components/Accordion'
import settings from "@/modules/constructor2/editor/parameters-panel/mixins/settings";
import ParameterInput from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Input";
import ParametersPanelParameterCheckbox from "@/modules/constructor2/editor/parameters-panel/atoms/parameter-checkbox";
import {mapGetters} from "vuex";
import {AVAILABLE_SIZES_LIST} from "@/store/modules/constructor2/consts";
import C2PSelect from "@/modules/constructor2/editor/parameters-panel/molecules/select";
import {getSizedPropValue} from "@/containers/Constructor/containers/Editor/utils";
import ParameterUrl from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Url";
import ParameterTabSelect
  from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/TabSelect";

export default {
  name: "ParametersPanelSettingsForm",

  mixins: [settings],

  components: {
    C2PSelect,
    ParametersPanelParameterCheckbox,
    ParameterInput,
    Accordion,
    ParameterUrl,
    ParameterTabSelect,
  },

  data() {
    return {
      noOptionsText: "Locate the component you want to select here and turn on \"Make available to forms\" in the \"Extra\" section of its parameters",

      afterSubmitActionItems: [
        {
          id: 'redirect',
          label: 'Redirect'
        },
        {
          id: 'manipulateElements',
          label: 'Manipulate elements'
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      addedComponents: "constructor2/addedComponents"
    }),

    availableElementsToForm() {
      return this.addedComponents.filter(component =>
        component.props?.availableToForms?.value &&
        AVAILABLE_SIZES_LIST.some(size => component.props.availableToForms.value[size.slug] === 'on')
      ).map(component => ({ id: component.id, label: `[${ component.id }] ${ component.displayName || component.name }` }))
    },

    afterSubmitAction() {
      return getSizedPropValue(this.activeComponent.props.afterSubmitAction, this.size)
    }
  }
}
</script>
