<template>
  <div class="stream-fonts" />
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: "ConstructorFonts",

  data () {
    return {
      addedFonts: []
    }
  },

  mounted () {
    this.onFontsUpdate()
    this.$watch(vm => [
      vm.usedFonts,
      vm.availableFonts
    ], this.onFontsUpdate)
  },

  computed: {
    ...mapState({
      availableFonts: state => state.constructor2.availableFonts
    }),
    ...mapGetters({
      usedFonts: 'constructor2/usedFonts'
    })
  },

  methods: {
    onFontsUpdate () {
      const style = document.createElement("style");
      style.innerHTML = '';
      let add = false;
      (this.usedFonts || []).forEach((font) => {
        if (!this.addedFonts.find(f => f.fontFamily === font.fontFamily && f.fontStyle === font.fontStyle && f.fontWeight === font.fontWeight)) {
          const fullFont = this.availableFonts.filter(f => f.fontFamily === font.fontFamily && f.fontWeight === font.fontWeight)
          fullFont.forEach(ff => {
            if (ff?.fonts) {
              add = true
              let htmlPart = `@font-face {
              font-family: ${font.fontFamily};
              font-style: ${font.fontStyle};
              font-weight: ${font.fontWeight};
            `;
              let urls = [];
              ff.fonts.map((fontItem) => {
                urls.push(`url(${process.env.VUE_APP_CONSTRUCTOR_2_BASE_URL}${fontItem.url})${fontItem.format ? ` format('${fontItem.format}')` : ''}`);
              });
              const urlString = urls.join(",");
              htmlPart += `src: ${urlString};`;
              htmlPart += `}`;
              style.innerHTML += htmlPart;
              this.addedFonts.push(font)
            }
          })
        }
      });
      if (add) {
        this.$el.append(style);
      }
    }
  }
}
</script>
