<template>
  <Accordion block expanded-by-default>
    <template #top>
      Bubble Settings
    </template>

    <div class="stream-editor-parameter-panel__props">
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Padding</div>
        <ParameterInput :prop="{...activeComponent.props.padding, key: 'padding'}" :size="size" :active-size="size" :value-type="valueType" />
      </div>
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Bubble Modal Position</div>
        <ParameterSelect :prop="{...activeComponent.props.modalPosition, key: 'modalPosition'}" :size="size" :active-size="size" :value-type="valueType" />
      </div>
    </div>

    <div class="stream-editor-parameter-panel__props">
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Bubble Position Top</div>
        <ParameterInput :prop="{...activeComponent.props.top, key: 'top'}" :size="size" :active-size="size" :value-type="valueType" />
      </div>
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Bubble Position Left</div>
        <ParameterInput :prop="{...activeComponent.props.left, key: 'left'}" :size="size" :active-size="size" :value-type="valueType" />
      </div>
    </div>
  </Accordion>
</template>

<script>
import Accordion from '@/containers/Constructor/containers/Editor/components/Accordion'
import ParameterInput from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Input";
import ParameterSelect from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Select";
import settings from "@/modules/constructor2/editor/parameters-panel/mixins/settings";

export default {
  name: "ParametersPanelSettingsBubble",

  mixins: [settings],

  components: {
    Accordion,
    ParameterInput,
    ParameterSelect,
  },
}
</script>
