<template>
  <Accordion block expanded-by-default>
    <template #top>
      Slide Settings
    </template>

    <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.padding">
      <div class="stream-editor-parameter-panel__prop-title">Padding</div>
      <ParameterInput :prop="{...activeComponent.props.padding, key: 'padding'}" :size="size" :active-size="size" :value-type="valueType" />
    </div>
  </Accordion>
</template>

<script>
import Accordion from '@/containers/Constructor/containers/Editor/components/Accordion'

import ParameterInput from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Input";
import settings from "@/modules/constructor2/editor/parameters-panel/mixins/settings";

export default {
  name: "ParametersPanelSettingsSlide",

  mixins: [settings],

  components: {
    Accordion,
    ParameterInput,
  },
}
</script>
