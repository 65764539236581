<template>
  <component
    :is="element"
    class="stream-components-text"
    :class="[
      { 'stream-components-text--empty': empty },
      { 'stream-components-text--clamped': isClamped }
    ]"
    :style="{ '--max-lines': maxLines }"
    @click.stop="$emit('click')"
    @mouseenter="$emit('mouseenter')"
    @mouseleave="$emit('mouseleave')"
  >
<!--  todo possibly get rid of v-html and make it safe  -->
    <span v-if="content" v-html="content"></span>
    <slot />
  </component>
</template>

<script>
import {mapState} from "vuex";
import {getSizedPropValue} from "@/containers/Constructor/containers/Editor/utils";

export default {
  name: "TextElement",

  props: {
    empty: {
      type: Boolean,
      default: false
    },
    component: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    ...mapState({
      activeSize: state => state.constructor2.activeSize
    }),

    element () {
      return getSizedPropValue(this.component?.props?.element) || 'p'
    },

    content () {
      return getSizedPropValue((this.component?.props?.content), this.activeSize)?.replaceAll(/\n/g, '<br>') || ''
    },

    maxLines () {
      return getSizedPropValue((this.component?.props?.maxLines), this.activeSize)
    },

    isClamped () {
      return !isNaN(this.maxLines) && Number(this.maxLines) > 0
    }
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
