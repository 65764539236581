<template>
  <div
    class="stream-editor-editor-panel-workarea"
    @wheel.prevent="wheel"
  >
      <div class="stream-editor-editor-panel-workarea__nav">
        <button
          class="stream-editor-editor-panel-workarea__nav__home"
          @click="resetTransform"
        >
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'">
  <rect x="0.5" y="0.5" width="39" height="39" rx="5.5" fill="white" stroke="#D6DEE2"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9454 11.168C20.2956 10.9345 20.7545 10.9453 21.0933 11.1949L30.5933 18.1949C31.0379 18.5226 31.1328 19.1486 30.8052 19.5932C30.4775 20.0378 29.8515 20.1327 29.4069 19.8051L28.0001 18.7685V28C28.0001 28.5523 27.5524 29 27.0001 29H22.0001H18.0001H13.0001C12.4478 29 12.0001 28.5523 12.0001 28V18.8685L10.5548 19.8321C10.0953 20.1384 9.47441 20.0142 9.16806 19.5547C8.8617 19.0952 8.98588 18.4743 9.44541 18.168L12.434 16.1755C12.442 16.17 12.4501 16.1646 12.4583 16.1593L19.9454 11.168ZM19.0001 27H21.0001V23H19.0001V27ZM23.0001 27V22C23.0001 21.4477 22.5524 21 22.0001 21H18.0001C17.4478 21 17.0001 21.4477 17.0001 22V27H14.0001V17.5352L20.4714 13.221L26.0001 17.2948V27H23.0001Z" fill="#8D9CA8"/>
</svg>
        </button>
        <div class="stream-editor-editor-panel-workarea__nav__zoom">
          <button
            :class="'stream-editor-editor-panel-workarea__nav__zoom__item' +(currentZoom >= maxZoom  ? ' stream-editor-editor-panel-workarea__nav__zoom__item--disabled'  : '')"
            @click="changeZoom(1 + stepZoom)"
          >
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'">
  <rect x="0.5" y="0.5" width="39" height="39" rx="5.5" fill="white" stroke="#D6DEE2"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M21 11C21 10.4477 20.5523 10 20 10C19.4477 10 19 10.4477 19 11V19H11C10.4477 19 10 19.4477 10 20C10 20.5523 10.4477 21 11 21H19V29C19 29.5523 19.4477 30 20 30C20.5523 30 21 29.5523 21 29V21H29C29.5523 21 30 20.5523 30 20C30 19.4477 29.5523 19 29 19H21V11Z" fill="#8D9CA8"/>
</svg>
          </button>
          <button
            :class="'stream-editor-editor-panel-workarea__nav__zoom__item' + (currentZoom >= maxZoom ? ' stream-editor-editor-panel-workarea__nav__zoom__item--disabled' : '')"
            @click="changeZoom(1 - stepZoom)"
          >
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'">
  <rect x="0.5" y="0.5" width="39" height="39" rx="5.5" fill="white" stroke="#D6DEE2"/>
  <rect x="10" y="19" width="20" height="2" rx="1" fill="#8D9CA8"/>
</svg>
          </button>
        </div>
        <div class="stream-editor-editor-panel-workarea__zoom-value">
          {{parseInt(currentZoom * 100) + '%'}}
        </div>
      </div>
      <div class="stream-editor-editor-panel-workarea__container">
        <div
          class="stream-editor-editor-panel-workarea__inner"
          :style="`${transformStyle} top:50%; left:50%;`"
        >
          <slot />
          <editor-tooltip :scaling="currentZoom" />
        </div>
      </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EditorTooltip from '../Tooltip'

export default {
  name: "Workarea",

  components: {
    EditorTooltip
  },

  props: {
    minZoom: {
      type: Number,
      default: 0.3,
    },
    maxZoom: {
      type: Number,
      default: 2,
    },
    stepZoom: {
      type: Number,
      default: 0.1,
    },
    initialZoom: {
      type: Number,
    },
  },

  data() {
    return {
      currentZoom: 1,
      positionCoordinates: {
        top: 0,
        left: 0,
      },
    };
  },

  computed: {
    ...mapState({
      activeTemplate: (state) => state.constructor2.activeTemplate,
    }),

    transformStyle () {
      return `
        -webkit-transform: translate(calc(-50% + ${this.positionCoordinates.left}px), calc(-50% + ${this.positionCoordinates.top}px)) scale(${this.currentZoom});
        -ms-transform: translate(calc(-50% + ${this.positionCoordinates.left}px), calc(-50% + ${this.positionCoordinates.top}px)) scale(${this.currentZoom});
        transform: translate(calc(-50% + ${this.positionCoordinates.left}px), calc(-50% + ${this.positionCoordinates.top}px)) scale(${this.currentZoom});
      `;
    },
  },
  methods: {
    changeZoom: function (value) {
      if (typeof value === 'number' && !isNaN(value)) {
        if ((this.currentZoom > this.minZoom && value < 1) || (this.currentZoom < this.maxZoom && value > 1)) {
          this.currentZoom *= value;
          this.positionCoordinates.top *= value;
          this.positionCoordinates.left *= value;
        }
      }
    },

    resetTransform: function () {
      const height = this.$el.querySelector(".stream-editor-editor-panel-workarea__inner").offsetHeight;
      const containerHeight = this.$el.querySelector(".stream-editor-editor-panel-workarea__container").offsetHeight;
      this.setInitialZoom();
      this.positionCoordinates = { top: ((height-containerHeight)/2*this.currentZoom), left: 0 };
    },

    setInitialZoom: function () {
      let initialZoom;
      if (!this.initialZoom) {
        const containerWidth = this.$el.querySelector(".stream-editor-editor-panel-workarea__container").offsetWidth;
        const elemWidth = this.$el.querySelector(".stream-editor-editor-panel-workarea__inner").offsetWidth;
        initialZoom = parseInt(containerWidth) / parseInt(elemWidth);
      } else {
        initialZoom = this.initialZoom;
      }
      if (typeof initialZoom === 'number' && !isNaN(initialZoom))
        this.currentZoom = initialZoom;
    },

    wheel(e) {
      if(e.ctrlKey) {
        if(e.deltaY !== 0) {
          let deltaZoom = e.deltaY;
          if(e.deltaY > 5) {
            deltaZoom = 5;
          }
          this.changeZoom(1 - deltaZoom / 20);
        }

      } else {
        if(e.deltaY > 2 || e.deltaY < -2) {
          this.positionCoordinates.top -= e.deltaY;
        }
        if(e.deltaX > 2 || e.deltaX < -2) {
          this.positionCoordinates.left -= e.deltaX;
        }
      }
    },
  },
};
</script>

<style lang="scss" src="./index.scss"></style>
