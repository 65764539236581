<template>
  <div class="parameters-panel-multi-input">
    <div class="parameters-panel-multi-input__items" v-for="(item, index) in inputValues" :key="index">
      <div class="parameters-panel-multi-input__items-inputs">
        <template v-for="(vt, i) in valueTemplate">
          <Input
            v-if="!vt.type || vt.type === 'input'"
            :key="`${vt.prop}-${i}`"
            :name="vt.name"
            :placeholder="vt.placeholder"
            :value="item[vt.prop]"
            @debounced-input="onChange(index, vt.prop, $event)"
          />
          <Select
            v-else-if="vt.type === 'select'"
            :key="`${vt.prop}-${i}`"
            :name="vt.name"
            :placeholder="vt.placeholder"
            :options="vt.options"
            :value="item[vt.prop]"
            @input="onChange(index, vt.prop, $event)"
          />
        </template>
      </div>
      <button
        class="parameters-panel-multi-input__button parameters-panel-multi-input__button--remove"
        @click="onRemove(index)"
        v-if="!noRemove"
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M8 4C7.44772 4 7 4.44772 7 5V7.5H4C3.44772 7.5 3 7.94772 3 8.5C3 9.05228 3.44772 9.5 4 9.5H5.04501L5.82486 17.2985C5.97822 18.8321 7.26872 20 8.80998 20H15.19C16.7313 20 18.0218 18.8321 18.1751 17.2985L18.955 9.5H20C20.5523 9.5 21 9.05228 21 8.5C21 7.94772 20.5523 7.5 20 7.5H17V5C17 4.44772 16.5523 4 16 4H8ZM15 7.5V6H9V7.5H15ZM7.05499 9.5H16.945L16.1851 17.0995C16.1339 17.6107 15.7038 18 15.19 18H8.80998C8.29622 18 7.86606 17.6107 7.81494 17.0995L7.05499 9.5Z" fill="#8D9CA8"></path></svg>
      </button>
    </div>
    <button class="parameters-panel-multi-input__button" @click="addInput" v-if="!noAdd">
      <Plus />
    </button>
  </div>
</template>

<script>
import Plus from "@/components/ui/icons/constructor/Plus.vue";
import Input from "../Input";
import dcopy from "deep-copy";
import _ from "lodash";
import Select from "@/modules/shared/atoms/select";

export default {
  name: 'ParametersPanelMultiInput',

  components: {
    Select,
    Input,
    Plus
  },

  props: {
    value: {
      type: Array,
      default: () => []
    },
    valueTemplate: {
      type: Array,
      required: true
    },
    noAdd: {
      type: Boolean,
      default: false
    },
    noRemove: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    inputs: {
      cache: false,
      get () {
        const inputs = {}
        if (this.value) {
          this.value.forEach((v, i) => {
            inputs[i.toString()] = v
          })
        }
        return inputs
      }
    },

    inputValues () {
      return this.inputs && this.inputs !== {} ? Object.values(this.inputs) : []
    },

    valueModel() {
      return this.valueTemplate.reduce((obj, item) => ({ ...obj, [item.prop]: '' }), {})
    }
  },

  methods: {
    onChange (inputIndex, inputProp, inputValue) {
      const inputs = dcopy(this.inputs)
      inputs[inputIndex.toString()][inputProp] = inputValue
      this.$emit('input', Object.values(inputs))
    },

    onRemove (inputIndex) {
      const inputs = dcopy(this.inputs)
      delete inputs[inputIndex.toString()]
      this.$emit('input', Object.values(inputs))
    },

    addInput () {
      const inputs = this.inputValues.slice()
      inputs.push(_.cloneDeep(this.valueModel))
      this.$emit('input', inputs)
    }
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
