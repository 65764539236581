<template>
  <vn-select-add
    class="parameter-select"
    :label="prop.name"
    :value="value || placeholderValue"
    :options="availableFontWeights"
    @input="onUpdate"
    no-clear
    no-remove-option
  />
</template>

<script>
import parameterItem from "../mixins/parameterItem";
import {mapState} from "vuex";
import {getPropValueBySize} from "@/containers/Constructor/containers/Editor/utils";
import syncFontStyle from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/utils/syncFontStyle";
import VnSelectAdd from '@/components/ui/vn-select-add.vue';

export default {
  name: "ParameterFontWeight",

  components: {
    VnSelectAdd,
  },

  mixins: [ parameterItem ],

  computed: {
    ...mapState({
      availableFonts: state => state.constructor2.availableFonts,
      activeComponent: state => state.constructor2.activeComponent
    }),

    availableFontWeights () {
      let availableFontWeights = [];
      const fontFamilyValue = (
        getPropValueBySize(this.activeComponent?.props?.fontFamily?.value, this.size)
        || (typeof this.activeComponent?.props?.fontFamily?.value === 'string'
          && this.activeComponent?.props?.fontFamily?.value)
      )
      const fontStyleValue = getPropValueBySize(this.activeComponent?.props?.fontStyle?.value, this.size)
        || this.activeComponent?.props?.fontStyle?.value
        || getPropValueBySize(this.activeComponent?.props?.fontStyle?.defaultValue, this.size)
        || this.activeComponent?.props?.fontStyle?.defaultValue
      if (!!fontFamilyValue) {
        availableFontWeights = [...new Set(this.availableFonts.filter(f => (
          f.fontFamily === fontFamilyValue && f.fontStyle === fontStyleValue
        )).reduce((a, c) => [...a, c.fontWeight], []) || [])]
      } else {
        const fontFamilyDefaultValue = (
          getPropValueBySize(this.activeComponent?.props?.fontFamily?.defaultValue, this.size)
          || (typeof this.activeComponent?.props?.fontFamily?.defaultValue === 'string'
            && this.activeComponent?.props?.fontFamily?.defaultValue)
        )
        if (!!fontFamilyDefaultValue) {
          availableFontWeights = [...new Set(this.availableFonts.filter(f => (
            f.fontFamily === fontFamilyDefaultValue && f.fontStyle === fontStyleValue
          )).reduce((a, c) => [...a, c.fontWeight], []) || [])]
        }
      }
      return availableFontWeights.sort((a,b) => Number(a) > Number(b) ? 1 : -1)
    }
  },

  methods: {
    onUpdate (value) {
      this.updateProp(value)
      const currentFont = getPropValueBySize(this.activeComponent.props.fontFamily.value, this.size)
        || this.activeComponent.props.fontFamily.value
        || getPropValueBySize(this.activeComponent.props.fontFamily.defaultValue, this.size)
        || this.activeComponent.props.fontFamily.defaultValue
      syncFontStyle(currentFont, this.availableFonts, this.activeComponent, this.size, this.valueType, this.updateActiveComponentProp)
    }
  }
}
</script>
