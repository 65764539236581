<template>
  <c2-button @click="onAddBubble">Add Bubble</c2-button>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import C2Button from "@/modules/constructor2/shared/atoms/button";
import {ALL_ELEMENTS} from "@/store/modules/constructor2/components/consts";
export default {
  name: "ParametersPanelTitleParametersBubbles",

  components: {C2Button},

  computed: {
    ...mapState({
      activeComponent: state => state.constructor2.activeComponent,
      activeSize: state => state.constructor2.activeSize
    }),
  },

  methods: {
    ...mapMutations({
      addComponent: 'constructor2/addComponent'
    }),

    onAddBubble () {
      this.addComponent({
        componentKey: ALL_ELEMENTS.Bubble.componentKey,
        insideActive: true,
        makeActive: false,
        additionalProps: {
          top: {
            ...ALL_ELEMENTS.Bubble.props.top,
            defaultValue: Math.floor(Math.random() * (95 - 5 + 1) + 5)
          },
          left: {
            ...ALL_ELEMENTS.Bubble.props.left,
            defaultValue: Math.floor(Math.random() * (95 - 5 + 1) + 5)
          }
        }
      })
    }
  }
}
</script>
