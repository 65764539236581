<template>
  <Accordion block expanded-by-default>
    <template #top>
      Option Settings
    </template>

    <div class="stream-editor-parameter-panel__props">
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Value</div>
        <ParameterInput :prop="{...activeComponent.props.value, key: 'value'}" :size="size" :active-size="size" :value-type="valueType" />
      </div>
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Label</div>
        <ParameterInput :prop="{...activeComponent.props.label, key: 'label'}" :size="size" :active-size="size" :value-type="valueType" />
      </div>
    </div>
  </Accordion>
</template>

<script>
import Accordion from '@/containers/Constructor/containers/Editor/components/Accordion'
import settings from "@/modules/constructor2/editor/parameters-panel/mixins/settings";
import ParameterInput from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Input";
import ParametersPanelParameterCheckbox from "@/modules/constructor2/editor/parameters-panel/atoms/parameter-checkbox";
import {mapGetters} from "vuex";
import {AVAILABLE_SIZES_LIST} from "@/store/modules/constructor2/consts";
import C2PSelect from "@/modules/constructor2/editor/parameters-panel/molecules/select";
import {getSizedPropValue} from "@/containers/Constructor/containers/Editor/utils";

export default {
  name: "ParametersPanelSettingsFormSelectOption",

  mixins: [settings],

  components: {
    ParameterInput,
    Accordion,
  },
}
</script>
