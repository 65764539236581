<template>
  <c2-button @click="onAddSlide">Add Slide</c2-button>
</template>

<script>
import {mapMutations} from "vuex";
import C2Button from "@/modules/constructor2/shared/atoms/button";
import {ALL_ELEMENTS} from "@/store/modules/constructor2/components/consts";
export default {
  name: "ParametersPanelTitleParametersSlider",

  components: {C2Button},

  methods: {
    ...mapMutations({
      addComponent: 'constructor2/addComponent'
    }),

    onAddSlide () {
      this.addComponent({
        componentKey: ALL_ELEMENTS.Slide.componentKey,
        insideActive: true,
        makeActive: false,
      })
    }
  }
}
</script>
