<template>
  <control-item title="Select template">
    <VnSelectAdd
      class="stream-controls-item__select"
      :label="$t('constructor.Templates')"
      :non-removables="DEFAULT_TEMPLATES"
      :addHandler="() => {this.addPopupShown = true}"
      :delHandler="()=>{this.removePopupShown = true}"
      @returnValForDelete="val=>{this.forDelete = val}"
      :value="activeTemplate"
      @input="changeTemplate($event)"
      icon="search"
      :loading="loading"
      :options="templates"
      noClear
    />
    <va-modal
      v-model="addPopupShown"
      size="medium"
      :title="$t('constructor.AddTemplate')"
      cancelText=""
      okText="Add"
      @ok="templateVal.trim() !== '' ? addTemplate(templateVal +'_template') : null"
    >
      <div class="layout fluid gutter--md">
        <div class="row">
          <div class="flex xs12">
            <va-input
              :label="$t('constructor.Template')"
              v-model="templateVal"
              class="select-template-modal-input"
              noClear
            />
            <div class="select-template-modal-text">
              _template
            </div>
          </div>
        </div>
      </div>
    </va-modal>
    <va-modal
      v-model="removePopupShown"
      size="medium"
      title="Delete template"
      :message="`This action will delete template.`"
      okText="Delete"
      cancelText="Cancel"
      @ok="onDeleteTemplate"
    />
  </control-item>
</template>

<script>
import {mapMutations, mapState, mapGetters, mapActions} from "vuex";
import {DEFAULT_TEMPLATES} from "@/store/modules/constructor2/consts";
import ControlItem from "../../components/ControlItem";
import VnSelectAdd from '@/components/ui/vn-select-add.vue';
import {showToastError} from "@/services/Helpers/HelperToast";

export default {
  name: "SelectTemplate",

  components: {
    ControlItem,
    VnSelectAdd
  },

  data () {
    return {
      DEFAULT_TEMPLATES,
      addPopupShown: false,
      removePopupShown: false,
      forDelete: null,
      templateVal: null,
      loading: false,
    }
  },

  watch: {
    addPopupShown() {
      this.templateVal = null
    }
  },

  computed: {
    ...mapState({
      activeTemplate: state => state.constructor2.activeTemplate,
    }),
    ...mapGetters({
      templates: 'constructor2/templates'
    })
  },

  methods: {
    ...mapMutations({
      set: 'constructor2/set',
      addTemplate: 'constructor2/addTemplate',
      changeTemplate: 'constructor2/changeTemplate',
    }),
    ...mapActions({
      deleteTemplate: 'constructor2/deleteTemplate',
    }),

    async onDeleteTemplate() {
      this.loading = true
      await this.deleteTemplate({template: this.forDelete, epackId: this.$route.params.id !== 'untitled' ? this.$route.params.id : null})
        .catch(() => {
          showToastError('Something went wrong while removing the template', this.$toast)
        })
      this.loading = false
    }
  }
};
</script>

<style lang="scss" src="./index.scss"></style>
