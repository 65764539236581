<template>
  <div :class="[isEmpty && 'stream--empty stream-workarea-components--empty']">
    <WorkareaComponent
      v-for="(component, index) in nestedComponents"
      :key="`${component.id}-${component.parentId}-${component.order}-${index}`"
      :component="component"
    />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import WorkareaComponent from './containers/Component'

export default {
  name: "WorkareaComponents",

  components: {
    WorkareaComponent
  },

  computed: {
    ...mapGetters({
      nestedComponents: 'constructor2/nestedComponents'
    }),

    isEmpty () {
      return !this.nestedComponents.length
    }
  }
};
</script>

<style lang="scss" src="./index.scss"></style>
