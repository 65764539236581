<template>
  <div class="stream-header">
    <router-link to="/" class="stream-header__logo">
      <svg class="auth-logo" width="616" height="146" viewBox="0 0 616 146" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'">
  <path d="M540.891 146H310.249C265.18 146 236 117.819 236 74.0493V71.9507C236 28.2806 265.18 0 310.249 0H540.891C585.96 0 615.14 28.1807 615.14 71.9507V74.0493C615.14 117.819 585.96 146 540.891 146ZM310.249 3.69748C267.279 3.69748 239.598 30.5791 239.598 72.0507V74.1492C239.598 115.721 267.379 142.502 310.349 142.502H540.991C583.962 142.502 611.643 115.621 611.643 74.1492V72.0507C611.643 30.4791 583.862 3.69748 540.891 3.69748H310.249Z" fill="#000000"></path>
  <path d="M208.057 0L13.4908 0.0999376C5.6961 0.0999376 0 5.79604 0 13.5907V132.509C0 140.304 5.6961 146 13.4908 146L208.057 145.9C215.852 145.9 221.548 140.204 221.548 132.409V13.4908C221.548 5.6961 215.852 0 208.057 0ZM105.228 94.7351V108.925H30.679V84.0424C42.5708 81.0445 59.6591 76.7474 67.6537 74.449C83.7426 69.7522 87.7399 65.8549 87.7399 57.9603C87.7399 48.8665 80.245 44.4695 68.0534 44.4695C56.3614 44.4695 46.8679 48.3669 47.3676 63.8563H29.2799C28.2806 45.4688 38.0739 30.0794 67.5537 30.0794C95.7344 30.0794 106.127 41.9712 106.127 56.861C106.127 68.3532 102.13 78.0465 83.6427 83.7426C70.6516 87.7399 57.7604 90.2382 44.9692 92.5366V94.4353L105.228 94.7351ZM195.666 100.831H181.975V111.224H166.286V100.831H115.721V76.6475C138.905 64.5558 154.194 42.7707 157.492 31.6783H178.078C165.487 55.2622 143.002 77.7468 129.311 84.642V87.4401H166.286V67.7536H181.975V87.4401H195.666V100.831Z" fill="#000000"></path>
  <path d="M272.041 82.2488H262C262 93.2537 271.882 97 285.43 97C299.297 97 309.418 92.7854 309.418 82.4049C309.418 74.2878 304.875 69.9951 292.124 68.6683C276.903 67.1073 272.121 66.8732 272.121 62.2683C272.121 58.2098 276.425 56.5707 284.075 56.5707C291.088 56.5707 297.304 57.8195 297.703 63.6732H307.346C307.346 52.7463 297.942 49 284.792 49C271.802 49 262.478 53.6049 262.478 63.5951C262.478 72.0244 267.818 75.6927 281.684 76.8634C294.993 77.9561 299.137 78.2683 299.137 83.4976C299.137 88.1805 293.798 89.4293 286.546 89.4293C279.055 89.4293 272.52 88.1805 272.041 82.2488Z" fill="#000000"></path>
  <path d="M363.227 49.7805H315.411V57.9756H334.298V96.2195H344.339V57.9756H363.227V49.7805Z" fill="#000000"></path>
  <path d="M419.044 96.2195L405.974 78.1122C411.234 76.9415 417.131 72.7268 417.131 64.2195C417.131 55.4 411.154 49.5463 400.794 49.5463H371.626V96.2195H381.189V80.6878H396.889L407.01 96.2195H419.044ZM398.084 72.1024H381.189V57.1951H399.28C404.38 57.1951 407.329 59.6146 407.329 64.5317C407.329 69.1366 404.141 72.1024 398.084 72.1024Z" fill="#000000"></path>
  <path d="M468.071 57.5854V49.7805H427.427V96.2195H468.071V88.4146H436.991V76.8634H467.274V69.0585H436.991V57.5854H468.071Z" fill="#000000"></path>
  <path d="M514.723 96.2195H525.482L507.949 49.5463H492.728L474.876 96.2195H484.838L488.823 85.2146H510.818L514.723 96.2195ZM491.692 77.2537L499.103 56.6488H500.697L508.029 77.2537H491.692Z" fill="#000000"></path>
  <path d="M556.449 88.4927H566.968L579.241 56.8829H580.596L580.437 96.2195H590L589.761 49.7805H572.627L562.346 77.7219L551.747 49.7805H534.055L533.816 96.2195H543.06L542.662 56.8829H544.017L556.449 88.4927Z" fill="#000000"></path>
</svg>
    </router-link>
    <select-tab class="stream-header__tabs" />
    <router-link to="/panel/builder-pro" class="stream-header__close">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="#8d9ca8" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711Z"></path></svg>
    </router-link>
  </div>
</template>

<script>
import SelectTab from '../Controls/containers/SelectTab'

export default {
  name: "ConstructorHeader",

  components: {
    SelectTab
  }
};
</script>

<style lang="scss" src="./index.scss"></style>
