<template>
  <vn-select-add
    class="parameter-select"
    :label="prop.name"
    :value="value || placeholderValue"
    :options="availableFontStyles"
    @input="updateProp($event)"
    no-clear
    no-remove-option
  />
</template>

<script>
import parameterItem from "../mixins/parameterItem";
import {mapState} from "vuex";
import {getPropValueBySize} from "@/containers/Constructor/containers/Editor/utils";
import VnSelectAdd from '@/components/ui/vn-select-add.vue';

export default {
  name: "ParameterFontStyle",

  components: {
    VnSelectAdd,
  },

  mixins: [ parameterItem ],

  computed: {
    ...mapState({
      availableFonts: state => state.constructor2.availableFonts,
      activeComponent: state => state.constructor2.activeComponent
    }),

    availableFontStyles () {
      let availableFontStyles = []
      const fontFamilyValue = (
        getPropValueBySize(this.activeComponent?.props?.fontFamily?.value, this.size)
        || (typeof this.activeComponent?.props?.fontFamily?.value === 'string'
          && this.activeComponent?.props?.fontFamily?.value)
      )
      const fontWeightValue = (
        getPropValueBySize(this.activeComponent?.props?.fontWeight?.value, this.size)
        || (typeof this.activeComponent?.props?.fontWeight?.value === 'string'
          && this.activeComponent?.props?.fontWeight?.value)
      )
      if (!!fontFamilyValue && !!fontWeightValue) {
        availableFontStyles = [...new Set(this.availableFonts.filter(f => (
          f.fontFamily === fontFamilyValue && f.fontWeight === (fontWeightValue)
        )).reduce((a, c) => [...a, c.fontStyle], []))]
      } else {
        const fontFamilyDefaultValue = (
          getPropValueBySize(this.activeComponent?.props?.fontFamily?.defaultValue, this.size)
          || (typeof this.activeComponent?.props?.fontFamily?.defaultValue === 'string'
            && this.activeComponent?.props?.fontFamily?.defaultValue)
        )
        const fontWeightDefaultValue = (
          getPropValueBySize(this.activeComponent?.props?.fontWeight?.defaultValue, this.size)
          || (typeof this.activeComponent?.props?.fontWeight?.defaultValue === 'string'
            && this.activeComponent?.props?.fontWeight?.defaultValue)
        )
        if (!!fontFamilyDefaultValue && !!fontWeightDefaultValue) {
          availableFontStyles = [...new Set(this.availableFonts.filter(f => (
            f.fontFamily === fontFamilyDefaultValue && f.fontWeight === (fontWeightDefaultValue)
          )).reduce((a, c) => [...a, c.fontStyle], []))]
        }
      }
      return availableFontStyles.sort()
    }
  }
}
</script>
