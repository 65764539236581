<template>
  <Accordion block :expanded-by-default="accordionExpandedByDefault">
    <template #top>
      Image Settings
    </template>

    <div class="stream-editor-parameter-panel__prop">
      <div class="stream-editor-parameter-panel__prop-title">Image</div>
      <parameter-file :prop="{...activeComponent.props.src, fileType: 'image', key: 'src'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
    </div>

    <div class="stream-editor-parameter-panel__prop">
      <div class="stream-editor-parameter-panel__prop-title">Object fit</div>
      <ParameterSelect :prop="{...activeComponent.props.objectFit, key: 'objectFit'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
    </div>
  </Accordion>
</template>

<script>
import Accordion from '@/containers/Constructor/containers/Editor/components/Accordion'

import ParameterFile from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/File";
import ParameterSelect from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Select";
import settings from "@/modules/constructor2/editor/parameters-panel/mixins/settings";

export default {
  name: "ParametersPanelSettingsImage",

  mixins: [settings],

  components: {
    Accordion,
    ParameterFile,
    ParameterSelect
  },
}
</script>
