<template>
  <div
    v-if="errors.length"
    class="stream-components-form-error"
    @click.stop="$emit('click')"
    @mouseenter="$emit('mouseenter')"
    @mouseleave="$emit('mouseleave')"
  >
    <!--  todo possibly get rid of v-html and make it safe  -->
    <span v-if="content" v-html="content"></span>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {bus} from "@/app/main";

export default {
  name: "FormErrorElement",

  props: {
    empty: {
      type: Boolean,
      default: false
    },
    component: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      errors: []
    }
  },

  created() {
    bus.$on(`update-form-errors-${this.component.id}`, (errors) => this.errors = errors)
  },

  computed: {
    ...mapState({
      activeSize: state => state.constructor2.activeSize
    }),

    content() {
      return this.errors.map(e => `<div>${e}</div>`).join('')
    }
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
