<template>
  <div class="parameter-file">
    <div class="parameter-file-container">
      <div class="parameter-file-container__overlay" v-if="loading">
        <va-progress-bar
          indeterminate
          :style="{
            width: '100%',
            position: 'absolute',
            left: 0,
            bottom: '-12px'
          }"
        />
      </div>
      <div class="parameter-file__inner">
        <va-file-upload
          type="single"
          v-model="file"
          class="parameter-file__dropzone dropzone--white dropzone--vertical"
          :style="{ background: '#ffffff', border: '2px dashed #D6DEE2' }"
          dropzone
        />
        <div class="parameter-file__preview" v-if="value">
          <div class="parameter-file__preview__file">
            <div class="parameter-file__preview__file__actions">
              <button class="parameter-file__preview__file__actions-item" @click="onRemoveFile">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M8 4C7.44772 4 7 4.44772 7 5V7.5H4C3.44772 7.5 3 7.94772 3 8.5C3 9.05228 3.44772 9.5 4 9.5H5.04501L5.82486 17.2985C5.97822 18.8321 7.26872 20 8.80998 20H15.19C16.7313 20 18.0218 18.8321 18.1751 17.2985L18.955 9.5H20C20.5523 9.5 21 9.05228 21 8.5C21 7.94772 20.5523 7.5 20 7.5H17V5C17 4.44772 16.5523 4 16 4H8ZM15 7.5V6H9V7.5H15ZM7.05499 9.5H16.945L16.1851 17.0995C16.1339 17.6107 15.7038 18 15.19 18H8.80998C8.29622 18 7.86606 17.6107 7.81494 17.0995L7.05499 9.5Z" fill="#8D9CA8"></path></svg>
              </button>
            </div>
            <img :src="domain + value" alt="" v-if="prop.fileType === 'image'" />
            <video :src="domain + value" v-else-if="prop.fileType === 'video'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import parameterItem from "../../mixins/parameterItem";
import { mapState } from "vuex";

import { showToastError } from "@/services/Helpers/HelperToast";
import axios from "axios";

export default {
  name: "ParameterFile",

  mixins: [ parameterItem ],

  props: {
    prop: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      loading: false,
      file: [],
      domain: process.env.VUE_APP_CONSTRUCTOR_2_BASE_URL,
    };
  },

  computed: {
    ...mapState({
      activeTemplate: (state) => state.constructor2.activeTemplate,
      activeLocale: (state) => state.constructor2.activeLocale,
    }),
  },

  watch: {
    file: {
      deep: true,
      async handler () {
        if (this.file.length) {
          let formData = new FormData();
          formData.append("file", this.file[this.file.length - 1]);
          this.loading = true;
          await axios.post(
            `${process.env.VUE_APP_CONSTRUCTOR_2_BASE_URL}/api/constructor/load/${this.prop?.fileType === 'video' ? 'video' : 'image'}`,
            formData, {
              headers: {
                Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`
              }
            })
            .then((response) => {
              if (response.data.code === 200) {
                this.updateProp(response.data.data.path)
              } else if (response.data.code === 402) {
                showToastError(response.data.message, this.$toast);
              } else {
                showToastError(
                  "Something wrong while uploading the file. <br/> Please try later",
                  this.$toast
                );
              }
              this.loading = false;
            })
            .catch((error) => {
              if (error.response.status === 402) {
                showToastError(error.response.data.data.message, this.$toast);
              } else {
                showToastError(
                  "Something wrong while uploading the file. <br/> Please try later",
                  this.$toast
                );
              }
              this.loading = false;
            })
        }
      },
    },
  },

  methods: {
    onRemoveFile() {
      this.file = [];
      this.updateActiveComponentProp({
        prop: this.prop,
        updatedProp: {
          ...this.prop,
          value: {
            ...(typeof this.prop.value === 'string' ? {
              desktop: this.prop.value
            } : this.prop.value || {}),
            [`${this.formattedValueType !== 'general' ? `${this.formattedValueType}_`: ''}${this.size}`]: undefined
          }
        }
      })
    }
  }
};
</script>

<style lang="scss" src="./index.scss"></style>
