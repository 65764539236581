<template>
  <Accordion class="stream-editor-add-component" expanded-by-default block v-if="components && components.length">
    <template #top>
      {{ title }}
    </template>
    <add-component-item
      v-for="(item, index) in components"
      :component="item"
      :key="index"
      :custom="custom"
    />
  </Accordion>
</template>

<script>
import Accordion from '@/containers/Constructor/containers/Editor/components/Accordion'
import AddComponentItem from './containers/AddComponentItem'

export default {
  name: "AddComponent",

  components: {
    Accordion,
    AddComponentItem,
  },

  props: {
    title: {
      type: String,
      required: true
    },
    components: {
      type: Array,
      default: () => []
    },
    custom: Boolean
  }
};
</script>
