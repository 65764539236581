<template>
  <control-item title="Select locale" class="constructor2-controls-select-locale">
    <VnSelectAdd
      class="stream-controls-item__select constructor2-controls-select-locale__select"
      :label="$t('constructor.Locales')"
      :non-removables="['ru']"
      :addHandler="() => {this.addPopupShown = true}"
      :delHandler="() => {this.removePopupShown = true}"
      @returnValForDelete="val => {this.forDelete = val}"
      :value="activeLocale"
      @input="changeLocale($event)"
      icon="search"
      :loading="loading"
      :options="locales"
      noClear
    />
    <va-modal
      v-model="addPopupShown"
      size="medium"
      :title="$t('constructor.AddLocale')"
      okText="Add"
      cancelText=""
      @ok="countryVal !== null && languageVal !== null ? addLocale(`${countryVal}_${languageVal}`) : null"
    >
      <div class="layout fluid gutter--md">
        <div class="row">
          <div class="flex xs12 sm6">
            <va-select
              :label="$t('constructor.Country')"
              v-model="countryVal"
              icon="search"
              :options="countries"
              noClear
            />
          </div>
          <div class="flex xs12 sm6">
            <va-select
              :label="$t('constructor.Language')"
              v-model="languageVal"
              icon="search"
              :options="languages"
              noClear
            />
          </div>
        </div>
      </div>
    </va-modal>
    <va-modal
      v-model="removePopupShown"
      size="medium"
      title="Delete Locale"
      :message="`This action will delete locale.`"
      okText="Delete"
      cancelText="Cancel"
      @ok="onDeleteLocale"
    />
  </control-item>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {AVAILABLE_COUNTRIES, AVAILABLE_LANGUAGES} from "@/store/modules/constructor2/consts";
import ControlItem from "../../components/ControlItem";
import VnSelectAdd from '@/components/ui/vn-select-add.vue';
import {showToastError} from "@/services/Helpers/HelperToast";

export default {
  name: "SelectLocale",

  components: {
    ControlItem,
    VnSelectAdd
  },

  data () {
    return {
      addPopupShown: false,
      removePopupShown: false,
      forDelete: null,
      countryVal: null,
      languageVal: null,
      loading: false,
      countries: AVAILABLE_COUNTRIES,
      languages: AVAILABLE_LANGUAGES,
    }
  },

  watch: {
    addPopupShown() {
      this.countryVal = null
      this.languageVal = null
    }
  },

  computed: {
    ...mapState({
      activeLocale: state => state.constructor2.activeLocale
    }),

    ...mapGetters({
      locales: 'constructor2/locales'
    })
  },

  methods: {
    ...mapMutations({
      addLocale: 'constructor2/addLocale',
      changeLocale: 'constructor2/changeLocale',
    }),
    ...mapActions({
      deleteLocale: 'constructor2/deleteLocale',
    }),

    async onDeleteLocale() {
      this.loading = true
      await this.deleteLocale({locale: this.forDelete, epackId: this.$route.params.id !== 'untitled' ? this.$route.params.id : null})
        .catch(() => {
          showToastError('Something went wrong while removing the locale', this.$toast)
        })
      this.loading = false
    }
  }
};
</script>

<style lang="scss" src="./index.scss"></style>
