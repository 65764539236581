<template>
  <input
    class="parameters-panel-input"
    :value="value"
    :name="name"
    :placeholder="placeholder"
    :disabled="disabled"
    @input="onInput"
    @change="onInput($event, 'change')"
    :type="type"
    :min="min"
    @focus="$emit('focus')"
    @blur="$emit('blur')"
    ref="input"
  >
</template>

<script>
import { debounce } from 'vue-debounce';

export default {
  name: "ParametersPanelInput",

  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    placeholder: {
      type: [String, Number],
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    name: {
      type: String,
      default: ''
    },
    min: {
      type: Number,
      default: undefined
    },
    debounceMs: {
      type: Number,
      default: 500
    },
    enforceValue: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      localValue: ''
    }
  },

  methods: {
    onInput (e, type = 'input') {
      this.localValue = e.target.value
      this.emitValue(e.target.value, e, type)
      this.onDebouncedInput(e)
    },

    onDebouncedInput: debounce(function (e) {
      this.emitValue(this.localValue, e, 'debounced-input')
    }, 500),

    emitValue (value, e, emitEvent) {
      this.$emit(emitEvent, value)
      if (this.enforceValue) {
        this.$nextTick(() => {
          if (e) e.target.value = this.value
          else this.$refs.input.value = this.value
        })
      }
    }
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
