<template>
  <ul class="the-tab-select" :class="{ 'the-tab-select--thin': thin }">
    <li
      class="the-tab-select__item"
      :class="{ 'the-tab-select__item--active': value && value.id === item.id }"
      v-for="(item, index) in items"
      :key="index"
      :title="item.title"
      @click="$emit('input', item)"
    >
      {{ item.content }}
      <slot :name="`slot-${index}`" />
    </li>
  </ul>
</template>

<script>
export default {
  name: "TabSelect",

  props: {
    items: {
      type: Array,
      default: null
    },
    value: {
      type: Object,
      default: null
    },
    thin: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" src="./index.scss"></style>
