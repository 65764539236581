<template>
  <div class="stream-controls-item">
    <div class="stream-controls-item__title">
      <slot name="title">
        {{ title }}
      </slot>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: "ControlItem",

  props: {
    title: {
      type: String,
      default: 'Control item'
    }
  }
};
</script>

<style lang="scss" src="./index.scss"></style>
