import {mapMutations} from "vuex";
import {getPropValueBySize} from "@/containers/Constructor/containers/Editor/utils";

export default {
  props: {
    prop: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      default: 'desktop'
    },
    activeSize: {
      type: String,
      default: 'desktop'
    },
    valueType: {
      type: String,
      default: 'general'
    }
  },

  data() {
    return {
      undefinedValue: ''
    }
  },

  computed: {
    formattedValueType () {
      return this.valueType
    },

    value () {
      if (this.prop?.value) {
        if (this.formattedValueType !== 'general') {
          const typedValue = (this.prop.value[`${this.formattedValueType}_${this.size}`]) || this.undefinedValue
          if (typedValue) return typedValue
        }
        return (!!this.prop.value[this.size] || typeof this.prop.value === 'string')
          && (this.prop.value[this.size] || this.prop.value) || this.undefinedValue
      }
      return this.undefinedValue
    },

    placeholderValue () {
      if (this.prop && (this.prop.value || this.prop.defaultValue)) {
        if (this.formattedValueType !== 'general') {
          const typedValue = getPropValueBySize(this.prop.value, this.size, this.formattedValueType)
          if (typedValue) return typedValue
        }
        let value = getPropValueBySize(this.prop.value, this.size)
          || (['string', 'number'].includes(typeof this.prop.value) && this.prop.value.toString()) || ''
        if (value) return value
        value = getPropValueBySize(this.prop.defaultValue, this.size)
          || (['string', 'number'].includes(typeof this.prop.defaultValue) && this.prop.defaultValue.toString()) || ''
        if (value) return value
      }
      return ''
    }
  },

  watch: {
    size: {
      immediate: true,
      handler() {
        if (this.value && this.value !== this.undefinedValue &&
          (!(this.value instanceof Array) || (this.value instanceof Array && this.value.length))) {
          this.onHasValue()
        }
      }
    }
  },

  methods: {
    ...mapMutations({
      updateActiveComponentProp: 'constructor2/updateActiveComponentProp'
    }),

    updateProp (value, prop = this.prop) {
      if (
        prop.value !== value
        && prop.value?.[`${this.formattedValueType !== 'general' ? `${this.formattedValueType}_`: ''}${this.size}`] !== value
      ) {
        this.updateActiveComponentProp({
          prop: prop,
          updatedProp: {
            ...prop,
            value: {
              ...(typeof prop.value === 'string' ? {
                desktop: prop.value
              } : prop.value || {}),
              [`${this.formattedValueType !== 'general' ? `${this.formattedValueType}_` : ''}${this.size}`]: value
            }
          }
        })
      }
    },

    onHasValue() {
      this.$emit('has-value')
    }
  }
}
