<template>
  <c2-button @click="onAddColumn">Add Column</c2-button>
</template>

<script>
import {mapMutations} from "vuex";
import C2Button from "@/modules/constructor2/shared/atoms/button";
import {ALL_ELEMENTS} from "@/store/modules/constructor2/components/consts";

export default {
  name: "ParametersPanelTitleParametersTableRow",

  components: {C2Button},

  methods: {
    ...mapMutations({
      addComponent: 'constructor2/addComponent'
    }),

    onAddColumn () {
      this.addComponent({
        componentKey: ALL_ELEMENTS.Column.componentKey,
        insideActive: true,
        makeActive: false,
      })
    }
  }
}
</script>
