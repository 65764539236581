<template>
  <div
    class="stream-editor-accordion"
    :class="{
      'stream-editor-accordion--block': block,
      'stream-editor-accordion--active': !isCollapsed,
      'stream-editor-accordion--with-content': hasChildren,
    }"
  >
    <div
      class="stream-editor-accordion__top"
      @click="onClickTop"
    >
      <div class="stream-editor-accordion__top-content" @click="onClickTopContent">
        <slot name="top" />
      </div>
      <slot name="icon">
        <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" v-bind:class="'stream-editor-accordion__top-arrow'" v-bind:svg-inline="''" @click="onClickArrow" v-if="$slots.default" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'">
  <defs>
    <linearGradient id="arrow-down-gradient" x2="0.35" y2="1">
      <stop offset="0%" stop-color="#2d4de0" />
      <stop offset="40%" stop-color="#9f71f0" />
      <stop offset="90%" stop-color="#fc6277" />
    </linearGradient>
  </defs>
  <defs>
    <linearGradient id="arrow-down-gradient-green" x2="1" y2=".25">
      <stop offset="0%" stop-color="#5166d7" />
      <stop offset="100%" stop-color="#43f59f" />
    </linearGradient>
  </defs>
  <path d="M5 6l5 5 5-5 2 1-7 7-7-7z"/>
</svg>
      </slot>
    </div>
    <div class="stream-editor-accordion__content" v-if="$slots.default" v-show="!isCollapsed">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "Accordion",

  props: {
    expandedByDefault: {
      type: Boolean,
      default: undefined
    },
    toggleOnArrow: {
      type: Boolean,
      default: false
    },
    hasChildren: {
      type: Boolean,
      default: undefined
    },
    block: {
      type: Boolean,
      default: false,
    }
  },

  data () {
    return {
      isCollapsed: (
        this.hasChildren === false ||
        (this.hasChildren === undefined && !this.expandedByDefault)
      ),
    }
  },

  watch: {
    hasChildren (n, o) {
      if (n === true && !o) {
        this.isCollapsed = false
      }
    },

    expandedByDefault () {
      this.isCollapsed = this.expandedByDefault === false
    }
  },

  methods: {
    toggle () {
      this.isCollapsed = !this.isCollapsed
    },

    onClickTop () {
      if (this.$slots.default && !this.toggleOnArrow) {
        this.toggle()
      }
    },

    onClickTopContent () {
      this.$emit('click-top-content')
    },

    onClickArrow () {
      if (this.$slots.default && this.toggleOnArrow) this.toggle()
    }
  }
};
</script>

<style lang="scss" src="./index.scss"></style>
