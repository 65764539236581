<template>
  <c2-button @click="onRunScript">Run code</c2-button>
</template>

<script>
import C2Button from "@/modules/constructor2/shared/atoms/button";
import {bus} from "@/app/main";

export default {
  name: "ParametersPanelTitleParametersScript",

  components: {C2Button},

  methods: {
    onRunScript () {
      bus.$emit('constructor-run-script')
    }
  }
}
</script>
