<template>
  <Accordion block :expanded-by-default="accordionExpandedByDefault">
    <template #top>
      {{accordionTitle}}
    </template>

    <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.content">
      <div class="stream-editor-parameter-panel__prop-title">Text</div>
      <ParameterInput :prop="{...activeComponent.props.content, key: 'content'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
    </div>

    <div class="stream-editor-parameter-panel__props">
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Color</div>
        <ParameterColorPick :prop="{...activeComponent.props.color, key: 'color'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
      </div>
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Font style</div>
        <ParameterFontStyle :prop="{...activeComponent.props.fontStyle, key: 'fontStyle'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
      </div>
    </div>

    <div class="stream-editor-parameter-panel__props">
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Font family</div>
        <ParameterFontFamily :prop="{...activeComponent.props.fontFamily, key: 'fontFamily'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
      </div>
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Font weight</div>
        <ParameterFontWeight :prop="{...activeComponent.props.fontWeight, key: 'fontWeight'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
      </div>
    </div>

    <div class="stream-editor-parameter-panel__props">
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Font size</div>
        <ParameterFontSize :prop="{...activeComponent.props.fontSize, key: 'fontSize'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
      </div>
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Line height</div>
        <ParameterLineHeight :prop="{...activeComponent.props.lineHeight, key: 'lineHeight'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
      </div>
    </div>

    <div class="stream-editor-parameter-panel__props" v-if="activeComponent.props.element || activeComponent.props.maxLines">
      <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.element">
        <div class="stream-editor-parameter-panel__prop-title">Element</div>
        <ParameterSelect :prop="{...activeComponent.props.element, key: 'element'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
      </div>
      <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.maxLines">
        <div class="stream-editor-parameter-panel__prop-title">Max lines</div>
        <ParameterInput :prop="{...activeComponent.props.maxLines, key: 'maxLines'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
      </div>
    </div>

    <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.textAlign">
      <div class="stream-editor-parameter-panel__prop-title">Text align</div>
      <ParameterAlign :prop="{...activeComponent.props.textAlign, key: 'textAlign'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
    </div>
  </Accordion>
</template>

<script>
import Accordion from '@/containers/Constructor/containers/Editor/components/Accordion'

import ParameterSelect from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Select";
import ParameterInput from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Input";
import ParameterColorPick
  from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/ColorPick";
import ParameterFontStyle
  from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/FontStyle";
import ParameterFontFamily
  from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/FontFamily";
import ParameterFontWeight
  from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/FontWeight";
import ParameterFontSize
  from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/FontSize";
import ParameterLineHeight
  from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/LineHeight";
import ParameterAlign from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Align";
import settings from "@/modules/constructor2/editor/parameters-panel/mixins/settings";

export default {
  name: "ParametersPanelSettingsText",

  mixins: [settings],

  props: {
    accordionTitle: {
      type: String,
      default: 'Text Settings'
    }
  },

  components: {
    ParameterAlign,
    ParameterLineHeight,
    ParameterFontSize,
    ParameterFontWeight,
    ParameterFontFamily,
    ParameterFontStyle,
    Accordion,
    ParameterSelect,
    ParameterInput,
    ParameterColorPick
  },
}
</script>
