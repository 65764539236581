<template>
  <div
    class="stream-components-popover"
    :class="{'stream-components-popover--triggered': triggered}"
    @click.stop="$emit('click')"
    @mouseenter="$emit('mouseenter')"
    @mouseleave="$emit('mouseleave')"
  >
    <slot />
  </div>
</template>

<script>
import {getSizedPropValue} from "@/containers/Constructor/containers/Editor/utils";
import {mapState} from "vuex";

export default {
  name: "PopoverElement",

  props: {
    component: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    ...mapState({
      activeSize: state => state.constructor2.activeSize
    }),

    triggered () {
      return getSizedPropValue(this.component?.props?.triggered, this.activeSize) === 'on'
    }
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
