<template>
  <div class="parameter-border">
    <number-input-select
      class="parameter-border__input-area"
      :input-value="border.number"
      :select-value="border.suffix"
      :select-items="['px', 'rem', 'em']"
      @change="onNumberInput"
      @select-change="onSuffixChange"
    />
    <Select
      class="parameter-border__type"
      :value="border.type"
      :items="['solid', 'dashed', 'dotted', 'double', 'groove', 'ridge', 'inset', 'outset']"
      @input="onTypeChange"
    />
    <ColorPick
      :value="border.color"
      placeholder="Border color"
      @input="onColorChange"
    />
  </div>
</template>

<script>
import parameterItem from "../../mixins/parameterItem";
import NumberInputSelect from '../../components/NumberInputSelect'
import Select from '../../components/Select'
import ColorPick from '../../components/ColorPick'

export default {
  name: "ParameterBorder",

  components: {
    NumberInputSelect,
    Select,
    ColorPick,
  },

  mixins: [ parameterItem ],

  computed: {
    border () {
      const borderProps = (this.value || this.placeholderValue).split(' ')
      return {
        number: borderProps[0].match(/(\d+)/)?.[0] || '',
        suffix: borderProps[0].match(/%|rem|px|em/)?.[0] || '',
        type: borderProps[1] || '',
        color: borderProps[2] || '',
      }
    }
  },

  methods: {
    onNumberInput (value) {
      const numberValue = value?.match(/(\d+)/)?.[0] || ''
      let suffixValue = this.border.suffix
      let typeValue = this.border.type || 'solid'
      let colorValue = this.border.color || '#000000'
      if (value) {
        if (value.includes('p')) suffixValue = 'px'
        else if (value.includes('%')) suffixValue = '%'
        else if (value.includes('r')) suffixValue = 'rem'
        else if (value.includes('e')) suffixValue = 'em'
        else if (!suffixValue) suffixValue = 'px'
      } else {
        suffixValue = ''
        typeValue = ''
        colorValue = ''
      }
      this.updateProp(`${numberValue}${suffixValue} ${typeValue} ${colorValue}`.trim())
    },

    onSuffixChange (suffix) {
      this.updateProp(
        `${this.border.number || 1}${suffix} ${this.border.type || 'solid'} ${this.border.color || '#000000'}`.trim()
      )
    },

    onTypeChange (type) {
      this.updateProp(
        `${this.border.number || 1}${this.border.suffix || 'px'} ${type} ${this.border.color || '#000000'}`.trim()
      )
    },

    onColorChange (color) {
      this.updateProp(
        `${this.border.number || 1}${this.border.suffix || 'px'} ${this.border.type || 'solid'} ${color}`.trim()
      )
    }
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
