<template>
  <div class="stream-editor-parameter-panel__prop">
    <div class="stream-editor-parameter-panel__prop-title">Youtube Link / ID</div>
    <ParameterInput :prop="{...activeComponent.props.youtubeId, key: 'youtubeId'}" :size="size" :active-size="size" :value-type="valueType" />
  </div>
</template>

<script>
import {mapState} from "vuex";
import ParameterInput from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Input";

export default {
  name: "ParametersPanelTitleParametersYoutube",

  props: {
    size: {
      type: String,
      default: 'desktop'
    },
    valueType: {
      type: String,
      default: 'general'
    }
  },

  components: {
    ParameterInput,
  },

  computed: {
    ...mapState({
      activeComponent: state => state.constructor2.activeComponent
    })
  }
}
</script>
