<template>
  <Accordion
    class="parameters-panel-dynamic-data"
    :class="{'parameters-panel-dynamic-data--active': isActive}"
    :expanded-by-default="isActive"
    block
    v-if="canBeDynamic"
  >
    <template #top>
      <div class="parameters-panel-dynamic-data__header">
        <div class="parameters-panel-dynamic-data__title">Dynamic data</div>
        <pulsating-dot
          v-if="isActive"
          :color="possibleDynamicParent === 'DynamicDataProvider' ? 'red' : 'blue'"
          :direction="possibleDynamicParent === 'DynamicDataProvider' ? 'outward' : 'inward'"
          :title="`${possibleDynamicParent === 'DynamicDataProvider' ? 'Provider' : 'Receiver'} is active`"
        />
      </div>
    </template>

    <div class="parameters-panel-dynamic-data__content">
      <div class="parameters-panel-dynamic-data__tip" v-if="tipText">
        <span
          v-html="tipText"
          class="parameters-panel-dynamic-data__tip-text"
          :class="{
            'parameters-panel-dynamic-data__tip-text--provider': possibleDynamicParent === 'DynamicDataProvider',
            'parameters-panel-dynamic-data__tip-text--receiver': possibleDynamicParent === 'DynamicDataReceiver',
          }"
        />
        <v-dropdown auto-hide :triggers="['hover']" placement="top" :distance="10">
          <button class="parameters-panel-dynamic-data__tooltip">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'">

  <defs>
    <linearGradient id="question-circled-gradient" x2="0.35" y2="1">
      <stop offset="0%" stop-color="#2d4de0" />
      <stop offset="40%" stop-color="#9f71f0" />
      <stop offset="90%" stop-color="#fc6277" />
    </linearGradient>
    <linearGradient id="question-circled-gradient-green" x2="0.75" y2="1">
      <stop offset="0%" stop-color="#5166d7" />
      <stop offset="100%" stop-color="#43f59f" />
    </linearGradient>
  </defs>

  <path d="M256,0C114.62,0,0,114.62,0,256s114.62,256,256,256s256-114.62,256-256S397.38,0,256,0z M256,486.4
    C128.956,486.4,25.6,383.044,25.6,256S128.956,25.6,256,25.6S486.4,128.956,486.4,256S383.044,486.4,256,486.4z" fill="url(#question-circled-gradient)"/>

  <path d="M319.258,119.578c-13.858-11.981-31.718-17.98-53.581-17.98h-4.403c-22.673,0-42.001,7.074-58.001,21.197
    c-17.604,15.753-26.402,36.804-26.402,63.198c0,4.275,1.203,7.603,3.601,10.001c2.398,2.15,5.325,3.072,8.798,2.799
    c3.2,0,5.999-1.203,8.397-3.601c2.662-2.654,4.002-5.854,4.002-9.6c0-14.925,3.465-27.853,10.402-38.801
    c10.129-15.454,26.522-23.202,49.203-23.202c19.2,0,33.724,5.197,43.597,15.599c8.26,8.55,12.527,19.601,12.8,33.203
    c0,11.998-2.534,22.673-7.603,32c-5.871,10.675-16.401,22.4-31.599,35.2c-12.8,10.402-21.734,21.999-26.803,34.799
    c-5.077,12.271-7.603,27.878-7.603,46.797c0,3.746,1.203,6.801,3.601,9.199c2.398,2.15,5.325,3.2,8.798,3.2
    c3.2,0,5.999-1.05,8.397-3.2c2.662-2.398,4.002-5.453,4.002-9.199c0-18.654,2.261-33.05,6.801-43.204
    c4.523-9.6,13.329-19.729,26.402-30.404c14.404-12.254,24.798-24.124,31.198-35.601c6.127-11.204,9.199-23.723,9.199-37.598
    C342.46,150.929,334.72,132.651,319.258,119.578z" fill="url(#question-circled-gradient)" />

  <circle cx="256" cy="384" r="25.6" fill="url(#question-circled-gradient)"/>
</svg>
          </button>

          <template #popper>
            <div class="parameters-panel-dynamic-data__tooltip-content">
              <template v-if="possibleDynamicParent === 'DynamicDataProvider'">
                <div class="parameters-panel-dynamic-data__tooltip-title">Provider</div>
                Provider duplicates the component (or its children as in the "Slider" component)
                {{ " " }}
                n-times where n is the number of selected items in the "Provider"'s configuration.
                {{ " " }}
                Which data the "Providers" can provide to its children is selected in the "Data Sources" tab.
                {{ " " }}
                Once there are selected data in "Data Sources," you can configure the "Provider" (by clicking "Configure")
                {{ " " }}
                and select which data are provided to the children of this component.
                <br><br>
                Certain components [Text, Link, Image, Youtube, Video] inside of the "Provider" component can "Receive" the provided data
                {{ " " }}
                and display it according to the "Receiver" component's function (regardless of the depth the "Receiver" component is at).
                <br><br>
                Providers' configurations are NOT affected by the selected parameters mode (General/Hover).
                {{ " " }}
                Providers' configurations ARE affected by the selected screen size.
              </template>
              <template v-if="possibleDynamicParent === 'DynamicDataReceiver'">
                <div class="parameters-panel-dynamic-data__tooltip-title">Receiver</div>
                Receiver lets this component receive data from the closest "Provider" component in the upper parent components.
                {{ " " }}
                Received data can be configured (by clicking "Configure") to display the desired data in the view.
              </template>
            </div>
          </template>
        </v-dropdown>
      </div>

      <div class="parameters-panel-dynamic-data__actions">
        <button class="parameters-panel-dynamic-data__button" @click="toggleDynamic">{{ buttonText }}</button>
      </div>
      <div v-if="isActive" class="parameters-panel-dynamic-data__actions">
        <button class="parameters-panel-dynamic-data__button parameters-panel-dynamic-data__button--configure" @click="configure">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 458.317 458.317" style="enable-background:new 0 0 458.317 458.317;" xml:space="preserve" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'">
		<path d="M446.185,179.159h-64.768c-2.536-7.702-5.636-15.15-9.26-22.29l45.818-45.818c4.737-4.737,4.737-12.416,0-17.152
			L364.416,40.34c-4.737-4.737-12.416-4.737-17.152,0l-45.818,45.818c-7.14-3.624-14.587-6.724-22.289-9.26V12.131
			c0.001-6.699-5.429-12.129-12.128-12.129h-75.743c-6.698,0-12.129,5.43-12.129,12.128v64.768
			c-7.702,2.535-15.149,5.636-22.29,9.26L111.05,40.341c-4.737-4.737-12.416-4.737-17.152,0L40.339,93.9
			c-4.737,4.736-4.737,12.416,0,17.152l45.817,45.817c-3.624,7.14-6.725,14.588-9.26,22.29H12.129C5.43,179.159,0,184.59,0,191.288
			v75.743c0,6.698,5.43,12.128,12.129,12.128h64.768c2.536,7.702,5.636,15.149,9.26,22.29L40.34,347.266
			c-4.737,4.736-4.737,12.416,0,17.152l53.559,53.559c4.737,4.736,12.416,4.736,17.152,0l45.817-45.817
			c7.14,3.624,14.587,6.725,22.29,9.26v64.768c0,6.698,5.43,12.128,12.129,12.128h75.743c6.698,0,12.129-5.43,12.129-12.128v-64.768
			c7.702-2.535,15.149-5.636,22.289-9.26l45.818,45.817c4.737,4.736,12.416,4.736,17.152,0l53.559-53.559
			c4.737-4.737,4.737-12.416,0-17.152l-45.817-45.817c3.624-7.14,6.724-14.587,9.26-22.289h64.768
			c6.698,0,12.129-5.43,12.129-12.128v-75.743C458.314,184.59,452.884,179.159,446.185,179.159z M229.157,289.542
			c-33.349,0-60.384-27.035-60.384-60.384s27.035-60.384,60.384-60.384s60.384,27.035,60.384,60.384
			S262.506,289.542,229.157,289.542z"/>
</svg>
          <span>Configure</span>
        </button>
      </div>
    </div>
  </Accordion>
</template>

<script>
import Accordion from '@/containers/Constructor/containers/Editor/components/Accordion'
import {mapMutations, mapState} from "vuex";
import {DYNAMIC_DATA_COMPONENT_KEYS_OBJ, DYNAMIC_DATA_COMPONENTS} from "@/store/modules/constructor2/components/consts";
import findInUpperNestedComponents from "@/containers/Constructor/containers/Editor/mixins/findInUpperNestedComponents";
import {bus} from "@/app/main";
import PulsatingDot from "@/containers/Constructor/containers/Editor/components/PulsatingDot";

export default {
  name: "ParametersPanelDynamicData",

  components: {
    PulsatingDot,
    Accordion
  },

  mixins: [findInUpperNestedComponents],

  computed: {
    ...mapState({
      activeComponent: state => state.constructor2.activeComponent,
    }),

    activeComponentParent() {
      return this.addedComponents.find(component => component.id === this.activeComponent.parentId)
    },

    isActive() {
      if (this.activeComponentParent) {
        return DYNAMIC_DATA_COMPONENT_KEYS_OBJ[this.activeComponentParent.name]
      }
      return false
    },

    possibleDynamicParent() {
      if (DYNAMIC_DATA_COMPONENTS.DynamicDataReceiver.possibleChildren.includes(this.activeComponent.name)) {
        return 'DynamicDataReceiver'
      } else if (DYNAMIC_DATA_COMPONENTS.DynamicDataProvider.possibleChildren.includes(this.activeComponent.name)) {
        return 'DynamicDataProvider'
      }
      return undefined
    },

    canBeDynamic() {
      if (this.possibleDynamicParent) {
        return !(this.possibleDynamicParent === 'DynamicDataReceiver' && !this.dynamicProvider);
      }
      return false
    },

    tipText() {
      if (this.possibleDynamicParent === 'DynamicDataProvider') {
        if (this.isActive) return 'This component is a <b>Provider</b>'
        else return 'This component can be a <b>Provider</b>'
      } else if (this.possibleDynamicParent === 'DynamicDataReceiver') {
        if (this.isActive) return 'This component is a <b>Receiver</b>'
        else return 'This component can be a <b>Receiver</b>'
      }
      return undefined
    },

    buttonText() {
      if (this.possibleDynamicParent === 'DynamicDataProvider') {
        if (this.isActive) return 'Stop providing'
        else return 'Provide'
      } else if (this.possibleDynamicParent === 'DynamicDataReceiver') {
        if (this.isActive) return 'Stop receiving'
        else return 'Receive'
      }
      return '???'
    },

    dynamicProvider() {
      return this.possibleDynamicParent === 'DynamicDataReceiver' && this.activeComponentParent
        ? this.findInUpperNestedComponents('DynamicDataProvider', this.activeComponentParent)?.id || undefined
        : undefined
    }
  },

  methods: {
    ...mapMutations({
      makeComponentDynamic: "constructor2/makeComponentDynamic",
      removeDynamicDataComponent: "constructor2/removeDynamicDataComponent"
    }),

    toggleDynamic() {
      if (this.isActive) this.loseDynamic()
      else this.makeDynamic()
    },

    makeDynamic() {
      this.makeComponentDynamic({
        componentId: this.activeComponent.id,
        dynamicName: this.possibleDynamicParent
      })
    },

    loseDynamic() {
      this.removeDynamicDataComponent(this.activeComponent.parentId)
    },

    configure() {
      bus.$emit('dynamic-configurator', {
        ...this.activeComponentParent,
        relatedDynamicId: this.dynamicProvider
      })
    },
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
