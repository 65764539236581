<template>
  <div
    class="stream-components-form-radio-group"
    :class="[
      { 'stream-components-form-radio-group--empty': isRadioGroupEmpty },
      { 'stream--empty': isRadioGroupEmpty },
    ]"
    @click.stop="$emit('click')"
    @mouseenter="$emit('mouseenter')"
    @mouseleave="$emit('mouseleave')"
  >
    <slot />
  </div>
</template>

<script>
import {mapState} from "vuex";
import {getSizedPropValue} from "@/containers/Constructor/containers/Editor/utils";

export default {
  name: "FormRadioGroupElement",

  props: {
    empty: {
      type: Boolean,
      default: false
    },
    component: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    ...mapState({
      activeSize: state => state.constructor2.activeSize
    }),

    isRadioGroupEmpty() {
      return this.empty || this.component.children?.length <= 1
    }
  }
};
</script>

<style lang="scss" src="./index.scss"></style>
