<template>
  <draggable
    tag="div"
    :group="group"
    :list="draggableComponents"
    :draggable="draggable"
    :scroll-sensitivity="200"
    :move="onDragMove"
    @end="onDragEnd"
    class="stream-nested-draggable"
  >
    <slot/>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable'
import {mapMutations} from "vuex";
import {showToastError} from "@/services/Helpers/HelperToast";
import findInUpperNestedComponents from "@/containers/Constructor/containers/Editor/mixins/findInUpperNestedComponents";

const getDragComponents = (event) => {
  const draggedComponentEl = event.item || event.dragged
  const draggedComponent = draggedComponentEl?._underlying_vm_
    || draggedComponentEl?.__vue__?.component || null
  const desiredParentComponent = event.to?.parentElement?.parentElement?.parentElement?._underlying_vm_
    || event.to?.parentElement?.parentElement?.parentElement?.__vue__?.component
    || {
      isBody: event.to?.className?.includes('stream-editor-components__body') || false
    }
  const prevParentComponent = event.from?.parentElement?.parentElement?.parentElement?._underlying_vm_
    || event.from?.parentElement?.parentElement?.parentElement?.__vue__?.component
    || {
      isBody: event.from?.className?.includes('stream-editor-components__body') || false
    }
  return {
    draggedComponent,
    prevParentComponent,
    desiredParentComponent
  }
}

export default {
  name: "NestedDraggable",

  mixins: [findInUpperNestedComponents],

  components: {
    draggable
  },

  props: {
    children: {
      type: Array,
      default: () => []
    },
    group: {
      type: [String, Object],
      default: 'children'
    },
    draggable: {
      type: String,
      default: '.stream-editor-components-item'
    }
  },

  computed: {
    draggableComponents() {
      return this.children.map(c => ({...c, children: c.withChildren || c.possibleChildren ? [] : undefined}))
    }
  },

  methods: {
    ...mapMutations({
      reorderComponent: 'constructor2/reorderComponent'
    }),

    isDragPossible(draggedComponent, desiredParentComponent, prevParentComponent) {
      // if draggedComponent and desiredParentComponent and prevParentComponent exists
      // and if the draggedComponent doesn't have nonDraggable
      // and if there are no possibleParents or if there are, then the desiredParentComponent is one of them
      // and if the desiredParentComponent is body or withChildren
      // or there are possibleChildren, and they include the draggedComponent
      // or if it's TableColumn, then can only change its order within the same TableRow
      // same for TableRow, can only reorder it within the same Table
      // and if there are no requiredUpperElements or if there are, then they exist
      return (draggedComponent && desiredParentComponent && prevParentComponent)
        && (!draggedComponent.nonDraggable
          && (!draggedComponent.possibleParents || draggedComponent.possibleParents.includes(desiredParentComponent.name)))
        && ((desiredParentComponent.isBody || desiredParentComponent.withChildren
            || (desiredParentComponent.possibleChildren
              && desiredParentComponent.possibleChildren.includes(draggedComponent.name)))
          && (draggedComponent.name !== 'TableColumn'
            || (draggedComponent.name === 'TableColumn' && desiredParentComponent.id === prevParentComponent.id))
          && (draggedComponent.name !== 'TableRow'
            || (draggedComponent.name === 'TableRow' && desiredParentComponent.id === prevParentComponent.id))
        ) && (!draggedComponent.requiredUpperElements ||
          (draggedComponent.requiredUpperElements && draggedComponent.requiredUpperElements.every(el =>
            desiredParentComponent.componentKey === el || !!this.findInUpperNestedComponents(el, desiredParentComponent))))
    },

    onDragMove(e) {
      const {draggedContext: {element}} = e
      return element === undefined || !element.nonDraggable
    },

    onDragEnd(event) {
      const {draggedComponent, prevParentComponent, desiredParentComponent} = getDragComponents(event)
      if (this.isDragPossible(draggedComponent, desiredParentComponent, prevParentComponent)) {
        this.reorderComponent(
          {
            draggedComponent,
            prevParentComponent,
            desiredParentComponent,
            newIndex: event.newIndex,
          }
        )
      } else {
        showToastError(
          "Such drag action is not possible",
          this.$toast,
        );
      }
    }
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
