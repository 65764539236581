<template>
  <div
    class="stream-editor-components-item"
    :class="{
      'stream-editor-components-item--active': active,
      'stream-editor-components-item--exact-active': exactActive,
    }"
  >
    <Accordion
      @click-top-content="onSetActive"
      toggle-on-arrow
      :has-children="childrenComponents.length > 0"
      :expanded-by-default="expandedByDefault"
      ref="accordion"
    >
      <template #top>
        <div class="stream-editor-components-item__name">
          <span :title="componentName">{{ componentName }}</span>
          <pulsating-dot
            v-if="component.isDynamic"
            :color="component.dynamicType === 'DynamicDataProvider' ? 'red' : 'blue'"
            :direction="component.dynamicType === 'DynamicDataProvider' ? 'outward' : 'inward'"
            :title="component.dynamicType === 'DynamicDataProvider' ? 'Provider' : 'Receiver'"
          />
        </div>
        <div class="stream-editor-components-item__button stream-editor-components-item__button--hidden" v-if="componentHidden" title="Hidden">
          <svg viewBox="0 -1 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.29289 20.2929C0.902367 20.6834 0.902367 21.3166 1.29289 21.7071C1.68342 22.0976 2.31658 22.0976 2.70711 21.7071L3.7048 20.7094L3.70711 20.7071L21.7071 2.70711L21.7093 2.70487L22.7071 1.70711C23.0976 1.31658 23.0976 0.68342 22.7071 0.29289C22.3166 -0.097631 21.6834 -0.097631 21.2929 0.29289L1.29289 20.2929z" fill="#fff"/><path d="M19.5969 6.93936C19.2188 7.3419 19.2386 7.97475 19.6411 8.35288C20.4437 9.1068 21.2296 9.989 21.9978 11C18.9335 15.0325 15.6129 17 12.0011 17C11.4223 17 10.8528 16.9498 10.2921 16.8494C9.74848 16.7521 9.22889 17.114 9.13159 17.6576C9.03429 18.2013 9.39613 18.7209 9.93978 18.8182C10.6172 18.9394 11.3045 19 12.0011 19C16.3004 19 20.1751 16.7042 23.5902 12.2101C24.1337 11.4949 24.1337 10.5051 23.5902 9.7899C22.7567 8.69307 21.897 7.72797 21.0104 6.89513C20.6079 6.51701 19.975 6.53681 19.5969 6.93936z" fill="#fff"/><path d="M4.40412 15.0597C4.78228 14.6571 4.76253 14.0243 4.36002 13.6461C3.55775 12.8924 2.77226 12.0105 2.00433 11C5.0687 6.96748 8.38928 5 12.0011 5C12.5793 5 13.1482 5.0501 13.7084 5.15026C14.252 5.24746 14.7716 4.88554 14.8688 4.34187C14.966 3.79821 14.6041 3.27868 14.0604 3.18148C13.3836 3.06048 12.6969 3 12.0011 3C7.70178 3 3.82708 5.2958 0.41195 9.7899C-0.131537 10.5051 -0.131538 11.4949 0.411944 12.2101C1.24509 13.3064 2.10438 14.2712 2.9906 15.1038C3.39311 15.4819 4.02597 15.4622 4.40412 15.0597z" fill="#fff"/></svg>
        </div>
        <button
          class="stream-editor-components-item__button"
          @click.stop="onRemove"
          v-if="!component.noManualRemove"
          title="Remove"
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M8 4C7.44772 4 7 4.44772 7 5V7.5H4C3.44772 7.5 3 7.94772 3 8.5C3 9.05228 3.44772 9.5 4 9.5H5.04501L5.82486 17.2985C5.97822 18.8321 7.26872 20 8.80998 20H15.19C16.7313 20 18.0218 18.8321 18.1751 17.2985L18.955 9.5H20C20.5523 9.5 21 9.05228 21 8.5C21 7.94772 20.5523 7.5 20 7.5H17V5C17 4.44772 16.5523 4 16 4H8ZM15 7.5V6H9V7.5H15ZM7.05499 9.5H16.945L16.1851 17.0995C16.1339 17.6107 15.7038 18 15.19 18H8.80998C8.29622 18 7.86606 17.6107 7.81494 17.0995L7.05499 9.5Z" fill="#8D9CA8"></path></svg>
        </button>
      </template>
      <template v-if="canHaveChildren">
        <nested-draggable v-if="isNestedDraggable" :children="childrenComponents">
          <components-item
            v-for="item in childrenComponents"
            :key="item.id"
            :component="item"
            :expanded-by-default="innerExpandedByDefault"
            @activate="onActivate"
          />
        </nested-draggable>
        <template v-else>
          <components-item
            v-for="item in childrenComponents"
            :key="item.id"
            :component="item"
            :expanded-by-default="innerExpandedByDefault"
            @activate="onActivate"
          />
        </template>
      </template>
    </Accordion>
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import Accordion from '@/containers/Constructor/containers/Editor/components/Accordion'
import NestedDraggable from '../NestedDraggable'
import PulsatingDot from "@/containers/Constructor/containers/Editor/components/PulsatingDot";
import {DYNAMIC_DATA_COMPONENT_KEYS_OBJ} from "@/store/modules/constructor2/components/consts";
import {getSizedPropValue} from "@/containers/Constructor/containers/Editor/utils";

export default {
  name: "ComponentsItem",

  components: {
    PulsatingDot,
    Accordion,
    NestedDraggable
  },

  props: {
    component: {
      type: Object,
      default: () => {}
    },
    expandedByDefault: {
      type: Boolean,
      default: undefined
    },
    innerExpandedByDefault: {
      type: Boolean,
      default: undefined
    },
  },

  data () {
    return {
      active: false,
      exactActive: false,
    }
  },

  watch: {
    activeComponent: {
      immediate: true,
      handler() {
        if (this.activeComponent && this.activeComponent.id === this.component.id) {
          this.exactActive = true
          this.onActivate()
        } else {
          this.active = false
          this.exactActive = false
        }
      }
    }
  },

  computed: {
    ...mapState({
      activeComponent: state => state.constructor2.activeComponent,
      activeSize: state => state.constructor2.activeSize
    }),
    ...mapGetters({
      addedComponents: "constructor2/addedComponents"
    }),

    childrenComponents () {
      return this.component.children || []
    },

    canHaveChildren () {
      return this.component.withChildren || this.component.possibleChildren || !!this.component.children
    },

    isNestedDraggable () {
      return !this.component.staticChildren
    },

    componentName() {
      return `${this.availableToForms || this.component.showId ? `[${
        DYNAMIC_DATA_COMPONENT_KEYS_OBJ[this.component.name]
          ? this.component.children[0].id
          : this.component.id
      }] ` : ''}${
        DYNAMIC_DATA_COMPONENT_KEYS_OBJ[this.component.name]
          ? this.component.children[0].displayName || this.component.children[0].name
          : this.component.displayName || this.component.name
      }${
        this.component.customName
          ? ` (${this.component.customName})`
          : ''
      }`
    },

    componentHidden() {
      let hidden = false
      if (this.component.props?.hide?.value) {
        hidden = ((!!this.component.props?.hide.value[this.activeSize] || typeof this.component.props?.hide.value === 'string')
          && (this.component.props?.hide.value[this.activeSize] || this.component.props?.hide.value) || '') === 'on'
      }
      return hidden
    },

    availableToForms() {
      return this.component.props?.availableToForms ? getSizedPropValue(this.component.props.availableToForms, this.activeSize) === 'on' : false
    }
  },

  methods: {
    ...mapMutations({
      setActiveComponent: 'constructor2/setActiveComponent',
      remove: 'constructor2/removeComponent',
    }),

    onActivate () {
      this.active = true
      this.$emit('activate')
      if (!this.exactActive)
        this.setAccordionCollapse(false)
      if (!this.component.parentId) {
        this.$emit('scroll-to-active')
      }
    },

    setAccordionCollapse (value = false) {
      this.$refs.accordion.isCollapsed = value
    },

    onSetActive () {
      this.setActiveComponent({
        component: this.addedComponents.find(component => component.id === this.component.id)
      })
    },

    onRemove () {
      this.remove(this.component)
    }
  }
};
</script>

<style lang="scss" src="./index.scss"></style>
