import {mapState} from "vuex";

export default {
  props: {
    size: {
      type: String,
      default: 'desktop'
    },
    valueType: {
      type: String,
      default: 'general'
    }
  },

  data() {
    return {
      accordionExpandedByDefault: false,
    }
  },

  computed: {
    ...mapState({
      activeComponent: state => state.constructor2.activeComponent
    })
  },

  watch: {
    size() {
      this.accordionExpandedByDefault = false
    }
  },

  methods: {
    onHaveValues() {
      this.accordionExpandedByDefault = true
    }
  }
}
