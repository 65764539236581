<template>
  <div class="stream-data-sources">
    <vn-select-add
      class="stream-data-sources__sources"
      label="Data Source"
      :value="currentDataSource"
      :options="allDataSources"
      :loading="loading"
      key-by="id"
      text-by="name"
      @input="onSelectDataSource"
      no-clear
      no-remove-option
    />
    <div class="stream-data-sources__table-wrapper">
      <constructor2-data-sources-table
        with-select
        :with-paginating="false"
        :is-data-source-selected="isDataSourceSelected"
        :data="currentDataSourceList"
        :selected-data-ids="selectedDataIds"
        :selected-data-items="currentSelectedDataSourceList"
        :loading="loading"
        @select-data-source-item="toggleEpackDataSourceItemById({dataSource: currentDataSource, item: $event})"
      />
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Constructor2DataSourcesTable from '@/components/constructor2/DataSourcesTable';
import VnSelectAdd from '@/components/ui/vn-select-add.vue';

export default {
  name: "ConstructorDataSources",

  components: {
    VnSelectAdd,
    Constructor2DataSourcesTable
  },

  data () {
    return {
      localDataSource: null,
      loading: false
    }
  },

  computed: {
    ...mapGetters({
      allDataSources: 'constructor2/dataSources',
      selectedData: 'constructor2/selectedDataSources',
    }),

    isDataSourceSelected () {
      return !!this.localDataSource
    },

    currentDataSource () {
      return this.localDataSource || {}
    },

    currentDataSourceList () {
      return this.currentDataSource.items || []
    },

    currentSelectedDataSourceList () {
      return (this.currentDataSource.id
        && this.selectedData[this.currentDataSource.id]?.items
        && Object.values(this.selectedData[this.currentDataSource.id]?.items)) || []
    },

    selectedDataIds () {
      return this.currentSelectedDataSourceList.map(d => d.id)
    },
  },

  methods: {
    ...mapActions({
      toggleEpackDataSourceItemById: 'constructor2/toggleEpackDataSourceItemById',
      loadDetailedDataSource: 'constructor2/loadDetailedDataSource'
    }),

    async onSelectDataSource(dataSource) {
      // load the data source if it doesn't have detailed data
      if (!dataSource.list) {
        this.loading = true
        await this.loadDetailedDataSource(dataSource)
        this.localDataSource = this.allDataSources.find(ds => ds.id === dataSource.id)
        this.$nextTick(() => {
          this.loading = false
        })
      }
    }
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
