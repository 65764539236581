<template>
  <control-item class="stream-controls-select-size">
    <template #title>
      <span>Select size</span>
      <C2ChangeSizes />
    </template>
    <tab-select
      :items="availableSizes"
      :value="activeSize"
      @input="set({ stateProp: 'activeSize', value: $event.id })"
      thin
    >
      <template #slot-0><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M2 6C2 5.46957 2.21071 4.96086 2.58579 4.58579C2.96086 4.21071 3.46957 4 4 4H20C20.5304 4 21.0391 4.21071 21.4142 4.58579C21.7893 4.96086 22 5.46957 22 6V15C22 15.5304 21.7893 16.0391 21.4142 16.4142C21.0391 16.7893 20.5304 17 20 17H4C3.46957 17 2.96086 16.7893 2.58579 16.4142C2.21071 16.0391 2 15.5304 2 15V6Z" stroke="#8d9ca8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M8 20H16" stroke="#8d9ca8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg></template>
      <template #slot-1><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0-570198)"><path d="M19.0002 5C19.2654 5 19.5198 5.10536 19.7073 5.29289C19.8949 5.48043 20.0002 5.73478 20.0002 6V15H4.00021V6C4.00021 5.73478 4.10557 5.48043 4.29311 5.29289C4.48064 5.10536 4.735 5 5.00021 5H19.0002ZM5.00021 3C4.20456 3 3.4415 3.31607 2.87889 3.87868C2.31628 4.44129 2.00021 5.20435 2.00021 6V17H22.0002V6C22.0002 5.20435 21.6841 4.44129 21.1215 3.87868C20.5589 3.31607 19.7959 3 19.0002 3H5.00021Z" fill="#8d9ca8"></path><path d="M24 19H0C0.411836 20.1652 1.69397 21 3.00019 21H21.0002C22.3064 21 23.5882 20.1652 24 19Z" fill="#8d9ca8"></path></g><defs><clipPath id="clip0-570198"><rect width="24" height="24" fill="white"></rect></clipPath></defs></svg></template>
      <template #slot-2><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M18 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V4C20 2.89543 19.1046 2 18 2Z" stroke="#8d9ca8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M11 19H13" stroke="#8d9ca8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg></template>
      <template #slot-3><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M16.75 2H6.75C5.647 2 4.75 2.897 4.75 4V20C4.75 21.103 5.647 22 6.75 22H16.75C17.853 22 18.75 21.103 18.75 20V4C18.75 2.897 17.853 2 16.75 2ZM6.75 20V4H16.75L16.752 20H6.75Z" fill="#8d9ca8"></path><path d="M11.75 19C12.3023 19 12.75 18.5523 12.75 18C12.75 17.4477 12.3023 17 11.75 17C11.1977 17 10.75 17.4477 10.75 18C10.75 18.5523 11.1977 19 11.75 19Z" fill="#8d9ca8"></path></svg></template>
    </tab-select>
  </control-item>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {AVAILABLE_SIZES_LIST} from "@/store/modules/constructor2/consts";
import ControlItem from "../../components/ControlItem";
import TabSelect from '@/containers/Constructor/components/TabSelect';
import C2ChangeSizes from "@/components/constructor2/ChangeSizes";

export default {
  name: "SelectSize",

  components: {
    C2ChangeSizes,
    ControlItem,
    TabSelect
  },

  computed: {
    ...mapState({
      activeSizeState: state => state.constructor2.activeSize,
    }),

    activeSize () {
      return { id: this.activeSizeState }
    },

    availableSizes() {
      return AVAILABLE_SIZES_LIST.map((size) => ({ id: size.slug, title: size.name }))
    }
  },

  methods: {
    ...mapMutations({
      set: 'constructor2/set'
    })
  }
};
</script>

<style lang="scss" src="./index.scss"></style>
