<template>
  <table
    class="stream-components-table"
    @click.stop="$emit('click')"
    @mouseenter="$emit('mouseenter')"
    @mouseleave="$emit('mouseleave')"
  >
    <tr
      class="stream-components-table-row stream-base-component"
      :data-stream-row-id="row.id"
      :class="[
        `stream-component-id--${row.id}`,
        {'stream-base-component--active': isElementActive(activeComponent, row)}
      ]"
      v-for="row in rows"
      :key="`table-row-${row.id}-${row.order}`"
      @click.stop="onSelectElement(row)"
    >
      <td
        class="stream-components-table-column stream-base-component"
        :class="[
          `stream-component-id--${col.id}`,
          {'stream-base-component--active': isElementActive(activeComponent, col)}
        ]"
        v-for="col in getColumns(row)"
        :key="`table-column-${col.id}-${col.order}`"
        :style="getColumnStyle(col, activeSize)"
        :colspan="colspan(col, activeSize, vw, vh)"
        @click.stop="onSelectElement(col)"
      >
        <template v-if="col && col.children">
          <workarea-component
            v-for="innerComponent in col.children"
            :key="`${innerComponent.id}-${innerComponent.parentId}-${innerComponent.order}`"
            :component="innerComponent"
          />
        </template>
      </td>
    </tr>
    <slot />
  </table>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {computeComponentStyle, getSizedPropValue} from "@/containers/Constructor/containers/Editor/utils";

export default {
  name: "TableElement",

  components: {
    WorkareaComponent: () => import('../../../../index')
  },

  props: {
    component: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    ...mapState({
      activeComponent: state => state.constructor2.activeComponent,
      activeSize: state => state.constructor2.activeSize,
      vw: state => state.constructor2.windowWidth,
      vh: state => state.constructor2.windowHeight,
    }),

    rows () {
      return this.component?.children || []
    }
  },

  methods: {
    ...mapMutations({
      setActiveComponent: 'constructor2/setActiveComponent',
    }),

    colspan (column, activeSize) {
      return getSizedPropValue(column?.props?.colspan, activeSize) || undefined
    },

    getColumns (row) {
      return row?.children || []
    },

    getColumnStyle (col, activeSize, vw, vh) {
      return computeComponentStyle(col, activeSize, vw, vh)
    },

    isElementActive (activeComponent, element) {
      return activeComponent && element.id === activeComponent.id || false
    },

    onSelectElement (element) {
      this.setActiveComponent({ component: element })
    },
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
