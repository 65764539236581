<template>
  <Accordion block :expanded-by-default="accordionExpandedByDefault">
    <template #top>
      Slider Settings
    </template>

    <div class="stream-editor-parameter-panel__props">
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Slides per view</div>
        <ParameterInput :prop="{...activeComponent.props.slidesPerView, key: 'slidesPerView'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
      </div>
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Navigation color</div>
        <ParameterColorPick :prop="{...activeComponent.props.arrowColor, key: 'arrowColor'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
      </div>
    </div>

    <div class="stream-editor-parameter-panel__props">
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Navigation width</div>
        <ParameterInput :prop="{...activeComponent.props.arrowWidth, key: 'arrowWidth'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
      </div>
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Navigation height</div>
        <ParameterInput :prop="{...activeComponent.props.arrowHeight, key: 'arrowHeight'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
      </div>
    </div>

    <div class="stream-editor-parameter-panel__prop">
      <div class="stream-editor-parameter-panel__prop-title">Navigation svg code (left)</div>
      <ParameterTextarea :prop="{...activeComponent.props.leftArrowSvgCode, key: 'leftArrowSvgCode'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
    </div>

    <div class="stream-editor-parameter-panel__props">
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Pagination color</div>
        <ParameterColorPick :prop="{...activeComponent.props.bulletColor, key: 'bulletColor'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
      </div>
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title" title="Pagination active color">Pagination active color</div>
        <ParameterColorPick :prop="{...activeComponent.props.activeBulletColor, key: 'activeBulletColor'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
      </div>
    </div>

    <div class="stream-editor-parameter-panel__prop">
      <parameters-panel-parameter-checkbox :prop="{...activeComponent.props.showBullets, key: 'showBullets'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
    </div>
  </Accordion>
</template>

<script>
import Accordion from '@/containers/Constructor/containers/Editor/components/Accordion'

import ParameterInput from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Input";
import ParameterColorPick
  from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/ColorPick";
import ParameterTextarea
  from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Textarea";
import ParametersPanelParameterCheckbox from "@/modules/constructor2/editor/parameters-panel/atoms/parameter-checkbox";
import settings from "@/modules/constructor2/editor/parameters-panel/mixins/settings";

export default {
  name: "ParametersPanelSettingsSlider",

  mixins: [settings],

  components: {
    ParametersPanelParameterCheckbox,
    ParameterTextarea,
    ParameterColorPick,
    ParameterInput,
    Accordion
  },
}
</script>
