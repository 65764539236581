<template>
  <Accordion block :expanded-by-default="accordionExpandedByDefault">
    <template #top>
      Extra
    </template>

    <div
      class="stream-editor-parameter-panel__prop"
      v-if="activeComponent.props.availableToForms"
      style="margin-bottom: 12px;"
    >
      <parameters-panel-parameter-checkbox :prop="{...activeComponent.props.availableToForms, key: 'availableToForms'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
      <span v-if="isAvailableToForms" :style="{ marginTop: '4px', fontSize: '10px' }">
        This component is available as "[{{ activeComponent.id }}] {{ activeComponent.displayName || activeComponent.name }}"
      </span>
    </div>

    <div
      class="stream-editor-parameter-panel__prop"
      v-if="activeComponent.props.cssStop"
      style="margin-bottom: 12px;"
    >
      <parameters-panel-parameter-checkbox :prop="{...activeComponent.props.cssStop, key: 'cssStop'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
    </div>

    <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.classNames">
      <div class="stream-editor-parameter-panel__prop-title">Class names</div>
      <ParameterInput :prop="{...activeComponent.props.classNames, key: 'classNames'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
    </div>

    <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.attributes">
      <div class="stream-editor-parameter-panel__prop-title">Data attributes</div>
      <ParameterAttributes :prop="{...activeComponent.props.attributes, key: 'attributes'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
    </div>
  </Accordion>
</template>

<script>
import Accordion from '@/containers/Constructor/containers/Editor/components/Accordion'
import ParameterAttributes
  from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Attributes";
import settings from "@/modules/constructor2/editor/parameters-panel/mixins/settings";
import ParametersPanelParameterCheckbox from "@/modules/constructor2/editor/parameters-panel/atoms/parameter-checkbox";
import {AVAILABLE_SIZES_LIST} from "@/store/modules/constructor2/consts";
import ParameterInput from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Input";

export default {
  name: "ParametersPanelParametersExtra",

  mixins: [settings],

  components: {
    ParametersPanelParameterCheckbox,
    ParameterAttributes,
    ParameterInput,
    Accordion,
  },

  computed: {
    isAvailableToForms() {
      return (
        this.activeComponent?.props?.availableToForms?.value &&
        AVAILABLE_SIZES_LIST.some((size) => this.activeComponent.props.availableToForms.value[size.slug] === 'on')
      )
    }
  }
}
</script>
