<template>
  <va-checkbox class="c2-parameter-checkbox" :label="prop.name" :value="boolValue" @input="toggleValue" />
</template>

<script>
import parameterItem from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/mixins/parameterItem";

export default {
  name: "ParametersPanelParameterCheckbox",

  mixins: [ parameterItem ],

  props: {
    trueValue: {
      type: String,
      default: 'on'
    },
    falseValue: {
      type: String,
      default: 'off'
    }
  },

  computed: {
    boolValue() {
      return this.value === this.trueValue || (!this.value && this.placeholderValue === this.trueValue)
    }
  },

  methods: {
    toggleValue() {
      this.updateProp(this.boolValue ? this.falseValue : this.trueValue)
    },
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
