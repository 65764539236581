var render = function render(){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"stream-components-table",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('click')},"mouseenter":function($event){return _vm.$emit('mouseenter')},"mouseleave":function($event){return _vm.$emit('mouseleave')}}},[_vm._l((_vm.rows),function(row){return _c('tr',{key:`table-row-${row.id}-${row.order}`,staticClass:"stream-components-table-row stream-base-component",class:[
      `stream-component-id--${row.id}`,
      {'stream-base-component--active': _vm.isElementActive(_vm.activeComponent, row)}
    ],attrs:{"data-stream-row-id":row.id},on:{"click":function($event){$event.stopPropagation();return _vm.onSelectElement(row)}}},_vm._l((_vm.getColumns(row)),function(col){return _c('td',{key:`table-column-${col.id}-${col.order}`,staticClass:"stream-components-table-column stream-base-component",class:[
        `stream-component-id--${col.id}`,
        {'stream-base-component--active': _vm.isElementActive(_vm.activeComponent, col)}
      ],style:(_vm.getColumnStyle(col, _vm.activeSize)),attrs:{"colspan":_vm.colspan(col, _vm.activeSize, _vm.vw, _vm.vh)},on:{"click":function($event){$event.stopPropagation();return _vm.onSelectElement(col)}}},[(col && col.children)?_vm._l((col.children),function(innerComponent){return _c('workarea-component',{key:`${innerComponent.id}-${innerComponent.parentId}-${innerComponent.order}`,attrs:{"component":innerComponent}})}):_vm._e()],2)}),0)}),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }