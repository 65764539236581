<template>
  <parameters-panel-settings-the360-viewer :size="size" :value-type="valueType" />
</template>

<script>
import ParametersPanelSettingsThe360Viewer
  from "@/modules/constructor2/editor/parameters-panel/molecules/settings-the-360-viewer";

export default {
  name: "ParametersPanelParametersThe360Viewer",

  props: {
    size: {
      type: String,
      default: 'desktop'
    },
    valueType: {
      type: String,
      default: 'general'
    }
  },

  components: {
    ParametersPanelSettingsThe360Viewer,
  }
}
</script>
