<template>
  <div class="parameters-panel-color-pick">
    <input
      class="parameters-panel-color-pick__picker"
      type="color"
      :value="value || placeholder"
      @change="onInput($event.target.value)"
    >
    <div
      v-if="value === '' || value === 'transparent' || (!value && !placeholder)"
      class="parameters-panel-color-pick__picker parameters-panel-color-pick__picker--transparent"
    />
    <input
      class="parameters-panel-color-pick__input"
      type="text"
      :value="value"
      :placeholder="placeholder"
      v-debounce:500ms="onInput"
    >
  </div>
</template>

<script>
export default {
  name: "ParametersPanelColorPick",

  props: {
    value: String,
    placeholder: String
  },

  methods: {
    onInput (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
