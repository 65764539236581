<template>
  <div class="stream">
    <constructor-header />
    <constructor-controls />

    <constructor-epack-data v-if="activeTab === 'epackData'" />
    <constructor-editor v-show="activeTab === 'constructor'" :initialized="initialized" />
    <constructor-data-sources v-if="activeTab === 'dataSources'" />

    <constructor-save-component-modal />
    <constructor-dynamic-configurator-modal />
    <constructor-fonts />

    <va-inner-loading :loading="loading" class="stream-loading" :class="{'stream-loading--disabled': !loading}" />
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex";
import ConstructorHeader from './containers/Header'
import ConstructorControls from './containers/Controls'
import ConstructorEpackData from './containers/EpackData'
import ConstructorDataSources from './containers/DataSources'
import ConstructorEditor from './containers/Editor'
import ConstructorSaveComponentModal from './containers/SaveComponentModal'
import ConstructorDynamicConfiguratorModal from './containers/DynamicConfiguratorModal'
import ConstructorFonts from './containers/Fonts'
import {bus} from "@/app/main";

export default {
  name: "Constructor",

  components: {
    ConstructorHeader,
    ConstructorControls,
    ConstructorEpackData,
    ConstructorDataSources,
    ConstructorEditor,
    ConstructorSaveComponentModal,
    ConstructorDynamicConfiguratorModal,
    ConstructorFonts,
  },

  data () {
    return {
      initialized: false
    }
  },

  async mounted () {
    this.set({ stateProp: 'loading', value: true })

    // turn off page scroll
    document.body.style.overflow = 'hidden'
    await this.getEpackage({id: this.$route.params.id === 'untitled' ? undefined : this.$route.params.id, setIntoCurrent: true})

    // if tried to get the epackage by id but didn't receive it, route back to the constructor epackages list page
    if (this.$route.params.id !== 'untitled' && !this.activeEpackage) {
      this.$router.push('/panel/builder-pro')?.catch(() => {})
      return
    }

    await this.loadDataSources()
    await this.loadFonts().catch(() => {})

    bus.$emit('change-sizes-sync')

    // load corresponding components when either activeLocale or activeTemplate or activePage changes (lazy pages)
    this.$watch(vm => [
      vm.activeLocale,
      vm.activeTemplate,
      vm.activePage
    ], () => {
      this.onChangePage()
    })

    if (this.$route.params.id !== 'untitled') {
      this.onChangePage().then()
    } else {
      this.set({ stateProp: 'loading', value: false })
    }

    this.onResize()
    window.addEventListener('resize', this.onResize)
  },

  watch: {
    activeSize() {
      this.onResize()
    }
  },

  computed: {
    ...mapState({
      activeTab: state => state.constructor2.activeTab,
      activeEpackage: state => state.constructor2.activeEpackage,
      activePage: state => state.constructor2.activePage,
      activeTemplate: state => state.constructor2.activeTemplate,
      activeLocale: state => state.constructor2.activeLocale,
      activeSize: state => state.constructor2.activeSize,
      loading: state => state.constructor2.loading
    })
  },

  methods: {
    ...mapActions({
      getEpackage: 'constructor2/getEpackage',
      loadFonts: 'constructor2/loadFonts',
      loadDataSources: 'constructor2/loadDataSources',
      loadComponents: 'constructor2/loadComponents',
    }),
    ...mapMutations({
      reset: 'constructor2/reset',
      set: 'constructor2/set',
      updateWindowSize: 'constructor2/updateWindowSize'
    }),

    onResize () {
      setTimeout(() => {
        this.updateWindowSize()
      }, 1000)
    },

    async onChangePage () {
      this.initialized = false
      await this.loadComponents({
        epackId: this.$route.params.id,
        lang: this.activeLocale,
        template: this.activeTemplate,
        page: this.activePage
      })
      this.$nextTick(() => {
        setTimeout(() => {
          this.initialized = true
          this.set({ stateProp: 'loading', value: false })
        }, 500)
      })
    }
  },

  beforeDestroy() {
    // turn on page scroll
    document.body.style.overflow = ''

    window.removeEventListener('resize', this.onResize)

    // reset the constructor2 vuex store to its default values
    this.reset()
  }
};
</script>

<style lang="scss" src="./index.scss"></style>
