<template>
  <Accordion block :expanded-by-default="accordionExpandedByDefault">
    <template #top>
      Decoration
    </template>

    <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.cursor">
      <div class="stream-editor-parameter-panel__prop-title">Cursor</div>
      <ParameterSelect :prop="{...activeComponent.props.cursor, key: 'cursor'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
    </div>

    <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.background">
      <div class="stream-editor-parameter-panel__prop-title">Background</div>
      <ParameterInput :prop="{...activeComponent.props.background, key: 'background'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
    </div>

    <div class="stream-editor-parameter-panel__props">
      <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.backgroundSize">
        <div class="stream-editor-parameter-panel__prop-title">Background size</div>
        <ParameterInput :prop="{...activeComponent.props.backgroundSize, key: 'backgroundSize'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
      </div>
      <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.backgroundColor">
        <div class="stream-editor-parameter-panel__prop-title">Background color</div>
        <ParameterColorPick :prop="{...activeComponent.props.backgroundColor, key: 'backgroundColor'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
      </div>
    </div>

    <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.backgroundPosition">
      <div class="stream-editor-parameter-panel__prop-title">Background position</div>
      <ParameterInput :prop="{...activeComponent.props.backgroundPosition, key: 'backgroundPosition'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
    </div>

    <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.backgroundImage">
      <div class="stream-editor-parameter-panel__prop-title">Background image</div>
      <parameter-file :prop="{...activeComponent.props.backgroundImage, key: 'backgroundImage'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
    </div>

    <div class="stream-editor-parameter-panel__prop" :style="{ marginBottom: '16px' }" v-if="activeComponent.props.backgroundRepeat">
      <parameters-panel-parameter-checkbox
        :prop="{...activeComponent.props.backgroundRepeat, key: 'backgroundRepeat'}"
        :size="size"
        :active-size="size"
        :value-type="valueType"
        @has-value="onHaveValues"
        true-value="repeat"
        false-value="no-repeat"
      />
    </div>

    <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.border">
      <div class="stream-editor-parameter-panel__prop-title">Border</div>
      <parameter-border :prop="{...activeComponent.props.border, key: 'border'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
    </div>

    <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.borderRadius">
      <div class="stream-editor-parameter-panel__prop-title">Border radius</div>
      <ParameterInput :prop="{...activeComponent.props.borderRadius, key: 'borderRadius'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
    </div>

    <div class="stream-editor-parameter-panel__props" v-if="activeComponent.props.borderTop || activeComponent.props.borderRight">
      <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.borderTop">
        <div class="stream-editor-parameter-panel__prop-title">Border top</div>
        <ParameterInput :prop="{...activeComponent.props.borderTop, key: 'borderTop'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
      </div>
      <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.borderRight">
        <div class="stream-editor-parameter-panel__prop-title">Border right</div>
        <ParameterInput :prop="{...activeComponent.props.borderRight, key: 'borderRight'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
      </div>
    </div>

    <div class="stream-editor-parameter-panel__props" v-if="activeComponent.props.borderBottom || activeComponent.props.borderLeft">
      <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.borderBottom">
        <div class="stream-editor-parameter-panel__prop-title">Border bottom</div>
        <ParameterInput :prop="{...activeComponent.props.borderBottom, key: 'borderBottom'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
      </div>
      <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.borderLeft">
        <div class="stream-editor-parameter-panel__prop-title">Border left</div>
        <ParameterInput :prop="{...activeComponent.props.borderLeft, key: 'borderLeft'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
      </div>
    </div>

    <div class="stream-editor-parameter-panel__prop" v-if="activeComponent.props.transition">
      <div class="stream-editor-parameter-panel__prop-title">Transition</div>
      <ParameterInput :prop="{...activeComponent.props.transition, key: 'transition'}" :size="size" :active-size="size" :value-type="valueType" @has-value="onHaveValues" />
    </div>
  </Accordion>
</template>

<script>
import Accordion from '@/containers/Constructor/containers/Editor/components/Accordion'
import ParameterInput from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Input";
import ParameterColorPick
  from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/ColorPick";
import ParametersPanelParameterCheckbox from "@/modules/constructor2/editor/parameters-panel/atoms/parameter-checkbox";
import ParameterFile from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/File";
import ParameterBorder from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Border";
import ParameterSelect from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Select";
import settings from "@/modules/constructor2/editor/parameters-panel/mixins/settings";

export default {
  name: "ParametersPanelParametersDecoration",

  mixins: [settings],

  components: {
    ParameterBorder,
    ParameterFile,
    Accordion,
    ParameterInput,
    ParameterColorPick,
    ParameterSelect,
    ParametersPanelParameterCheckbox,
  },
}
</script>
