import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      addedComponents: 'constructor2/addedComponents'
    })
  },

  methods: {
    hasPropValueInChildren (propKey, providedComponent, deepness = 1) {
      deepness--
      if (deepness >= 0) {
        const children = this.addedComponents.filter(c => c.parentId === providedComponent.id)
        for (let i = 0; i < children.length; i++) {
          if (children[i].props?.[propKey]?.value && Object.values(children[i].props[propKey].value).some(propValue => {
            return propValue.length > 0;
          }))
            return true
          else this.hasPropValueInChildren(propKey, children[i], deepness)
        }
      }
      return false
    }
  }
}
