<template>
  <Accordion block expanded-by-default>
    <template #top>
      Script Settings
    </template>

    <div class="stream-editor-parameter-panel__prop">
      <div class="stream-editor-parameter-panel__prop-title">Code (HTML)</div>
      <ParameterCodeEditor :prop="{...activeComponent.props.code, key: 'code'}" :size="size" :active-size="size" :value-type="valueType" />
    </div>

    <div class="stream-editor-parameter-panel__prop">
      <ParametersPanelParameterCheckbox :prop="{...activeComponent.props.auto, key: 'auto'}" :size="size" :active-size="size" :value-type="valueType" />
    </div>
  </Accordion>
</template>

<script>
import Accordion from '@/containers/Constructor/containers/Editor/components/Accordion'
import ParameterCodeEditor
  from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/CodeEditor";
import ParametersPanelParameterCheckbox from "@/modules/constructor2/editor/parameters-panel/atoms/parameter-checkbox";
import settings from "@/modules/constructor2/editor/parameters-panel/mixins/settings";

export default {
  name: "ParametersPanelSettingsScript",

  mixins: [settings],

  components: {
    ParametersPanelParameterCheckbox,
    ParameterCodeEditor,
    Accordion,
  },
}
</script>
