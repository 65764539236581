<template>
  <Accordion block expanded-by-default>
    <template #top>
      Form Checkbox Settings
    </template>

    <div class="stream-editor-parameter-panel__prop">
      <div class="stream-editor-parameter-panel__prop-title">Value</div>
      <ParameterInput :prop="{...activeComponent.props.value, key: 'value'}" :size="size" :active-size="size"
                      :value-type="valueType"/>
    </div>

    <div class="stream-editor-parameter-panel__props">
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Active color</div>
        <ParameterColorPick :prop="{...activeComponent.props.activeColor, key: 'activeColor'}" :size="size" :active-size="size" :value-type="valueType" />
      </div>
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Inactive color</div>
        <ParameterColorPick :prop="{...activeComponent.props.inactiveColor, key: 'inactiveColor'}" :size="size" :active-size="size" :value-type="valueType" />
      </div>
    </div>

    <div class="stream-editor-parameter-panel__prop">
      <div class="stream-editor-parameter-panel__prop-title">Radio border</div>
      <parameter-border :prop="{...activeComponent.props.radioBorder, key: 'radioBorder'}" :size="size" :active-size="size" :value-type="valueType" />
    </div>

    <div class="stream-editor-parameter-panel__props">
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Radio width</div>
        <ParameterInput :prop="{...activeComponent.props.radioWidth, key: 'radioWidth'}" :size="size" :active-size="size" :value-type="valueType" />
      </div>
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Radio height</div>
        <ParameterInput :prop="{...activeComponent.props.radioHeight, key: 'radioHeight'}" :size="size" :active-size="size" :value-type="valueType" />
      </div>
    </div>

    <div class="stream-editor-parameter-panel__props">
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Dot width</div>
        <ParameterInput :prop="{...activeComponent.props.dotWidth, key: 'dotWidth'}" :size="size" :active-size="size" :value-type="valueType" />
      </div>
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Dot height</div>
        <ParameterInput :prop="{...activeComponent.props.dotHeight, key: 'dotHeight'}" :size="size" :active-size="size" :value-type="valueType" />
      </div>
    </div>

    <div class="stream-editor-parameter-panel__prop">
      <div class="stream-editor-parameter-panel__prop-title">Dot color</div>
      <ParameterColorPick :prop="{...activeComponent.props.dotColor, key: 'dotColor'}" :size="size" :active-size="size" :value-type="valueType" />
    </div>
  </Accordion>
</template>

<script>
import Accordion from '@/containers/Constructor/containers/Editor/components/Accordion'
import settings from "@/modules/constructor2/editor/parameters-panel/mixins/settings";
import ParameterInput from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Input";
import ParameterColorPick
  from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/ColorPick";
import ParameterBorder from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Border";

export default {
  name: "ParametersPanelSettingsFormRadioButton",

  mixins: [settings],

  components: {
    Accordion,
    ParameterInput,
    ParameterColorPick,
    ParameterBorder,
  },
}
</script>
