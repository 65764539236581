<template>
  <div class="draggable-table">
    <div class="draggable-table__header" v-if="withHeader">
      <Input
        v-if="withSearch"
        class="draggable-table__header-input draggable-table__header-input--search"
        :value="searchValue"
        @debounced-input="searchValue = $event"
        placeholder="Search"
      />
      <va-checkbox
        v-if="withSelect && withSelectedOnly"
        v-model="showSelectedOnly"
        class="draggable-table__header-checkbox"
      >
        <template #label>
          <span>Show selected only</span>
          <v-dropdown
            v-if="draggable && dragWithSelectedOnly"
            auto-hide
            :triggers="['hover']"
            placement="top"
            :distance="8"
            class="draggable-table__tooltip"
          >
            <button>
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'">

  <defs>
    <linearGradient id="question-circled-gradient" x2="0.35" y2="1">
      <stop offset="0%" stop-color="#2d4de0" />
      <stop offset="40%" stop-color="#9f71f0" />
      <stop offset="90%" stop-color="#fc6277" />
    </linearGradient>
    <linearGradient id="question-circled-gradient-green" x2="0.75" y2="1">
      <stop offset="0%" stop-color="#5166d7" />
      <stop offset="100%" stop-color="#43f59f" />
    </linearGradient>
  </defs>

  <path d="M256,0C114.62,0,0,114.62,0,256s114.62,256,256,256s256-114.62,256-256S397.38,0,256,0z M256,486.4
    C128.956,486.4,25.6,383.044,25.6,256S128.956,25.6,256,25.6S486.4,128.956,486.4,256S383.044,486.4,256,486.4z" fill="url(#question-circled-gradient)"/>

  <path d="M319.258,119.578c-13.858-11.981-31.718-17.98-53.581-17.98h-4.403c-22.673,0-42.001,7.074-58.001,21.197
    c-17.604,15.753-26.402,36.804-26.402,63.198c0,4.275,1.203,7.603,3.601,10.001c2.398,2.15,5.325,3.072,8.798,2.799
    c3.2,0,5.999-1.203,8.397-3.601c2.662-2.654,4.002-5.854,4.002-9.6c0-14.925,3.465-27.853,10.402-38.801
    c10.129-15.454,26.522-23.202,49.203-23.202c19.2,0,33.724,5.197,43.597,15.599c8.26,8.55,12.527,19.601,12.8,33.203
    c0,11.998-2.534,22.673-7.603,32c-5.871,10.675-16.401,22.4-31.599,35.2c-12.8,10.402-21.734,21.999-26.803,34.799
    c-5.077,12.271-7.603,27.878-7.603,46.797c0,3.746,1.203,6.801,3.601,9.199c2.398,2.15,5.325,3.2,8.798,3.2
    c3.2,0,5.999-1.05,8.397-3.2c2.662-2.398,4.002-5.453,4.002-9.199c0-18.654,2.261-33.05,6.801-43.204
    c4.523-9.6,13.329-19.729,26.402-30.404c14.404-12.254,24.798-24.124,31.198-35.601c6.127-11.204,9.199-23.723,9.199-37.598
    C342.46,150.929,334.72,132.651,319.258,119.578z" fill="url(#question-circled-gradient)" />

  <circle cx="256" cy="384" r="25.6" fill="url(#question-circled-gradient)"/>
</svg>
            </button>

            <template #popper>
              <div class="draggable-table__tooltip-content">
                You can drag table rows and change the order of the selected items only when "Show selected only" is active
              </div>
            </template>
          </v-dropdown>
        </template>
      </va-checkbox>
      <va-checkbox
        v-if="withParseMedia"
        v-model="parsingMedia"
        class="draggable-table__header-checkbox"
      >
        <template #label>
          Parse media
        </template>
      </va-checkbox>
      <va-checkbox
        v-if="withPaginating && paginationActive"
        v-model="paginating"
        class="draggable-table__header-checkbox"
      >
        <template #label>
          Paginate
        </template>
      </va-checkbox>
      <slot name="header-post-content" />
    </div>
    <va-inner-loading :loading="loading" class="draggable-table__loading draggable-table__table-wrapper">
      <table class="draggable-table__table" :class="{ 'draggable-table__table--no-data': !withData }">
        <thead>
          <tr>
            <th
              v-for="column in formattedTableColumns"
              :key="`table-head-${column[columnKey]}`"
              :data-key="`table-head-${column[columnKey]}`"
              :style="{
                width: column.width,
                minWidth: column.minWidth,
                maxWidth: column.maxWidth,
              }"
            >
              {{ column.title }}
            </th>
          </tr>
        </thead>
        <component
          :is="draggable && (!dragWithSelectedOnly || (dragWithSelectedOnly && showSelectedOnly))? 'draggable' : 'tbody'"
          :value="parsedTableData"
          @input="onReorder"
          tag="tbody"
        >
          <tr v-if="!withData">
            <td colspan="99999">
              <div><span>{{ noDataText }}</span></div>
            </td>
          </tr>
          <tr v-else v-for="item in parsedTableData" :key="`table-row-${item[rowKey]}`">
            <td
              v-for="column in formattedTableColumns"
              :key="`table-cell-${item[rowKey]}-${column[columnKey]}`"
              :class="{'draggable-table__table-cell--drag-handle': column[columnKey] === '__dragHandle'}"
            >
              <va-checkbox
                v-if="column[columnKey] === '__isSelected'"
                :value="showSelectedOnly || item.__isSelected || false"
                @input="onToggleSelected(item)"
              />
              <div v-else-if="column[columnKey] === '__dragHandle'">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="45.363px" height="45.363px" viewBox="0 0 45.363 45.363" style="enable-background:new 0 0 45.363 45.363;"
	 xml:space="preserve" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'">
		<path d="M1.788,16.945c0.388,0.385,0.913,0.601,1.459,0.601l27.493-0.035v3.831c0.003,0.836,0.556,1.586,1.329,1.904
			c0.771,0.314,1.658,0.135,2.246-0.459l9.091-9.18c1.062-1.071,1.06-2.801-0.009-3.868l-9.137-9.134
			c-0.59-0.591-1.479-0.768-2.25-0.446c-0.77,0.319-1.271,1.074-1.27,1.908L30.74,5.9L3.219,5.937
			C2.08,5.94,1.161,6.864,1.163,8.004l0.018,7.483C1.182,16.034,1.401,16.56,1.788,16.945z"/>
		<path d="M42.146,27.901l-27.522-0.035l-0.001-3.834c0.002-0.835-0.5-1.587-1.27-1.907c-0.771-0.321-1.66-0.146-2.25,0.445
			l-9.136,9.135c-1.067,1.064-1.071,2.796-0.009,3.866l9.09,9.181c0.588,0.596,1.475,0.772,2.247,0.458
			c0.772-0.316,1.326-1.066,1.329-1.904v-3.83l27.493,0.035c0.547,0,1.072-0.216,1.459-0.602s0.605-0.91,0.607-1.456L44.2,29.97
			C44.203,28.83,43.284,27.903,42.146,27.901z"/>
</svg>
              </div>
              <template v-else-if="
                typeof item[column[columnKey]] === 'object'
                  && (item[column[columnKey]].__isLink || item[column[columnKey]].__isImage)
              ">
                <a
                  v-if="item[column[columnKey]].__isLink"
                  :href="item[column[columnKey]].url"
                  :title="item[column[columnKey]].url"
                  target="_blank"
                  rel="noreferrer"
                >
                  {{ item[column[columnKey]].url }}
                </a>
                <div v-else-if="item[column[columnKey]].__isImage" class="draggable-table__table-image-wrapper-outer">
                  <div class="draggable-table__table-image-wrapper">
                    <div class="draggable-table__table-image-actions">
                      <a
                        :href="item[column[columnKey]].url"
                        target="_blank"
                        rel="noreferrer"
                        class="draggable-table__table-image-actions-item draggable-table__table-image-actions-item--open-link"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
                      </a>
                      <button
                        class="draggable-table__table-image-actions-item"
                        @click="onCopyText(item[column[columnKey]].url, 'Copied the media link to the clipboard')"
                      >
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 283.842 283.842" style="enable-background:new 0 0 283.842 283.842;" xml:space="preserve" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'">
	<path d="M265.117,22.764l-9.877-8.737c-23.003-20.398-58.227-18.283-78.618,4.726l-28.267,31.89
		c-6.38,7.199-5.717,18.251,1.479,24.637l2.653,2.354c7.221,6.402,18.239,5.741,24.646-1.481l28.265-31.889
		c6.305-7.107,17.227-7.761,24.338-1.466l9.865,8.752c7.113,6.303,7.783,17.223,1.469,24.334l-61.808,69.726
		c-5.231,5.911-13.791,7.505-20.816,3.875c-7.682-3.967-17.051-2.224-22.787,4.245l-0.482,0.544
		c-3.881,4.377-5.499,10.188-4.439,15.943c1.061,5.752,4.642,10.604,9.825,13.313c8.197,4.284,17.049,6.358,25.814,6.358
		c15.532,0,30.795-6.512,41.67-18.775l61.804-69.718C290.219,78.417,288.099,43.148,265.117,22.764z"/>
	<path d="M133.998,208.581l-2.659-2.356c-7.204-6.383-18.259-5.712-24.64,1.489l-28.254,31.886
		c-6.308,7.105-17.222,7.764-24.327,1.473l-9.879-8.764c-7.115-6.301-7.783-17.212-1.467-24.325l61.806-69.721
		c5.124-5.787,13.555-7.442,20.504-4.028c7.986,3.924,17.683,2.016,23.595-4.656l0.222-0.25c3.798-4.288,5.396-9.979,4.386-15.614
		c-1.01-5.636-4.484-10.417-9.533-13.119c-22.828-12.22-50.769-7.22-67.947,12.165l-61.81,69.707v0.001
		c-20.371,22.978-18.252,58.246,4.726,78.622l9.877,8.749c10.583,9.383,23.77,13.992,36.913,13.992
		c15.394,0,30.724-6.327,41.718-18.724l28.258-31.886C141.88,226.003,141.224,214.987,133.998,208.581z"/>
</svg>
                      </button>
                    </div>
                    <img
                      class="draggable-table__table-image"
                      :src="item[column[columnKey]].url"
                      :alt="item[column[columnKey]].url"
                    >
                  </div>
                </div>
              </template>
              <span v-else :title="item[column[columnKey]]">{{ item[column[columnKey]] }}</span>
            </td>
          </tr>
        </component>
      </table>
    </va-inner-loading>
    <div class="draggable-table__pagination-wrapper" v-if="paginationActive && paginating">
      <va-pagination
        class="draggable-table__pagination"
        :value="withPagination.page"
        :pages="totalPages"
        :visible-pages="visiblePages"
        :boundary-links="totalPages > visiblePages"
        @input="onInputPage"
      />
    </div>
  </div>
</template>

<script>
import Input from '../../containers/Constructor/containers/Editor/containers/ParametersPanel/components/Input'
import Draggable from "vuedraggable";
import dcopy from "deep-copy";
import {showToastError, showToastSuccess} from "@/services/Helpers/HelperToast";

const IMAGE_TYPES = ['webp', 'jpg', 'jpeg', 'png', 'gif']

export default {
  name: "DraggableTable",

  components: {
    Input,
    Draggable,
  },

  props: {
    withSearch: {
      type: Boolean,
      default: false
    },
    showSelectedOnlyByDefault: {
      type: Boolean,
      default: false
    },
    withSelect: {
      type: Boolean,
      default: false
    },
    withSelectedOnly: {
      type: Boolean,
      default: false
    },
    dragWithSelectedOnly: {
      type: Boolean,
      default: false
    },
    withPagination: {
      type: [Object], // { page, perPage }
      default: undefined
    },
    withPaginating: {
      type: Boolean,
      default: false
    },
    withParseMedia: {
      type: Boolean,
      default: false
    },
    parseLinks: {
      type: Boolean,
      default: true
    },
    parseMedia: {
      type: Boolean,
      default: true
    },
    noDataText: {
      type: String,
      default: 'No data'
    },
    rowKey: {
      type: String,
      default: 'id'
    },
    columnKey: {
      type: String,
      default: 'key'
    },
    draggable: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    tableColumns: {
      type: Array,
      required: true
    },
    tableData: {
      type: Array,
      required: true
    },
    selectedIds: {
      type: Array,
      default: undefined
    }
  },

  data() {
    return {
      visiblePages: 4,
      searchValue: '',
      showSelectedOnly: this.showSelectedOnlyByDefault,
      parsingMedia: this.parseMedia !== false,
      paginating: this.withPagination !== undefined
    }
  },

  computed: {
    withHeader() {
      return (
        this.withSearch
          || this.withSelectedOnly
      )
    },

    formattedTableColumns() {
      const tableColumns = this.tableColumns.slice()

      if (tableColumns.length) {
        if (this.withSelect) {
          tableColumns.unshift({
            [this.columnKey]: '__isSelected',
            title: 'SELECTED',
            width: '80px'
          })
        }
        if (this.draggable && (!this.dragWithSelectedOnly || (this.dragWithSelectedOnly && this.showSelectedOnly))) {
          tableColumns.unshift({
            [this.columnKey]: '__dragHandle',
            title: '',
            width: '40px',
            minWidth: '40px'
          })
        }
      }

      return tableColumns
    },

    columnKeys() {
      return this.tableColumns?.map(item => item[this.columnKey]) || []
    },

    filteredTableData() {
      let tableData = this.tableData.slice()
      if (tableData.length && this.columnKeys.length) {
        // if showSelectedOnly, filtering out not selected items
        if (this.showSelectedOnly) {
          if (!this.selectedIds) {
            console.warn('WARNING: selectedIds were not provided in draggable-table (filteredTableData)')
            return []
          }
          tableData = tableData
            .filter(item => this.selectedIds.findIndex(id => id === item[this.rowKey]) !== -1)
        }
        // filtering by searchValue
        const trimmedSearchValue = this.searchValue.trim().toLowerCase()
        if (trimmedSearchValue) {
          tableData = tableData
            .filter(item => this.columnKeys.some(key => typeof item[key] === 'string'
              && item[key].toLowerCase().includes(trimmedSearchValue)))
        }

        // sorting the array according to the order of the selectedIds array
        if (this.showSelectedOnly) {
          const sortedTableData = []
          this.selectedIds.forEach(id => {
            sortedTableData.push(...tableData.splice(tableData.findIndex(item => item.id === id), 1))
          })
          tableData = sortedTableData
        }
      }
      return tableData
    },

    withData() {
      return this.filteredTableData.length > 0
    },

    paginatedTableData() {
      if (this.withPagination && this.paginating) {
        return this.filteredTableData.slice(
          (this.withPagination.page - 1) * this.withPagination.perPage,
          this.withPagination.page * this.withPagination.perPage
        )
      }
      return this.filteredTableData
    },

    parsedTableData() {
      // copying table items to avoid mutating the original table items
      let tableData = this.paginatedTableData.map(item => dcopy(item))
      // marking items as selected from selectedIds array
      if (!this.showSelectedOnly && this.selectedIds) {
        tableData = tableData
          .map(item => {
            item.__isSelected = this.selectedIds.findIndex(id => id === item[this.rowKey]) !== -1
            return item
          })
      }
      // parsing links and media
      if (this.columnKeys.length && (this.parseLinks || this.parseMedia)) {
        tableData = tableData
          .map(item => {
            this.columnKeys.forEach(key => {
              if (typeof item[key] === 'string') {
                if (this.parseMedia && this.parsingMedia
                  && IMAGE_TYPES.some(type => item[key].endsWith(`.${type}`))) {
                  item[key] = {
                    __isImage: true,
                    url: item[key]
                  }
                } else if (this.parseLinks
                  && (item[key].startsWith('http://') || item[key].startsWith('https://'))) {
                  item[key] = {
                    __isLink: true,
                    url: item[key]
                  }
                }
              }
            })
            return item
          })
      }
      return tableData
    },

    totalPages() {
      return (this.withPagination
        && Math.ceil(this.filteredTableData.length / (this.withPagination.perPage || 1))) || 0
    },

    paginationActive() {
      return this.withPagination && this.totalPages > 1
    }
  },

  mounted() {
    this.$watch(vm => [
      vm.searchValue,
      vm.showSelectedOnly,
      vm.paginating
    ], () => {
      if (this.withPagination && this.withPagination.page !== 1) {
        this.onInputPage(1)
      }
    })
  },

  methods: {
    onCopyText(text, onSuccessText = 'Copied to the clipboard', onErrorText = 'Couldn\'t copy to the clipboard') {
      this.$copyText(text)
        .then(() => {
          showToastSuccess(onSuccessText, this.$toast)
        })
        .catch(() => {
          showToastError(onErrorText, this.$toast);
        })
    },

    onInputPage(page) {
      this.$emit('input-page', page)
    },

    normalizeTableItem(item) {
      let newValue
      return Object.entries(item).reduce((newItem, [key, value]) => {
        if (typeof value === 'object') {
          if (value.__isLink || value.__isImage) newValue = value.url
        } else {
          newValue = value
        }
        return {...newItem, [key]: newValue}
      }, {})
    },

    onToggleSelected(item) {
      const { __isSelected, __dragHandle, ...rawItem } = item
      this.$emit('toggle-selected', this.normalizeTableItem(rawItem))
    },

    onReorder(reorderedArray) {
      this.$emit('reorder', reorderedArray)
    }
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
