<template>
    <textarea
      class="parameter-textarea"
      :value="value"
      :placeholder="placeholderValue"
      v-debounce:500ms="(v) => updateProp(v)"
    >
    </textarea>
</template>

<script>
import parameterItem from "../../mixins/parameterItem";

export default {
  name: "ParameterTextarea",

  mixins: [ parameterItem ]
}
</script>

<style lang="scss" src="./index.scss"></style>
