<template>
  <div
    class="stream-components-video"
    @click.stop="$emit('click')"
    @mouseenter="$emit('mouseenter')"
    @mouseleave="$emit('mouseleave')"
  >
    <video
      :src="`${includeDomain ? domain : ''}${src}`"
      :poster="poster ? `${includeDomain ? domain : ''}${poster}` : ''"
      controls
      playsinline
      muted
      :loop="loop"
      :autoplay="autoplay"
    ></video>
    <slot />
  </div>
</template>

<script>
import {mapState} from "vuex";
import {getSizedPropValue} from "@/containers/Constructor/containers/Editor/utils";

export default {
  name: "VideoElement",

  props: {
    component: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      domain: process.env.VUE_APP_CONSTRUCTOR_2_BASE_URL,
    };
  },

  computed: {
    ...mapState({
      activeSize: state => state.constructor2.activeSize
    }),

    loop () {
      const propValue = getSizedPropValue(this.component?.props?.loop, this.activeSize, false)
      return propValue === 'On'
        || (!propValue && getSizedPropValue(this.component?.props?.loop, this.activeSize, true) === 'On')
    },

    autoplay () {
      const propValue = getSizedPropValue(this.component?.props?.autoplay, this.activeSize, false)
      return propValue === 'On'
        || (!propValue && getSizedPropValue(this.component?.props?.autoplay, this.activeSize, true) === 'On')
    },

    poster () {
      return getSizedPropValue(this.component?.props?.poster, this.activeSize)
    },

    src () {
      return getSizedPropValue(this.component?.props?.src, this.activeSize)
    },

    includeDomain () {
      return !(this.src.startsWith('http://') || this.src.startsWith('https://'))
    }
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
