<template>
  <Portal to="body">
    <the-modal v-if="isOpen" @close="onClose" class="stream-constructor-sync-modal">
      <div class="the-modal__title">Syncing components</div>
      <div class="the-modal__text">Choose the locale, template, and page you want to duplicate into the current page</div>
      <sync-content
        :epack-data="epackData"
        :available-locales="availableLocales"
        :selected-locale="selectedLocale"
        :selected-template="selectedTemplate"
        :selected-page="selectedPage"
        @select-locale="onSelectLocale"
        @select-template="onSelectTemplate"
        @select-page="onSelectPage"
      />
      <div class="the-modal__buttons">
        <button class="the-modal__button the-modal__button--black" @click="onSync" :disabled="!syncAvailable">Sync</button>
        <button class="the-modal__button" @click="onClose">Close</button>
      </div>
    </the-modal>
  </Portal>
</template>

<script>
import TheModal from "@/components/TheModal";
import {mapGetters, mapState} from "vuex";
import SyncContent from './components/Content'
import {Portal} from "portal-vue";

export default {
  name: "SyncModal",

  emits: ['close', 'sync'],

  components: {
    Portal,
    TheModal,
    SyncContent
  },

  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      selectedLocale: null,
      selectedTemplate: null,
      selectedPage: null
    }
  },

  computed: {
    ...mapState({
      epackData: state => state.constructor2.epackData
    }),
    ...mapGetters({
      availableLocales: "constructor2/locales"
    }),

    syncAvailable () {
      return (
        !!this.selectedLocale
        && !!this.selectedTemplate
        && !!this.selectedPage
      )
    }
  },

  methods: {
    onClose () {
      this.selectedLocale = null
      this.selectedTemplate = null
      this.selectedPage = null
      this.$emit('close')
    },

    onSync () {
      this.$emit('sync', {
        lang: this.selectedLocale,
        template: this.selectedTemplate,
        page: this.selectedPage,
        epackId: this.$route.params.id
      })
      this.onClose()
    },

    onSelectLocale (locale) {
      this.selectedLocale = locale
      this.selectedTemplate = null
      this.selectedPage = null
    },

    onSelectTemplate (template) {
      this.selectedTemplate = template
      this.selectedPage = null
    },

    onSelectPage (page) {
      this.selectedPage = page
    }
  }
}
</script>
