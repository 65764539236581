<template>
  <div class="constructor2-copy-between-sises">
    <div class="constructor2-copy-between-sises__button-wrapper" title="Copy page" @click="modalActive = true">
      <slot name="button">
        <button class="constructor2-copy-between-sises__button">
          <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="none" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'">
 <g>
  <path fill-rule="evenodd" clip-rule="evenodd" d="m17.7431,2c0.2796,0 0.5465,0.11705 0.7358,0.32276l3.2569,3.53832c0.1699,0.1846 0.2642,0.42634 0.2642,0.67724l0,10.46168c0,0.5523 -0.4477,1 -1,1l-5,0l0,-2l4,0l0,-8l-3,0c-1.1046,0 -2,-0.89543 -2,-2l0,-2l-3,0l0,1l-2,0l0,-2c0,-0.55228 0.4477,-1 1,-1l6.7431,0zm-0.4386,2l-0.3045,0l0,2l2.1454,0l-1.8409,-2z" fill="#8D9CA8" id="svg_1"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="m10.4789,6.32276c-0.1893,-0.20571 -0.4562,-0.32276 -0.73576,-0.32276l-6.74314,0c-0.55229,0 -1,0.44772 -1,1l0,14c0,0.5523 0.44772,1 1,1l10,0c0.5523,0 1,-0.4477 1,-1l0,-10.4617c0,-0.2509 -0.0943,-0.4926 -0.2642,-0.67722l-3.2569,-3.53832zm-6.4789,13.67724l8,0l0,-8l-3,0c-1.10457,0 -2,-0.8954 -2,-2l0,-2l-3,0l0,12zm5.30447,-12l1.84093,2l-2.1454,0l0,-2l0.30447,0z" fill="#8d9ca8" id="svg_2"/>
  <g stroke="null">
   <path stroke="null" fill="#8d9ca8" d="m8.46314,13.10314l-2.05551,0c-0.00814,0 -0.0152,0.00326 -0.0228,0.00434l-0.35127,0c-0.06732,0 -0.12161,0.05429 -0.12161,0.12161l0,5.48189c0,0.06732 0.05429,0.12161 0.12161,0.12161l1.189,0c0.06732,0 0.12161,-0.05429 0.12161,-0.12161l0,-1.7417l1.11896,0c1.07662,0 1.95235,-0.86705 1.95235,-1.93281c0.00054,-1.06576 -0.87573,-1.93335 -1.95235,-1.93335zm-0.09338,2.72656c-0.019,0.00163 -0.038,0.00326 -0.05755,0.00326l-0.9474,0c-0.00271,0 -0.00489,-0.00217 -0.0076,-0.00326a0.02785,0.02785 0 0 1 -0.01303,-0.00543a0.03133,0.03133 0 0 1 -0.0114,-0.02335l0,-1.52941c0,-0.00977 0.00489,-0.01737 0.0114,-0.02335c0.00326,-0.00326 0.0076,-0.0038 0.01194,-0.00489c0.00326,-0.00109 0.00489,-0.00326 0.00814,-0.00326l0.9474,0c0.02172,0 0.04343,0.00163 0.06461,0.00326c0.39362,0.0342 0.70417,0.37679 0.70417,0.79321c0.00054,0.41859 -0.31327,0.76281 -0.71069,0.79321z"/>
  </g>
 </g>
</svg>
        </button>
      </slot>
    </div>

    <the-modal class="constructor2-copy-between-sises__modal" v-show="modalActive" @close="onClose">
      <div class="the-modal__title">Copy page from Epack</div>

      <template v-if="!selectedEpack">
        <div class="constructor-sort-panel row">
          <div class="flex xs12 md10 constructor-search-wrapper" :style="{ display: 'flex' }">
            <div class="constructor-search-button" @click="onSearch">
              <va-icon name="entypo entypo-search" />
            </div>
            <va-input
              label=""
              placeholder="Search E-packs"
              :value="searchValue"
              class="input--border"
              removable
              @input="searchValue = $event"
              @keyup.enter="onSearch"
            />
          </div>
          <div class="flex xs12 md2">
            <va-select
              label="Per page"
              :options="perPageList"
              :value="perPage"
              @input="perPage = $event"
              class="constructor-per-page-select"
              noClear
            />
          </div>
        </div>
        <va-data-table
          :fields="computedFields"
          :data="epacksList"
          :loading="loading"
          :totalPages="totalPages"
          no-pagination
          @page-selected="loadList"
          api-mode
        >
          <template slot="actions" slot-scope="props">
            <div
              class="the-modal__button the-modal__button--black"
              :style="{ textAlign: 'center', padding: '8px 16px' }"
              @click="onSelectEpack(props.rowData)"
            >
              Select
            </div>
          </template>
        </va-data-table>
        <div class="va-data-table__pagination">
          <va-pagination
            :value="currentPage"
            @input="currentPage = $event"
            :visible-pages="5"
            :pages="totalPages"
          />
        </div>
      </template>
      <template v-else>
        <div
          class="the-modal__text"
          :style="{ marginBottom: '16px', fontSize: '16px', fontWeight: '700' }"
        >
          Selected epack: {{ selectedEpack.productName }} ({{ selectedEpack.id }})
        </div>
        <div class="the-modal__text">Choose the locale, template, and page you want to duplicate into the current page</div>
        <sync-content
          :epack-data="loadedEpack"
          :available-locales="availableLocales"
          :selected-locale="selectedLocale"
          :selected-template="selectedTemplate"
          :selected-page="selectedPage"
          :loading="loading"
          @select-locale="onSelectLocale"
          @select-template="onSelectTemplate"
          @select-page="onSelectPage"
        />
      </template>

      <div class="the-modal__buttons" v-if="selectedEpack">
        <button class="the-modal__button the-modal__button--black" @click="onCopy" :disabled="!copyAvailable">Copy</button>
        <button class="the-modal__button" @click="onBack">Back</button>
      </div>
    </the-modal>
  </div>
</template>

<script>
import TheModal from "@/components/TheModal";
import {showToastError} from "@/services/Helpers/HelperToast";
import {mapActions} from "vuex";
import SyncContent
  from "@/containers/Constructor/containers/Controls/containers/Actions/components/SyncModal/components/Content";

export default {
  name: "CopyBetweenSises",

  components: {
    SyncContent,
    TheModal
  },

  data () {
    return {
      // for modal
      modalActive: false,

      // for table
      loading: false,
      currentPage: 1,
      perPage: '10',
      searchValue: '',
      epacksList: [],
      totalEpacks: 0,
      perPageList: ["10", "25", "50", "100"],

      selectedEpack: null,
      loadedEpack: null,
      selectedLocale: null,
      selectedTemplate: null,
      selectedPage: null
    }
  },

  computed: {
    computedFields() {
      return [
        {
          name: "productName",
          title: "Name",
          width: "19%",
        },
        {
          name: "id",
          title: "epack ID",
          width: "28%",
        },
        {
          name: "brandName",
          title: "Brand",
          width: "13%",
        },
        {
          name: "updatedAt",
          title: "Updated",
          width: "15%",
        },
        {
          name: "mappingId",
          title: "Mapping ID",
          width: "12.5%",
        },
        {
          name: "__slot:actions",
          title: "",
          width: "90px"
        },
      ]
    },

    totalPages () {
      return Math.ceil(this.totalEpacks / this.perPage)
    },

    availableLocales() {
      if (this.loadedEpack) {
        const computedLocales = [];
        Object.keys(this.loadedEpack.components).forEach((localeName) => {
          computedLocales.push(localeName);
        });
        return computedLocales;
      }
      return []
    },

    copyAvailable() {
      return (
        !this.loading
        && !!this.selectedLocale
        && !!this.selectedTemplate
        && !!this.selectedPage
      )
    }
  },

  watch: {
    modalActive() {
      if (this.modalActive) {
        this.loadList()
      } else {
        this.reset()
      }
    },

    perPage () {
      this.onSearch()
    },

    currentPage () {
      this.loadList();
    },
  },

  methods: {
    ...mapActions("constructor2", [
      "getEpackages",
      "getEpackage",
      "syncComponents"
    ]),

    reset() {
      this.loading = false
      this.currentPage = 1
      this.perPage = '10'
      this.searchValue = ''
      this.epacksList = []
      this.totalEpacks = 0
      this.selectedEpack = null
      this.loadedEpack = null
      this.selectedLocale = null
      this.selectedTemplate = null
      this.selectedPage = null
    },

    onClose() {
      if (!this.loading) {
        this.modalActive = false
      }
    },

    onBack() {
      if (!this.loading) {
        if (this.selectedEpack) {
          this.selectedEpack = null
          this.loadedEpack = null
          this.selectedLocale = null
          this.selectedTemplate = null
          this.selectedPage = null
        } else {
          this.modalActive = false
        }
      }
    },

    onSearch () {
      if (this.currentPage !== 1) {
        this.currentPage = 1
      } else {
        this.loadList().then()
      }
    },

    onSelectLocale (locale) {
      this.selectedLocale = locale
      this.selectedTemplate = null
      this.selectedPage = null
    },

    onSelectTemplate (template) {
      this.selectedTemplate = template
      this.selectedPage = null
    },

    onSelectPage (page) {
      this.selectedPage = page
    },

    async onSelectEpack(epack) {
      this.selectedEpack = epack
      this.loading = true
      await this.getEpackage({id: epack.id})
        .then(res => {
          this.loadedEpack = res.structure.epackData
        })
        .catch(e => {
          console.error(e)
          showToastError(
            "Something wrong while loading the epack. <br/> Please try later",
            this.$toast
          );
        })
      this.loading = false
    },

    async loadList () {
      if (this.modalActive) {
        this.loading = true;
        await this.getEpackages({
          page: this.currentPage,
          perPage: this.perPage,
          search: this.searchValue.trim() || undefined
        })
          .then(res => {
            this.epacksList = res.data?.data || []
            this.totalEpacks = res.data?.pagination?.total || 0
          })
          .catch(e => {
            console.error(e)
            showToastError(
              "Something wrong. <br/> Please try later",
              this.$toast
            );
          })
        this.loading = false;
      }
    },

    async onCopy() {
      this.loading = true
      await this.syncComponents({
        epackIdFrom: this.selectedEpack.id,
        epackId: this.$route.params.id,
        lang: this.selectedLocale,
        template: this.selectedTemplate,
        page: this.selectedPage,
      }).then(() => {
        this.modalActive = false
      }).catch(() => {})
      this.loading = false
    },
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
