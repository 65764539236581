<template>
  <Accordion block expanded-by-default>
    <template #top>
      Dropdown Settings
    </template>

    <div class="stream-editor-parameter-panel__props">
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">No options text</div>
        <ParameterInput :prop="{...activeComponent.props.noOptionsText, key: 'noOptionsText'}" :size="size" :active-size="size" :value-type="valueType" />
      </div>
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">No options color</div>
        <ParameterColorPick :prop="{...activeComponent.props.noOptionsColor, key: 'noOptionsColor'}" :size="size" :active-size="size" :value-type="valueType" />
      </div>
    </div>

    <div class="stream-editor-parameter-panel__props">
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Option padding</div>
        <ParameterInput :prop="{...activeComponent.props.optionPadding, key: 'optionPadding'}" :size="size" :active-size="size" :value-type="valueType" />
      </div>
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Option border radius</div>
        <ParameterInput :prop="{...activeComponent.props.optionBorderRadius, key: 'optionBorderRadius'}" :size="size" :active-size="size" :value-type="valueType" />
      </div>
    </div>

    <div class="stream-editor-parameter-panel__props">
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Option background</div>
        <ParameterInput :prop="{...activeComponent.props.optionBackground, key: 'optionBackground'}" :size="size" :active-size="size" :value-type="valueType" />
      </div>
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title" title="Selected option background">Selected option background</div>
        <ParameterInput :prop="{...activeComponent.props.selectedOptionBackground, key: 'selectedOptionBackground'}" :size="size" :active-size="size" :value-type="valueType" />
      </div>
    </div>

    <div class="stream-editor-parameter-panel__props">
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title">Option transition</div>
        <ParameterInput :prop="{...activeComponent.props.optionTransition, key: 'optionTransition'}" :size="size" :active-size="size" :value-type="valueType" />
      </div>
      <div class="stream-editor-parameter-panel__prop">
        <div class="stream-editor-parameter-panel__prop-title" title="Space between options">Space between options</div>
        <ParameterInput :prop="{...activeComponent.props.spaceBetweenOptions, key: 'spaceBetweenOptions'}" :size="size" :active-size="size" :value-type="valueType" />
      </div>
    </div>
  </Accordion>
</template>

<script>
import Accordion from '@/containers/Constructor/containers/Editor/components/Accordion'

import ParameterInput from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Input";
import ParameterColorPick
  from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/ColorPick";
import settings from "@/modules/constructor2/editor/parameters-panel/mixins/settings";

export default {
  name: "ParametersPanelSettingsFormSelectDropdown",

  mixins: [settings],

  components: {
    Accordion,
    ParameterInput,
    ParameterColorPick
  },
}
</script>
