<template>
  <div class="s-select" :class="{ 's-select--open': dropdownOpen }" v-click-outside="onClickOutside" :key="selectKey">
    <div class="s-select__selector" @click="onClickSelector">
      <div class="s-select__selector-value" :class="{'s-select__selector-value--multiple': multiple}">
        <template v-if="valueVisible">
          <template v-if="multiple">
            <div class="s-select__selector-value-item" v-for="item in value" :key="item">
              <span>{{ getOptionLabelByKey(item) }}</span>
              <button class="s-select__selector-value-item-remove" @click="onUnselectOption($event, item)">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="#8d9ca8" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711Z"></path></svg>
              </button>
            </div>
          </template>
          <template v-else>
            <div class="s-select__selector-value-single">{{ getOptionLabelByKey(value) }}</div>
          </template>
        </template>
        <input
          type="text"
          class="s-select__selector-input"
          v-model="searchValue"
          v-if="inputVisible"
          :placeholder="placeholder"
          ref="searchInput"
        >
      </div>
      <button class="s-select__selector-dropdown">
        <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'">
  <defs>
    <linearGradient id="arrow-down-gradient" x2="0.35" y2="1">
      <stop offset="0%" stop-color="#2d4de0" />
      <stop offset="40%" stop-color="#9f71f0" />
      <stop offset="90%" stop-color="#fc6277" />
    </linearGradient>
  </defs>
  <defs>
    <linearGradient id="arrow-down-gradient-green" x2="1" y2=".25">
      <stop offset="0%" stop-color="#5166d7" />
      <stop offset="100%" stop-color="#43f59f" />
    </linearGradient>
  </defs>
  <path d="M5 6l5 5 5-5 2 1-7 7-7-7z"/>
</svg>
      </button>
    </div>
    <div class="s-select__dropdown" v-if="dropdownOpen">
      <div class="s-select__dropdown-no-options-text" v-if="noOptions">{{ noOptionsText }}</div>
      <ul class="s-select__dropdown-list">
        <li
          class="s-select__dropdown-list-item"
          :class="{'s-select__dropdown-list-item--selected': isOptionSelected(item)}"
          v-for="item in computedOptions"
          :key="item[keyBy]"
          @click="onClickOption(item)"
        >
          {{ item[labelBy] }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Select",

  props: {
    value: {
      type: [Number, String, Array],
      default: undefined,
    },
    options: {
      type: Array,
      default: () => [],
    },
    keyBy: {
      type: String,
      default: 'id'
    },
    labelBy: {
      type: String,
      default: 'label'
    },
    placeholder: {
      type: String,
      default: 'Search to select'
    },
    allowClear: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: 'bottom',
      validator(value) {
        return ['top', 'bottom', 'left', 'right'].includes(value)
      }
    },
    multiple: {
      type: Boolean,
      default: false
    },
    valueAsObject: {
      type: Boolean,
      default: false,
    },
    noOptionsText: {
      type: String,
      default: 'No data'
    }
  },

  data() {
    return {
      // the key is here to let v-click-outside differentiate between several Select components in a page
      selectKey: `select-${Math.round(Math.random * 1000)}`,
      dropdownOpen: false,
      searchValue: ''
    }
  },

  computed: {
    noValue() {
      if (this.multiple && (!this.value || this.value.length === 0)) return true
      else if (this.value === undefined || this.value === '') return true
      return false
    },

    inputVisible() {
      return this.dropdownOpen || (this.noValue || (!this.multiple && this.searchValue))
    },

    valueVisible() {
      if (this.multiple && !this.noValue) return true
      return !this.noValue && !this.dropdownOpen
    },

    noOptions() {
      return !this.options || !this.options.length
    },

    computedOptions() {
      if (this.searchValue.trim()) {
        const searchValue = this.searchValue.trim().toLowerCase()
        return this.options.filter(option => option[this.labelBy].toLowerCase().includes(searchValue))
      }
      return this.options
    }
  },

  methods: {
    onClickOutside() {
      this.searchValue = ''
      this.dropdownOpen = false
    },

    onClickSelector() {
      this.dropdownOpen = true
      // focus on search after it's been rendered
      this.$nextTick(() => {
        this.focusSearch()
      })
    },

    focusSearch() {
      if (this.$refs.searchInput) {
        this.$refs.searchInput.focus();
      }
    },

    isOptionSelected(option) {
      return this.multiple
        ? (this.value || []).findIndex(item => option[this.keyBy] === (this.valueAsObject ? item[this.keyBy] : item)) !== -1
        : (option[this.keyBy] === (this.valueAsObject ? this.value[this.keyBy] : this.value))
    },

    onClickOption(option) {
      if (this.multiple) {
        if (this.isOptionSelected(option)) {
          this.unselectOptionByKey(option[this.keyBy])
        } else {
          this.$emit('input', [...(this.value || []), this.valueAsObject ? option : option[this.keyBy]])
        }
      } else {
        this.$emit('input', this.valueAsObject ? option : option[this.keyBy])
        this.onClickOutside()
      }
    },

    unselectOptionByKey(optionKey) {
      this.$emit('input', this.value.filter(item => optionKey !== (this.valueAsObject ? item[this.keyBy] : item)))
    },

    onUnselectOption(event, optionKey) {
      event.stopPropagation();
      this.unselectOptionByKey(optionKey)
    },

    getOptionLabelByKey(item) {
      return this.options.find(option => option[this.keyBy] === (this.valueAsObject ? item[this.keyBy] : item))?.[this.labelBy]
    }
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
