<template>
  <Accordion block :expanded-by-default="accordionExpandedByDefault">
    <template #top>
      Rules
    </template>

    <div class="stream-editor-parameter-panel__prop">
      <parameters-panel-parameters-form-rules-parameter
        :rule-types="ruleTypes"
        :prop="rulesProp"
        :size="size"
        :active-size="size"
        :value-type="valueType"
      />
    </div>
  </Accordion>
</template>

<script>

import settings from "@/modules/constructor2/editor/parameters-panel/mixins/settings";
import Accordion from "@/containers/Constructor/containers/Editor/components/Accordion";
import ParametersPanelParametersFormRulesParameter
  from "@/modules/constructor2/editor/parameters-panel/molecules/form-rules/rules";

export default {
  name: "ParametersPanelParametersFormRules",

  mixins: [settings],

  components: {
    ParametersPanelParametersFormRulesParameter,
    Accordion,
  },

  props: {
    ruleTypes: Object
  },

  computed: {
    rulesProp() {
      return {...(this.activeComponent?.props?.rules || {}), key: 'rules'}
    }
  },

  created() {
    // expand the accordion if there are existing rules
    if (this.activeComponent?.props?.rules?.value && this.activeComponent.props.rules.value[this.size]?.length) {
      this.onHaveValues()
    }
  },
}
</script>
