<template>
  <vn-select-add
    class="parameter-select"
    :label="prop.name"
    :value="value || placeholderValue"
    :options="prop.items"
    @input="updateProp($event)"
    :no-clear="!clearable"
    no-remove-option
  />
</template>

<script>
import parameterItem from "../mixins/parameterItem";
import VnSelectAdd from '@/components/ui/vn-select-add.vue';

export default {
  name: "ParameterSelect",

  components: {
    VnSelectAdd
  },

  mixins: [ parameterItem ],

  computed: {
    clearable () {
      return this.prop.clearable
    }
  }
}
</script>
