var render = function render(){var _vm=this,_c=_vm._self._c;return _c('parameters-panel-multi-input',{staticClass:"parameter-attributes",attrs:{"value":_vm.value || _vm.placeholderValue,"value-template":[
    {
      prop: 'key',
      name: 'Key',
      placeholder: 'Enter key'
    },
    {
      prop: 'value',
      name: 'Value',
      placeholder: 'Enter value'
    },
  ]},on:{"input":_vm.onMultiInput}})
}
var staticRenderFns = []

export { render, staticRenderFns }