<template>
  <div class="c2-tabs">
    <ul class="c2-tabs__list">
      <li
        class="c2-tabs__item"
        :class="{ 'c2-tabs__item--active': value === item.key }"
        v-for="item in tabs"
        :key="item.key"
        @click="onClickTab(item)"
      >
        {{ item.label }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "C2Tabs",

  props: {
    value: {
      type: String,
      required: true
    },
    tabs: {
      type: Array,
      required: true,
      validator(value) {
        if (value instanceof Array) {
          const keys = value.map(item => item.key)
          return value.length !== 0 && (
            value.every(
              item =>
                !!item.key && typeof item.key === 'string' &&
                !!item.label && typeof item.label === 'string'
            ) &&
            [...new Set(keys)].length === keys.length
          )
        }
        return false
      }
    }
  },

  methods: {
    onClickTab(tab) {
      this.$emit('input', tab.key)
    }
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
