var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stream-components-row",class:{
    'stream-components-row--empty': _vm.empty,
    'stream-components-row--vertical': _vm.direction === 'vertical',
    'stream-components-row--horizontal': _vm.direction === 'horizontal',
    'stream-components-row--align-start': _vm.align === 'start',
    'stream-components-row--align-center': _vm.align === 'center',
    'stream-components-row--align-end': _vm.align === 'end'
  },style:({
    '--stream-components-row-size': _vm.size,
  }),on:{"click":function($event){$event.stopPropagation();return _vm.$emit('click')},"mouseenter":function($event){return _vm.$emit('mouseenter')},"mouseleave":function($event){return _vm.$emit('mouseleave')}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }