<template>
  <div class="stream-editor-tooltip" :class="{ 'stream-editor-tooltip--active': isActive }">
    <div class="stream-editor-tooltip__borderer" :style="bordererStyle" />
    <div class="stream-editor-tooltip__actions" :style="actionsStyle">
      <dynamic-configure ref="dynamicConfigure" />
      <open-link-action v-if="showLink" ref="openLinkAction" />
      <button
        class="stream-editor-tooltip__icon-button"
        @click="removeComponent(activeComponent)"
        v-if="showRemove"
        title="Remove"
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M8 4C7.44772 4 7 4.44772 7 5V7.5H4C3.44772 7.5 3 7.94772 3 8.5C3 9.05228 3.44772 9.5 4 9.5H5.04501L5.82486 17.2985C5.97822 18.8321 7.26872 20 8.80998 20H15.19C16.7313 20 18.0218 18.8321 18.1751 17.2985L18.955 9.5H20C20.5523 9.5 21 9.05228 21 8.5C21 7.94772 20.5523 7.5 20 7.5H17V5C17 4.44772 16.5523 4 16 4H8ZM15 7.5V6H9V7.5H15ZM7.05499 9.5H16.945L16.1851 17.0995C16.1339 17.6107 15.7038 18 15.19 18H8.80998C8.29622 18 7.86606 17.6107 7.81494 17.0995L7.05499 9.5Z" fill="#8D9CA8"></path></svg>
      </button>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import OpenLinkAction
  from "@/containers/Constructor/containers/Editor/containers/EditorPanel/containers/Tooltip/components/OpenLinkAction";
import DynamicConfigure
  from "@/containers/Constructor/containers/Editor/containers/EditorPanel/containers/Tooltip/components/DynamicConfigure";

const CONTAINER_CLASS = '.stream-editor-editor-panel-workarea__inner'
const PAGE_CONTAINER_CLASS = '.stream-editor-editor-panel__container'

const isElementVisible = (positioning) => {
  return !(positioning.left < 0 || positioning.right < 0);
}

const checkVisibility = (component) => {
  return !['table-row', 'table', 'table-column'].includes(component.componentKey)
}

export default {
  name: "EditorTooltip",

  components: {
    OpenLinkAction,
    DynamicConfigure
  },

  props: {
    scaling: {
      type: Number,
      default: 1
    }
  },

  data () {
    return {
      isUpdatingPositioning: false,
      activeComponentExists: false,
      positioning: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: 0,
        height: 0
      },
      mutationObserver: null
    }
  },

  computed: {
    ...mapState({
      activeComponent: state => state.constructor2.activeComponent,
      activeComponentDynamicOrder: state => state.constructor2.activeComponentDynamicOrder,
      activeSize: state => state.constructor2.activeSize,
    }),

    isActive() {
      return !this.isUpdatingPositioning && this.activeComponentExists
    },

    bordererStyle() {
      return {
        top: `${this.positioning.top}px`,
        left: `${this.positioning.left}px`,
        width: `${this.positioning.width}px`,
        height: `${this.positioning.height}px`,
      }
    },

    actionsStyle() {
      return {
        top: `${this.positioning.top}px`,
        right: `${this.positioning.right}px`,
      }
    },

    showLink() {
      return this.activeComponent?.name === 'Link'
    },

    showRemove() {
      return !this.activeComponent?.noManualRemove
    },
  },

  mounted() {
    this.mutationObserver = new MutationObserver(() => this.onUpdatePositioning());
    const container = document.querySelector(PAGE_CONTAINER_CLASS);
    this.mutationObserver.observe(container, {
      attributes: true,
      characterData: true,
      subtree: true,
      childList: true
    })
  },

  watch: {
    activeComponent: {
      deep: true,
      handler() {
        this.updatePositioning()
      }
    },

    activeComponentDynamicOrder() {
      this.updatePositioning()
    },
  },

  methods: {
    ...mapMutations({
      removeComponent: 'constructor2/removeComponent',
    }),

    onUpdatePositioning() {
      this.isUpdatingPositioning = true
      setTimeout(() => {
        this.updatePositioning()
        this.isUpdatingPositioning = false
      }, 500)
    },

    updatePositioning() {
      this.activeComponentExists = false
      if (this.activeComponent) {
        const activeComponentEl = document.querySelector(
          `.stream-component-id--${this.activeComponent.id}${
            this.activeComponentDynamicOrder > 0 ? `-${this.activeComponentDynamicOrder}` : ''
          }`
        )
        const activeComponentBoundingClientRect = activeComponentEl?.getBoundingClientRect()
        const containerBoundingClientRect = document.querySelector(CONTAINER_CLASS).getBoundingClientRect()
        if (activeComponentBoundingClientRect) {
          const positioning = {
            top: (activeComponentBoundingClientRect.top - containerBoundingClientRect.top) / (this.scaling || 1),
            left: (activeComponentBoundingClientRect.left - containerBoundingClientRect.left) / (this.scaling || 1),
            bottom: (containerBoundingClientRect.bottom - activeComponentBoundingClientRect.bottom) / (this.scaling || 1),
            right: (containerBoundingClientRect.right - activeComponentBoundingClientRect.right) / (this.scaling || 1),
            width: activeComponentBoundingClientRect.width / (this.scaling || 1),
            height: activeComponentBoundingClientRect.height / (this.scaling || 1),
          }
          const needsToCheckVisibility = checkVisibility(this.activeComponent)
          if (!needsToCheckVisibility || (needsToCheckVisibility && isElementVisible(positioning))) {
            this.activeComponentExists = true
            this.$set(this, 'positioning', positioning)
            if (this.$refs.openLinkAction) {
              this.$refs.openLinkAction.updateLink(activeComponentEl)
            }
            if (this.$refs.dynamicConfigure) {
              this.$refs.dynamicConfigure.reinit(activeComponentEl)
            }
          }
        }
      }
    },

    disconnectMutationObserver() {
      if (this.mutationObserver) {
        this.mutationObserver.disconnect()
      }
    }
  },

  beforeDestroy() {
    this.disconnectMutationObserver()
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
