<template>
  <button
    class="stream-components-form-button"
    :class="{ 'stream-components-form-button--empty': empty }"
    type="button"
    @click.stop="$emit('click')"
    @mouseenter="$emit('mouseenter')"
    @mouseleave="$emit('mouseleave')"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "FormButtonElement",

  props: {
    empty: {
      type: Boolean,
      default: false
    },
    component: {
      type: Object,
      default: () => {}
    }
  },
};
</script>

<style lang="scss" src="./index.scss"></style>
