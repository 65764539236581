<template>
  <tab-select
    class="parameter-tab-select"
    :value="{ id: value || placeholderValue, content: value || placeholderValue }"
    :items="prop.items ? prop.items.map(i => ({ id: i, content: '' })) : []"
    @input="updateProp($event.id)"
  >
    <template #slot-0><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><rect x="4" y="5" width="16" height="2" fill="black"></rect><rect x="4" y="11" width="8" height="2" fill="black"></rect><rect x="4" y="17" width="12" height="2" fill="black"></rect></svg></template>
    <template #slot-1><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><rect x="4" y="5" width="16" height="2" fill="black"></rect><rect x="8" y="11" width="8" height="2" fill="black"></rect><rect x="6" y="17" width="12" height="2" fill="black"></rect></svg></template>
    <template #slot-2><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><rect x="4" y="5" width="16" height="2" fill="black"></rect><rect x="12" y="11" width="8" height="2" fill="black"></rect><rect x="8" y="17" width="12" height="2" fill="black"></rect></svg></template>
  </tab-select>
</template>

<script>
import parameterItem from "../mixins/parameterItem";
import TabSelect from '@/containers/Constructor/components/TabSelect'

export default {
  name: "ParameterAlign",

  components: {
    TabSelect
  },

  mixins: [ parameterItem ],
}
</script>
