import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      addedComponents: 'constructor2/addedComponents'
    }),
  },

  methods: {
    findInUpperNestedComponents (componentName, providedComponent, paramToCheck = 'name') {
      const parent = this.addedComponents.find(c => c.id === providedComponent.parentId)
      if (parent) {
        if (parent[paramToCheck] === componentName) return parent
        else return this.findInUpperNestedComponents(componentName, parent)
      } else {
        return null
      }
    }
  }
}
