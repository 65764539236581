<template>
  <div
    class="constructor2-pulsating-dot"
    :class="[`constructor2-pulsating-dot--direction-${direction}`, `constructor2-pulsating-dot--color-${color}`]"
    :title="title"
  />
</template>

<script>
export default {
  name: "PulsatingDot",

  props: {
    color: { // allowed values: red, blue
      type: String,
      required: true
    },
    direction: { // allowed values: inward, outward
      type: String,
      required: true
    },
    title: String
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
