<template>
  <ColorPick
    :value="value"
    :placeholder="placeholderValue"
    @input="updateProp($event)"
  />
</template>

<script>
import parameterItem from "../mixins/parameterItem";
import ColorPick from '../components/ColorPick';

export default {
  name: "ParameterColorPick",

  components: {
    ColorPick
  },

  data () {
    return {
      undefinedValue: undefined
    }
  },

  mixins: [ parameterItem ]
}
</script>
