<template>
  <div
    class="stream-components-form-input"
    @click.stop="$emit('click')"
    @mouseenter="$emit('mouseenter')"
    @mouseleave="$emit('mouseleave')"
  >
    <input
      class="stream-components-form-input__input"
      :placeholder="placeholder"
      :name="name"
      :type="type"
      :style="passedStyle"
      :autocomplete="autocomplete"
      :value="localInputValue"
      @input="onInput"
    />
    <slot />
  </div>
</template>

<script>
import {mapState} from "vuex";
import {getSizedPropValue} from "@/containers/Constructor/containers/Editor/utils";
import {debounce} from "lodash";
import validateFormItem from "@/modules/constructor2/editor/shared/utils/validate-form-item";

export default {
  name: "FormInputElement",

  props: {
    component: {
      type: Object,
      default: () => {}
    },
    passedStyle: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      localInputValue: ''
    };
  },

  computed: {
    ...mapState({
      activeSize: state => state.constructor2.activeSize
    }),

    placeholder () {
      return getSizedPropValue(this.component?.props?.placeholder, this.activeSize) || undefined
    },

    name () {
      return getSizedPropValue(this.component?.props?.name, this.activeSize) || undefined
    },

    autocomplete () {
      return getSizedPropValue(this.component?.props?.autocomplete, this.activeSize) || undefined
    },

    type () {
      return getSizedPropValue(this.component?.props?.type, this.activeSize) || undefined
    },
  },

  watch: {
    activeSize() {
      this.debouncedValidate()
    }
  },

  methods: {
    onInput(event) {
      this.localInputValue = event.target.value
      this.debouncedValidate()
    },

    debouncedValidate: debounce(function () {
      validateFormItem(this.localInputValue, this.component, this.activeSize)
    }, 400),
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
