<template>
  <div class="stream-editor-editor-panel" :class="{'stream-editor-editor-panel--loading': loading}" :style="style">
    <workarea>
      <div
        class="stream-editor-editor-panel__container"
        :class="containerSize"
      >
        <workarea-components />
      </div>
    </workarea>
  </div>
</template>

<script>
import {mapState} from "vuex";
import Workarea from './containers/Workarea'
import WorkareaComponents from './containers/WorkareaComponents'
import {AVAILABLE_SIZES_LIST} from "@/store/modules/constructor2/consts";

export default {
  name: "EditorPanel",

  components: {
    Workarea,
    WorkareaComponents,
  },

  computed: {
    ...mapState({
      epackData: state => state.constructor2.epackData,
      activeSize: state => state.constructor2.activeSize,
      loading: state => state.constructor2.loading
    }),

    style() {
      return {
        ...AVAILABLE_SIZES_LIST.reduce((sizes, size) =>
          ({ ...sizes, [`--editor-size-${size.slug}`]: `${(this.epackData.sizes?.[size.slug] || size.value) + 2}px` }), {})
      }
    },

    containerSize () {
      if (this.activeSize === 'laptop') {
        return ['small-pc']
      }
      if (this.activeSize === 'tablet') {
        return ['small-pc', 'tablet']
      }
      if (this.activeSize === 'mobile') {
        return ['small-pc', 'tablet', 'mobile']
      }
      return []
    },
  }
};
</script>

<style lang="scss" src="./index.scss"></style>
