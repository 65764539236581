<template>
  <button
    @click="toggleValue"
    class="c2-parameter-hidden"
    :class="{'c2-parameter-hidden--active': boolValue}"
    :title="boolValue ? 'Hidden' : 'Visible'"
  >
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-show="!boolValue" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#fff" d="M21.8661 11.5043C21.2263 10.3943 17.7077 4.82427 11.7301 5.00427C6.2023 5.14427 3.00358 10.0043 2.13392 11.5043C2.04619 11.6563 2 11.8287 2 12.0043C2 12.1798 2.04619 12.3522 2.13392 12.5043C2.76367 13.5943 6.13233 19.0043 12.02 19.0043H12.2699C17.7977 18.8643 21.0064 14.0043 21.8661 12.5043C21.9538 12.3522 22 12.1798 22 12.0043C22 11.8287 21.9538 11.6563 21.8661 11.5043ZM12.2199 17.0043C7.91163 17.1043 5.10274 13.4143 4.22309 12.0043C5.22269 10.3943 7.83166 7.10427 11.8301 7.00427C16.1184 6.89427 18.9372 10.5943 19.8269 12.0043C18.7973 13.6143 16.2183 16.9043 12.2199 17.0043Z"></path><path fill="#fff" d="M11.9998 8.50427C11.3079 8.50427 10.6315 8.70954 10.0561 9.09413C9.48077 9.47871 9.03234 10.0253 8.76754 10.6649C8.50274 11.3044 8.43345 12.0082 8.56845 12.6871C8.70344 13.366 9.03665 13.9897 9.52594 14.4791C10.0152 14.9686 10.6386 15.302 11.3173 15.437C11.996 15.5721 12.6994 15.5028 13.3387 15.2379C13.978 14.9729 14.5244 14.5243 14.9088 13.9488C15.2933 13.3732 15.4984 12.6965 15.4984 12.0043C15.4984 11.076 15.1298 10.1858 14.4737 9.5294C13.8176 8.87302 12.9277 8.50427 11.9998 8.50427ZM11.9998 13.5043C11.7033 13.5043 11.4134 13.4163 11.1668 13.2515C10.9202 13.0867 10.728 12.8524 10.6146 12.5783C10.5011 12.3042 10.4714 12.0026 10.5292 11.7116C10.5871 11.4207 10.7299 11.1534 10.9396 10.9436C11.1493 10.7338 11.4165 10.591 11.7073 10.5331C11.9982 10.4752 12.2996 10.5049 12.5736 10.6185C12.8476 10.732 13.0818 10.9242 13.2465 11.1709C13.4113 11.4176 13.4992 11.7076 13.4992 12.0043C13.4992 12.4021 13.3413 12.7836 13.0601 13.0649C12.7789 13.3462 12.3975 13.5043 11.9998 13.5043Z"></path></svg>
    <svg viewBox="0 -1 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-show="boolValue" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.29289 20.2929C0.902367 20.6834 0.902367 21.3166 1.29289 21.7071C1.68342 22.0976 2.31658 22.0976 2.70711 21.7071L3.7048 20.7094L3.70711 20.7071L21.7071 2.70711L21.7093 2.70487L22.7071 1.70711C23.0976 1.31658 23.0976 0.68342 22.7071 0.29289C22.3166 -0.097631 21.6834 -0.097631 21.2929 0.29289L1.29289 20.2929z" fill="#fff"/><path d="M19.5969 6.93936C19.2188 7.3419 19.2386 7.97475 19.6411 8.35288C20.4437 9.1068 21.2296 9.989 21.9978 11C18.9335 15.0325 15.6129 17 12.0011 17C11.4223 17 10.8528 16.9498 10.2921 16.8494C9.74848 16.7521 9.22889 17.114 9.13159 17.6576C9.03429 18.2013 9.39613 18.7209 9.93978 18.8182C10.6172 18.9394 11.3045 19 12.0011 19C16.3004 19 20.1751 16.7042 23.5902 12.2101C24.1337 11.4949 24.1337 10.5051 23.5902 9.7899C22.7567 8.69307 21.897 7.72797 21.0104 6.89513C20.6079 6.51701 19.975 6.53681 19.5969 6.93936z" fill="#fff"/><path d="M4.40412 15.0597C4.78228 14.6571 4.76253 14.0243 4.36002 13.6461C3.55775 12.8924 2.77226 12.0105 2.00433 11C5.0687 6.96748 8.38928 5 12.0011 5C12.5793 5 13.1482 5.0501 13.7084 5.15026C14.252 5.24746 14.7716 4.88554 14.8688 4.34187C14.966 3.79821 14.6041 3.27868 14.0604 3.18148C13.3836 3.06048 12.6969 3 12.0011 3C7.70178 3 3.82708 5.2958 0.41195 9.7899C-0.131537 10.5051 -0.131538 11.4949 0.411944 12.2101C1.24509 13.3064 2.10438 14.2712 2.9906 15.1038C3.39311 15.4819 4.02597 15.4622 4.40412 15.0597z" fill="#fff"/></svg>
  </button>
</template>

<script>
import parameterItem from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/mixins/parameterItem";

export default {
  name: "ParametersPanelParameterHidden",

  mixins: [ parameterItem ],

  computed: {
    boolValue() {
      return this.value === 'on'
    }
  },

  methods: {
    toggleValue() {
      this.updateProp(this.boolValue ? 'off' : 'on', { ...this.prop, key: 'hide' })
    },
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
