<template>
  <div class="parameter-files">
    <div class="parameter-files-container">
      <div class="parameter-files-container__overlay" v-if="loading">
        <va-progress-bar
          indeterminate
          :style="{
            width: '100%',
            position: 'absolute',
            left: 0,
            bottom: '-12px',
          }"
        />
      </div>
      <div class="parameter-files__inner">
        <va-file-upload
          type="list"
          v-model="file"
          :disabled="loading"
          class="parameter-files__dropzone dropzone--white dropzone--vertical"
          :style="{ background: '#ffffff', border: '2px dashed #D6DEE2' }"
          dropzone
        />
        <draggable
          v-if="value && value.length"
          :value="value"
          @input="onUpdateFiles($event, true)"
          handle=".parameter-files__preview__file__actions-item--move"
          class="parameter-files__preview"
        >
          <div class="parameter-files__preview__file" v-for="file in value" :key="file">
            <div class="parameter-files__preview__file__actions">
              <div
                class="parameter-files__preview__file__actions-item parameter-files__preview__file__actions-item--move"
              >
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 330 330" style="enable-background:new 0 0 330 330;" xml:space="preserve" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'">
<path id="XMLID_16_" d="M4.394,154.394c-5.858,5.857-5.858,15.355,0,21.213l37.5,37.5c2.929,2.929,6.768,4.394,10.606,4.394
	s7.678-1.465,10.606-4.394c5.858-5.857,5.858-15.355,0-21.213L51.213,180H150v98.787l-11.894-11.893
	c-5.857-5.857-15.355-5.857-21.213,0c-5.858,5.857-5.858,15.355,0,21.213l37.5,37.5C157.322,328.535,161.161,330,165,330
	s7.678-1.465,10.606-4.394l37.5-37.5c5.858-5.857,5.858-15.355,0-21.213c-5.857-5.857-15.355-5.857-21.213,0L180,278.787V180h98.787
	l-11.893,11.894c-5.858,5.857-5.858,15.355,0,21.213c2.929,2.929,6.767,4.394,10.606,4.394c3.839,0,7.678-1.465,10.606-4.394
	l37.5-37.5c5.858-5.857,5.858-15.355,0-21.213l-37.5-37.5c-5.857-5.857-15.355-5.857-21.213,0c-5.858,5.857-5.858,15.355,0,21.213
	L278.787,150H180V51.213l11.894,11.893c2.929,2.929,6.768,4.394,10.606,4.394s7.678-1.465,10.606-4.394
	c5.858-5.857,5.858-15.355,0-21.213l-37.5-37.5c-5.857-5.858-15.355-5.858-21.213,0l-37.5,37.5c-5.858,5.857-5.858,15.355,0,21.213
	c5.857,5.857,15.355,5.857,21.213,0L150,51.213V150H51.213l11.894-11.894c5.858-5.857,5.858-15.355,0-21.213
	c-5.857-5.857-15.355-5.857-21.213,0L4.394,154.394z"/>
</svg>
              </div>
              <button class="parameter-files__preview__file__actions-item" @click="onRemoveFile(file)">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M8 4C7.44772 4 7 4.44772 7 5V7.5H4C3.44772 7.5 3 7.94772 3 8.5C3 9.05228 3.44772 9.5 4 9.5H5.04501L5.82486 17.2985C5.97822 18.8321 7.26872 20 8.80998 20H15.19C16.7313 20 18.0218 18.8321 18.1751 17.2985L18.955 9.5H20C20.5523 9.5 21 9.05228 21 8.5C21 7.94772 20.5523 7.5 20 7.5H17V5C17 4.44772 16.5523 4 16 4H8ZM15 7.5V6H9V7.5H15ZM7.05499 9.5H16.945L16.1851 17.0995C16.1339 17.6107 15.7038 18 15.19 18H8.80998C8.29622 18 7.86606 17.6107 7.81494 17.0995L7.05499 9.5Z" fill="#8D9CA8"></path></svg>
              </button>
            </div>
            <img :src="domain + file" alt="" />
          </div>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import { mapState } from "vuex";
import parameterItem from "../../mixins/parameterItem";

import { showToastError } from "@/services/Helpers/HelperToast";
import axios from "axios";

export default {
  name: "ParameterFiles",

  components: {
    Draggable
  },

  mixins: [ parameterItem ],

  props: {
    prop: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      loading: false,
      file: [],
      domain: process.env.VUE_APP_CONSTRUCTOR_2_BASE_URL,
    };
  },

  computed: {
    ...mapState({
      activeTemplate: (state) => state.constructor2.activeTemplate,
      activeLocale: (state) => state.constructor2.activeLocale,
    }),
  },

  watch: {
    file: {
      deep: true,
      async handler () {
        if (this.file.length) {
          this.loading = true;
          const uploadPromises = []
          this.file.forEach(file => {
            let formData = new FormData();
            formData.append("file", file);
            uploadPromises.push(axios.post(
              `${process.env.VUE_APP_CONSTRUCTOR_2_BASE_URL}/api/constructor/load/${this.prop?.fileType === 'video' ? 'video' : 'image'}`,
              formData, {
                headers: {
                  Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`
                }
              })
              .then((response) => {
                if (response.data.code === 200) {
                  return response.data.data.path
                } else if (response.data.code === 402) {
                  showToastError(response.data.message, this.$toast);
                } else {
                  showToastError(
                    "Something wrong while uploading the file. <br/> Please try later",
                    this.$toast
                  );
                }
                this.loading = false;
              })
              .catch((error) => {
                if (error.response.status === 402) {
                  showToastError(error.response.data.data.message, this.$toast);
                } else {
                  showToastError(
                    "Something wrong while uploading the file. <br/> Please try later",
                    this.$toast
                  );
                }
              }))
          })
          await Promise.all(uploadPromises)
            .then(files => {
              this.onUpdateFiles(files)
            })
            .catch(() => {})
          this.file = []
          this.loading = false;
        }
      },
    },
  },

  methods: {
    onUpdateFiles(files, replace = false) {
      const prop = this.prop
      this.updateActiveComponentProp({
        prop,
        updatedProp: {
          ...prop,
          value: {
            ...(prop.value || {}),
            [this.size]: replace ? files : (prop.value?.[this.size] ? [...prop.value[this.size], ...files] : files)
          }
        }
      })
    },

    onRemoveFile(file) {
      const prop = this.prop
      this.updateActiveComponentProp({
        prop,
        updatedProp: {
          ...prop,
          value: {
            ...(prop.value || {}),
            [this.size]: prop.value[this.size].filter(item => item !== file)
          }
        }
      })
    }
  }
};
</script>

<style lang="scss" src="./index.scss"></style>
