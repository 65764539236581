<template>
  <div
    class="stream-components-form-select"
    @click.stop="$emit('click')"
    @mouseenter="$emit('mouseenter')"
    @mouseleave="$emit('mouseleave')"
  >
    <div
      class="stream-components-form-select-selector"
      :class="[
        `stream-component-id--${selectorComponentId}`,
      ]"
      :style="selectorStyle"
      @click.stop="onClickSelector"
    >
      <div class="stream-components-form-select-selector__value"
           :class="{ 'stream-components-form-select-selector__value--multiple': multiple }">
        <div class="stream-components-form-select-selector__value-item" v-for="item in value" :key="item" v-show="valueVisible">
          <span>{{ getOptionLabelByKey(item) }}</span>
          <button class="stream-components-form-select-selector__value-item-remove" @click="onUnselectOption($event, item)">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="#8d9ca8" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711Z"></path></svg>
          </button>
        </div>
        <input
          type="text"
          class="stream-components-form-select-selector__input"
          v-model="searchValue"
          v-show="inputVisible"
          :placeholder="placeholder"
          ref="searchInput"
        >
      </div>
      <div class="stream-components-form-select-selector__icon">
        <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'">
  <defs>
    <linearGradient id="arrow-down-gradient" x2="0.35" y2="1">
      <stop offset="0%" stop-color="#2d4de0" />
      <stop offset="40%" stop-color="#9f71f0" />
      <stop offset="90%" stop-color="#fc6277" />
    </linearGradient>
  </defs>
  <defs>
    <linearGradient id="arrow-down-gradient-green" x2="1" y2=".25">
      <stop offset="0%" stop-color="#5166d7" />
      <stop offset="100%" stop-color="#43f59f" />
    </linearGradient>
  </defs>
  <path d="M5 6l5 5 5-5 2 1-7 7-7-7z"/>
</svg>
      </div>
    </div>
    <div
      class="stream-components-form-select-dropdown"
      :class="[
        `stream-component-id--${dropdownComponentId}`,
      ]"
      :style="dropdownStyle"
      v-show="dropdownOpen"
    >
      <div
        class="stream-components-form-select-dropdown__no-options-text"
        v-show="noOptions"
        :style="{ color: noOptionsColor, padding: optionPadding }"
      >{{ noOptionsText }}
      </div>
      <ul class="stream-components-form-select-dropdown__list">
        <li
          v-for="item in computedOptions"
          :key="item.id"
          class="stream-components-form-select-dropdown__list-item"
          :class="[
          `stream-component-id--${item.id}`,
          {'stream-components-form-select-dropdown__list-item--selected': isOptionSelected(item)}
        ]"
          :style="optionStyle"
          @click="onClickOption(item)"
        >
          {{ item.label }}
        </li>
      </ul>
    </div>
    <!--  error component  -->
    <WorkareaComponent
      v-if="errorComponent"
      :key="`${errorComponent.id}-${errorComponent.parentId}-${errorComponent.order}`"
      :component="errorComponent"
    />
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import {computeComponentStyle, getSizedPropValue} from "@/containers/Constructor/containers/Editor/utils";
import {debounce} from "lodash";
import validateFormItem from "@/modules/constructor2/editor/shared/utils/validate-form-item";

export default {
  name: "FormSelectElement",

  components: {
    WorkareaComponent: () => import("@/containers/Constructor/containers/Editor/containers/EditorPanel/containers/WorkareaComponents/containers/Component")
  },

  props: {
    empty: {
      type: Boolean,
      default: false
    },
    component: {
      type: Object,
      default: () => {
      }
    }
  },

  data() {
    return {
      searchValue: '',
      value: [],
      dropdownOpen: false
    }
  },

  computed: {
    ...mapState({
      activeSize: state => state.constructor2.activeSize,
      activeComponent: state => state.constructor2.activeComponent,
      vw: state => state.constructor2.windowWidth,
      vh: state => state.constructor2.windowHeight,
    }),
    ...mapGetters({
      addedComponents: 'constructor2/addedComponents'
    }),

    noValue() {
      return this.value.length === 0;
    },

    multiple() {
      return getSizedPropValue(this.component.props?.multiple, this.activeSize) === 'on' || false
    },

    placeholder() {
      return getSizedPropValue(this.component.props?.placeholder, this.activeSize) || false
    },

    inputVisible() {
      return this.dropdownOpen || (this.noValue || (!this.multiple && this.searchValue))
    },

    valueVisible() {
      return (this.multiple && this.value.length) || (!this.multiple && !this.inputVisible)
    },

    errorComponent() {
      return this.component.children?.[2]
    },

    selectorComponent() {
      return this.component.children?.[0]
    },

    selectorComponentId() {
      return this.selectorComponent?.id
    },

    dropdownComponent() {
      return this.component.children?.[1]
    },

    dropdownComponentId() {
      return this.dropdownComponent?.id
    },

    selectorStyle () {
      if (this.selectorComponent)
        return computeComponentStyle(this.selectorComponent, this.activeSize, this.vw, this.vh, this.hovered ? 'hover' : undefined)
      return {}
    },

    dropdownStyle () {
      if (this.dropdownComponent)
        return computeComponentStyle(this.dropdownComponent, this.activeSize, this.vw, this.vh, this.hovered ? 'hover' : undefined)
      return {}
    },

    options() {
      return this.dropdownComponent?.children?.map(child => ({
        id: child.id,
        value: getSizedPropValue(child.props?.value, this.activeSize),
        label: getSizedPropValue(child.props?.label, this.activeSize),
      })) || []
    },

    computedOptions() {
      if (this.searchValue.trim()) {
        const searchValue = this.searchValue.trim().toLowerCase()
        return this.options.filter(option => option.label.toLowerCase().includes(searchValue))
      }
      return this.options
    },

    noOptions() {
      return !this.computedOptions.length
    },

    noOptionsText() {
      return getSizedPropValue(this.dropdownComponent?.props?.noOptionsText, this.activeSize) || ''
    },

    noOptionsColor() {
      return getSizedPropValue(this.dropdownComponent?.props?.noOptionsColor, this.activeSize)
    },

    optionPadding() {
      return getSizedPropValue(this.dropdownComponent?.props?.optionPadding, this.activeSize)
    },

    optionStyle() {
      return {
        padding: this.optionPadding,
        transition: getSizedPropValue(this.dropdownComponent?.props?.optionTransition, this.activeSize),
        borderRadius: getSizedPropValue(this.dropdownComponent?.props?.optionBorderRadius, this.activeSize),
        '--option-background': getSizedPropValue(this.dropdownComponent?.props?.optionBackground, this.activeSize),
        '--hover-option-background': getSizedPropValue(this.dropdownComponent?.props?.optionBackground, this.activeSize, undefined, 'hover'),
        '--selected-option-background': getSizedPropValue(this.dropdownComponent?.props?.selectedOptionBackground, this.activeSize),
        '--space-between-options': getSizedPropValue(this.dropdownComponent?.props?.spaceBetweenOptions, this.activeSize),
      }
    },
  },

  watch: {
    activeComponent() {
      if (!this.activeComponent ||
        ![
          this.component.id,
          ...(this.component.children?.flatMap((child, index) => index !== 1
              ? child.id
              : [child.id, ...(child.children?.map(option => option.id) || [])])
            || [])
        ]
          .includes(this.activeComponent.id)) {
        this.onClickOutside()
      }
    }
  },

  methods: {
    ...mapMutations({
      setActiveComponent: 'constructor2/setActiveComponent'
    }),

    onClickOutside() {
      this.searchValue = ''
      this.dropdownOpen = false
    },

    focusSearch() {
      if (this.$refs.searchInput) {
        this.$refs.searchInput.focus();
      }
    },

    onClickSelector() {
      this.setActiveComponent({
        component: this.addedComponents.find(c => c.id === this.component.children[0].id),
      })
      this.dropdownOpen = true
      this.$nextTick(() => {
        this.focusSearch()
      })
    },

    getOptionLabelByKey(item) {
      return this.options.find(option => option.value === item)?.label
    },

    unselectOptionByKey(optionKey) {
      this.value = this.value.filter(item => optionKey !== item)
    },

    onUnselectOption(event, optionKey) {
      event.stopPropagation();
      this.unselectOptionByKey(optionKey)
      this.onValueChange()
    },

    isOptionSelected(option) {
      return this.value.findIndex(item => option.value === item) !== -1
    },

    onClickOption(option) {
      if (this.multiple) {
        if (this.isOptionSelected(option)) {
          this.unselectOptionByKey(option.value)
        } else {
          this.value.push(option.value)
        }
      } else {
        this.value = [option.value]
        this.onClickOutside()
      }
      this.onValueChange()
    },

    onValueChange() {
      this.debouncedValidate()
    },

    debouncedValidate: debounce(function () {
      validateFormItem(this.value, this.component, this.activeSize, 2)
    }, 400),
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
