<template>
  <control-item title="Select page" class="constructor2-controls-select-page">
    <VnSelectAdd
      class="stream-controls-item__select constructor2-controls-select-page__select"
      :label="$t('constructor.Pages')"
      :addHandler="() => {this.addPopupShown = true}"
      :delHandler="()=>{this.removePopupShown = true}"
      @returnValForDelete="val=>{this.forDelete = val}"
      :value="activePage"
      @input="onChangePage"
      :loading="loading"
      icon="search"
      :options="pages"
      :non-removables="['index.html']"
      noClear
    />
    <va-modal
      v-model="addPopupShown"
      size="medium"
      :title="$t('constructor.AddPage')"
      cancelText=""
      okText="Add"
      @ok="pageVal.trim() !== '' ? addPage(pageVal +'.html') : null"
    >
      <div class="layout fluid gutter--md">
        <div class="row">
          <div class="flex xs12">
            <va-input
              :label="$t('constructor.Page')"
              v-model="pageVal"
              class="select-page-modal-input"
              noClear
            />
            <div class="select-page-modal-text">
              .html
            </div>
          </div>
        </div>
      </div>
    </va-modal>
    <va-modal
      v-model="removePopupShown"
      size="medium"
      title="Delete page"
      :message="`This action will delete page.`"
      okText="Delete"
      cancelText="Cancel"
      @ok="onDeletePage"
    />
  </control-item>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import ControlItem from "../../components/ControlItem";
import VnSelectAdd from '@/components/ui/vn-select-add.vue';
import {showToastError} from "@/services/Helpers/HelperToast";

export default {
  name: "SelectPage",

  components: {
    ControlItem,
    VnSelectAdd
  },

  data () {
    return {
      addPopupShown: false,
      removePopupShown: false,
      forDelete: null,
      pageVal: null,
      loading: false
    }
  },

  computed: {
    ...mapState({
      activePage: state => state.constructor2.activePage,
    }),
    ...mapGetters({
      pages: 'constructor2/pages'
    })
  },

  watch: {
    addPopupShown() {
      this.pageVal = null
    }
  },

  methods: {
    ...mapMutations({
      changePage: 'constructor2/changePage',
      addPage: 'constructor2/addPage',
    }),
    ...mapActions({
      deletePage: 'constructor2/deletePage',
    }),

    onChangePage(page) {
      this.changePage(page)
    },

    async onDeletePage() {
      this.loading = true
      await this.deletePage({page: this.forDelete, epackId: this.$route.params.id !== 'untitled' ? this.$route.params.id : null})
        .catch(() => {
          showToastError('Something went wrong while removing the page', this.$toast)
        })
      this.loading = false
    }
  }
};
</script>

<style lang="scss" src="./index.scss"></style>
