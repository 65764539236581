<template>
  <div>
    <parameters-panel-settings-form :size="size" :value-type="valueType" />
  </div>
</template>

<script>
import ParametersPanelSettingsForm from "@/modules/constructor2/editor/parameters-panel/molecules/settings-form";
export default {
  name: "ParametersPanelParametersForm",

  props: {
    size: {
      type: String,
      default: 'desktop'
    },
    valueType: {
      type: String,
      default: 'general'
    }
  },

  components: {
    ParametersPanelSettingsForm
  }
}
</script>
