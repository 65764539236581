<template>
  <div class="stream-controls">
    <template v-if="activeTab === 'constructor'">
      <select-locale />
      <select-template />
      <select-page />
      <select-size />
    </template>
    <actions />
  </div>
</template>

<script>
import SelectLocale from './containers/SelectLocale'
import SelectTemplate from './containers/SelectTemplate'
import SelectPage from './containers/SelectPage'
import SelectSize from './containers/SelectSize'
import Actions from './containers/Actions'
import {mapState} from "vuex";

export default {
  name: "ConstructorControls",

  components: {
    SelectLocale,
    SelectTemplate,
    SelectPage,
    SelectSize,
    Actions,
  },

  computed: {
    ...mapState({
      activeTab: state => state.constructor2.activeTab
    })
  }
};
</script>

<style lang="scss" src="./index.scss"></style>
