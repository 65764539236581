<template>
  <Accordion block expanded-by-default>
    <template #top>
      360 Viewer Settings
    </template>

    <div class="stream-editor-parameter-panel__prop">
      <div class="c2-parameter-panel-settings-the-360-viewer__title-with-tip">
        <div class="stream-editor-parameter-panel__prop-title">
          Images
        </div>
        <v-dropdown auto-hide :triggers="['hover']" placement="top" :distance="5" class="c2-parameter-panel-settings-the-360-viewer__title-tip">
          <button>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'">

  <defs>
    <linearGradient id="question-circled-gradient" x2="0.35" y2="1">
      <stop offset="0%" stop-color="#2d4de0" />
      <stop offset="40%" stop-color="#9f71f0" />
      <stop offset="90%" stop-color="#fc6277" />
    </linearGradient>
    <linearGradient id="question-circled-gradient-green" x2="0.75" y2="1">
      <stop offset="0%" stop-color="#5166d7" />
      <stop offset="100%" stop-color="#43f59f" />
    </linearGradient>
  </defs>

  <path d="M256,0C114.62,0,0,114.62,0,256s114.62,256,256,256s256-114.62,256-256S397.38,0,256,0z M256,486.4
    C128.956,486.4,25.6,383.044,25.6,256S128.956,25.6,256,25.6S486.4,128.956,486.4,256S383.044,486.4,256,486.4z" fill="url(#question-circled-gradient)"/>

  <path d="M319.258,119.578c-13.858-11.981-31.718-17.98-53.581-17.98h-4.403c-22.673,0-42.001,7.074-58.001,21.197
    c-17.604,15.753-26.402,36.804-26.402,63.198c0,4.275,1.203,7.603,3.601,10.001c2.398,2.15,5.325,3.072,8.798,2.799
    c3.2,0,5.999-1.203,8.397-3.601c2.662-2.654,4.002-5.854,4.002-9.6c0-14.925,3.465-27.853,10.402-38.801
    c10.129-15.454,26.522-23.202,49.203-23.202c19.2,0,33.724,5.197,43.597,15.599c8.26,8.55,12.527,19.601,12.8,33.203
    c0,11.998-2.534,22.673-7.603,32c-5.871,10.675-16.401,22.4-31.599,35.2c-12.8,10.402-21.734,21.999-26.803,34.799
    c-5.077,12.271-7.603,27.878-7.603,46.797c0,3.746,1.203,6.801,3.601,9.199c2.398,2.15,5.325,3.2,8.798,3.2
    c3.2,0,5.999-1.05,8.397-3.2c2.662-2.398,4.002-5.453,4.002-9.199c0-18.654,2.261-33.05,6.801-43.204
    c4.523-9.6,13.329-19.729,26.402-30.404c14.404-12.254,24.798-24.124,31.198-35.601c6.127-11.204,9.199-23.723,9.199-37.598
    C342.46,150.929,334.72,132.651,319.258,119.578z" fill="url(#question-circled-gradient)" />

  <circle cx="256" cy="384" r="25.6" fill="url(#question-circled-gradient)"/>
</svg>
          </button>

          <template #popper>
            <div class="c2-parameter-panel-settings-the-360-viewer__title-tip-content">
              The recommended number of images for the 360 Viewer to properly work should be a divisor of 360 (e.g. 15)
            </div>
          </template>
        </v-dropdown>
      </div>
      <ParameterFiles :prop="{...activeComponent.props.images, key: 'images'}" :size="size" :active-size="size" :value-type="valueType" />
    </div>
  </Accordion>
</template>

<script>
import Accordion from '@/containers/Constructor/containers/Editor/components/Accordion'
import ParameterFiles from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Files";
import settings from "@/modules/constructor2/editor/parameters-panel/mixins/settings";

export default {
  name: "ParametersPanelSettingsThe360Viewer",

  mixins: [settings],

  components: {
    ParameterFiles,
    Accordion,
  },
}
</script>

<style lang="scss" src="./index.scss"></style>
