<template>
  <div
    class="stream-components-column"
    :class="[
      { 'stream-components-column--empty': empty },
      [columnWithPresetClass]
    ]"
    @click.stop="$emit('click')"
    @mouseenter="$emit('mouseenter')"
    @mouseleave="$emit('mouseleave')"
  >
    <slot />
  </div>
</template>

<script>
import {mapState} from "vuex";
import {getSizedPropValue} from "@/containers/Constructor/containers/Editor/utils";

export default {
  name: "ColumnElement",

  props: {
    empty: {
      type: Boolean,
      default: false
    },
    component: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    ...mapState({
      activeSize: state => state.constructor2.activeSize
    }),

    columnWithPresetClass () {
      return (
        `stream-components-column--${
          getSizedPropValue(this.component?.props?.columnWidthPreset, this.activeSize)
        }`
      )
    }
  }
};
</script>

<style lang="scss" src="./index.scss"></style>
