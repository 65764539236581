<template>
  <parameters-panel-settings-script :size="size" :value-type="valueType" />
</template>

<script>
import ParametersPanelSettingsScript from "@/modules/constructor2/editor/parameters-panel/molecules/settings-script";

export default {
  name: "ParametersPanelParametersScript",

  props: {
    size: {
      type: String,
      default: 'desktop'
    },
    valueType: {
      type: String,
      default: 'general'
    }
  },

  components: {
    ParametersPanelSettingsScript,
  }
}
</script>
