<template>
  <number-input-select
    :input-value="lineHeight.number"
    :select-value="lineHeight.suffix"
    :select-items="['px', '%', 'rem', 'em']"
    @change="onNumberInput"
    @select-change="onSuffixChange"
  />
</template>

<script>
import parameterItem from "../mixins/parameterItem";
import NumberInputSelect from '../components/NumberInputSelect';

export default {
  name: "ParameterLineHeight",

  components: {
    NumberInputSelect
  },

  mixins: [ parameterItem ],

  computed: {
    lineHeight () {
      const fullValue = this.value || this.placeholderValue
      return {
        number: fullValue.match(/(\d+)/)?.[0] || '150',
        suffix: fullValue.match(/%|rem|px|em/)?.[0] || '%'
      }
    },
  },

  methods: {
    onNumberInput (value) {
      const numberValue = value?.match(/(\d+)/)?.[0] || ''
      let suffixValue = this.lineHeight.suffix
      if (value.includes('p')) suffixValue = 'px'
      else if (value.includes('%')) suffixValue = '%'
      else if (value.includes('r')) suffixValue = 'rem'
      else if (value.includes('e')) suffixValue = 'em'
      else if (!suffixValue) suffixValue = 'px'
      this.updateProp(`${numberValue}${suffixValue}`)
    },

    onSuffixChange (suffix) {
      this.updateProp(`${this.lineHeight.number}${suffix}`)
    }
  }
}
</script>
