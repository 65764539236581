<template>
  <div
    class="stream-components-link"
    :class="{ 'stream-components-link--empty': empty }"
    :data-link="url"
    :data-internal="internal"
    @click.stop="$emit('click')"
    @mouseenter="$emit('mouseenter')"
    @mouseleave="$emit('mouseleave')"
  >
    <slot />
  </div>
</template>

<script>
import {mapState} from "vuex";
import {getSizedPropValue} from "@/containers/Constructor/containers/Editor/utils";

export default {
  name: "LinkElement",

  props: {
    empty: {
      type: Boolean,
      default: false
    },
    component: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    ...mapState({
      activeSize: state => state.constructor2.activeSize
    }),

    url () {
      return getSizedPropValue(this.component?.props?.url, this.activeSize)
    },

    internal () {
      return getSizedPropValue(this.component?.props?.internal, this.activeSize)
    },
  },
};
</script>

<style lang="scss" src="./index.scss"></style>
