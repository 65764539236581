<template>
  <Accordion block expanded-by-default>
    <template #top>
      Form Radio Group Settings
    </template>

    <div class="stream-editor-parameter-panel__prop">
      <div class="stream-editor-parameter-panel__prop-title">Name</div>
      <ParameterInput :prop="{...activeComponent.props.name, key: 'name'}" :size="size" :active-size="size"
                      :value-type="valueType"/>
    </div>

    <div class="stream-editor-parameter-panel__prop">
      <div class="stream-editor-parameter-panel__prop-title">Default value</div>
      <ParameterInput :prop="{...activeComponent.props.defaultValue, key: 'defaultValue'}" :size="size" :active-size="size"
                      :value-type="valueType"/>
    </div>
  </Accordion>
</template>

<script>
import Accordion from '@/containers/Constructor/containers/Editor/components/Accordion'
import settings from "@/modules/constructor2/editor/parameters-panel/mixins/settings";
import ParameterInput from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/Input";

export default {
  name: "ParametersPanelSettingsFormRadioGroup",

  mixins: [settings],

  components: {
    ParameterInput,
    Accordion,
  },
}
</script>
