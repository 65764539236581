<template>
  <div class="stream-editor-parameter-panel">
    <template v-if="activeComponent">
      <C2Tabs
        v-show="tabsVisible"
        class="stream-editor-parameter-panel__tabs"
        v-model="activeTab"
        :tabs="tabs"
      />
      <div class="stream-editor-parameter-panel__header">
        <div class="stream-editor-parameter-panel__title">
          <div class="stream-editor-parameter-panel__title-text">
            <span class="stream-editor-parameter-panel__title-text-main" :title="activeComponentName">{{ activeComponentName }}</span>
            <span
              v-if="activeComponent.customName"
              class="stream-editor-parameter-panel__title-text-secondary"
              :title="activeComponent.customName"
            >
              {{ activeComponent.customName }}
            </span>
          </div>
          <div class="stream-editor-parameter-panel__title-actions">
            <button class="stream-editor-parameter-panel__title-actions-button" @click="onSaveComponent" title="Save" v-if="!activeComponent.noSave">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M2 4V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V6.31242C22 5.79148 21.7967 5.29109 21.4335 4.91771L19.1835 2.60529C18.807 2.21831 18.29 2 17.7501 2H4C2.89543 2 2 2.89543 2 4ZM4 4V20H6V13C6 11.8954 6.89543 11 8 11H16C17.1046 11 18 11.8954 18 13V20H20V6.31242L17.7501 4L16 4V6C16 7.10457 15.1046 8 14 8H10C8.89543 8 8 7.10457 8 6V4H4ZM16 20V13H8V20H16ZM10 4H14V6H10V4Z" fill="#fff"></path></svg>
            </button>
            <parameter-hidden
              v-if="activeComponent.props && activeComponent.props.hide"
              :prop="activeComponent.props.hide"
              :size="activeSize"
              :value-type="activeTab"
              class="stream-editor-parameter-panel__title-actions-button"
            />
            <button
              v-if="activeComponent.props && activeComponent.props.triggered"
              class="stream-editor-parameter-panel__title-actions-button stream-editor-parameter-panel__title-actions-button--triggered"
              :class="{'stream-editor-parameter-panel__title-actions-button--triggered--active': activeComponent.props.triggered.value && activeComponent.props.triggered.value.desktop === 'on'}"
              @click="onToggleTriggered"
              :title="activeComponent.props.triggered.value === 'on' ? 'Triggered' : 'Not triggered'"
            >
              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'">
  <polygon fill="none" stroke="#000000" stroke-width="2" points="4 14 10 14 7 23 9 23 20 9 14 9 18 1 7 1"/>
</svg>
            </button>
            <button
              v-if="!activeComponent.noManualRemove"
              class="stream-editor-parameter-panel__title-actions-button stream-editor-parameter-panel__title-actions-button--remove"
              @click="onRemove"
              title="Remove"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M8 4C7.44772 4 7 4.44772 7 5V7.5H4C3.44772 7.5 3 7.94772 3 8.5C3 9.05228 3.44772 9.5 4 9.5H5.04501L5.82486 17.2985C5.97822 18.8321 7.26872 20 8.80998 20H15.19C16.7313 20 18.0218 18.8321 18.1751 17.2985L18.955 9.5H20C20.5523 9.5 21 9.05228 21 8.5C21 7.94772 20.5523 7.5 20 7.5H17V5C17 4.44772 16.5523 4 16 4H8ZM15 7.5V6H9V7.5H15ZM7.05499 9.5H16.945L16.1851 17.0995C16.1339 17.6107 15.7038 18 15.19 18H8.80998C8.29622 18 7.86606 17.6107 7.81494 17.0995L7.05499 9.5Z" fill="#8D9CA8"></path></svg>
            </button>
          </div>
        </div>
        <component
          v-if="$options.components[`TitleParameters${activeComponent.componentKey}`]"
          :is="`TitleParameters${activeComponent.componentKey}`"
          :size="activeSize"
          :value-type="activeTab"
          class="stream-editor-parameter-panel__title-parameters"
        />
      </div>
      <component
        v-if="$options.components[`Parameters${activeComponent.componentKey}`]"
        :is="`Parameters${activeComponent.componentKey}`"
        :size="activeSize"
        :value-type="activeTab"
        class="stream-editor-parameter-panel__parameters"
      />
    </template>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {bus} from "@/app/main";

import Button from './components/Button'
import C2Tabs from '@/modules/constructor2/shared/atoms/tabs'
import ParameterHidden from "@/modules/constructor2/editor/parameters-panel/atoms/parameter-hidden";

import TitleParametersSlider from "@/modules/constructor2/editor/parameters-panel/organisms/title-parameters-slider";
import TitleParametersYoutube from "@/modules/constructor2/editor/parameters-panel/organisms/title-parameters-youtube";
import TitleParametersBubbles from "@/modules/constructor2/editor/parameters-panel/organisms/title-parameters-bubbles";
import TitleParametersTableRow from "@/modules/constructor2/editor/parameters-panel/organisms/title-parameters-table-row";
import TitleParametersTable from "@/modules/constructor2/editor/parameters-panel/organisms/title-parameters-table";
import TitleParametersFormSelectDropdown from "@/modules/constructor2/editor/parameters-panel/organisms/title-parameters-form-select-dropdown";
import ParametersSlider from "@/modules/constructor2/editor/parameters-panel/organisms/parameters-slider";
import ParametersRow from "@/modules/constructor2/editor/parameters-panel/organisms/parameters-row";
import ParametersColumn from "@/modules/constructor2/editor/parameters-panel/organisms/parameters-column";
import ParametersImage from "@/modules/constructor2/editor/parameters-panel/organisms/parameters-image";
import ParametersText from "@/modules/constructor2/editor/parameters-panel/organisms/parameters-text";
import ParametersVideo from "@/modules/constructor2/editor/parameters-panel/organisms/parameters-video";
import ParametersYoutube from "@/modules/constructor2/editor/parameters-panel/organisms/parameters-youtube";
import ParametersAccordionHeader from "@/modules/constructor2/editor/parameters-panel/organisms/parameters-accordion-header";
import ParametersAccordionContent from "@/modules/constructor2/editor/parameters-panel/organisms/parameters-accordion-content";
import ParametersAccordion from "@/modules/constructor2/editor/parameters-panel/organisms/parameters-accordion";
import ParametersBubble from "@/modules/constructor2/editor/parameters-panel/organisms/parameters-bubble";
import ParametersBubbles from "@/modules/constructor2/editor/parameters-panel/organisms/parameters-bubbles";
import ParametersTableColumn from "@/modules/constructor2/editor/parameters-panel/organisms/parameters-table-column";
import ParametersTable from "@/modules/constructor2/editor/parameters-panel/organisms/parameters-table";
import ParametersSlide from "@/modules/constructor2/editor/parameters-panel/organisms/parameters-slide";
import ParametersLink from "@/modules/constructor2/editor/parameters-panel/organisms/parameters-link";
import ParametersPopoverHeader
  from "@/modules/constructor2/editor/parameters-panel/organisms/parameters-popover-header";
import ParametersPopoverContent
  from "@/modules/constructor2/editor/parameters-panel/organisms/parameters-popover-content";
import ParametersPopover from "@/modules/constructor2/editor/parameters-panel/organisms/parameters-popover";
import ParametersScript from "@/modules/constructor2/editor/parameters-panel/organisms/parameters-script";
import TitleParametersScript from "@/modules/constructor2/editor/parameters-panel/organisms/title-parameters-script";
import ParametersThe360ViewerLoader
  from "@/modules/constructor2/editor/parameters-panel/organisms/parameters-the-360-viewer-loader";
import ParametersThe360ViewerAdditionalContent
  from "@/modules/constructor2/editor/parameters-panel/organisms/parameters-the-360-viewer-additional-content";
import ParametersThe360Viewer from "@/modules/constructor2/editor/parameters-panel/organisms/parameters-the-360-viewer";
import ParametersForm from "@/modules/constructor2/editor/parameters-panel/organisms/parameters-form";
import ParametersFormButton from "@/modules/constructor2/editor/parameters-panel/organisms/parameters-form-button";
import ParametersFormInput from "@/modules/constructor2/editor/parameters-panel/organisms/parameters-form-input";
import ParametersFormError from "@/modules/constructor2/editor/parameters-panel/organisms/parameters-form-error";
import ParametersFormCheckbox from "@/modules/constructor2/editor/parameters-panel/organisms/parameters-form-checkbox";
import ParametersFormRadioGroup from "@/modules/constructor2/editor/parameters-panel/organisms/parameters-form-radio-group";
import ParametersFormRadioButton from "@/modules/constructor2/editor/parameters-panel/organisms/parameters-form-radio-button";
import ParametersFormSelect from "@/modules/constructor2/editor/parameters-panel/organisms/parameters-form-select";
import ParametersFormSelectSelector from "@/modules/constructor2/editor/parameters-panel/organisms/parameters-form-select-selector";
import ParametersFormSelectDropdown from "@/modules/constructor2/editor/parameters-panel/organisms/parameters-form-select-dropdown";
import ParametersFormSelectOption from "@/modules/constructor2/editor/parameters-panel/organisms/parameters-form-select-option";

export default {
  name: "ParametersPanel",

  components: {
    Button,
    C2Tabs,
    ParameterHidden,

    TitleParametersSlider,
    TitleParametersYoutube,
    TitleParametersBubbles,
    TitleParametersTableRow,
    TitleParametersTable,
    TitleParametersScript,
    TitleParametersFormSelectDropdown,
    ParametersSlider,
    ParametersSlide,
    ParametersRow,
    ParametersColumn,
    ParametersImage,
    ParametersText,
    ParametersFormError,
    ParametersVideo,
    ParametersYoutube,
    ParametersAccordionHeader,
    ParametersAccordionContent,
    ParametersAccordion,
    ParametersBubble,
    ParametersBubbles,
    ParametersTableColumn,
    ParametersTable,
    ParametersLink,
    ParametersPopoverHeader,
    ParametersPopoverContent,
    ParametersPopover,
    ParametersScript,
    ParametersThe360ViewerLoader,
    ParametersThe360ViewerAdditionalContent,
    ParametersThe360Viewer,
    ParametersForm,
    ParametersFormButton,
    ParametersFormInput,
    ParametersFormCheckbox,
    ParametersFormRadioGroup,
    ParametersFormRadioButton,
    ParametersFormSelect,
    ParametersFormSelectSelector,
    ParametersFormSelectDropdown,
    ParametersFormSelectOption,
  },

  data () {
    return {
      tabs: [
        {key: 'general', label: 'General'},
        {key: 'hover', label: 'Hover'},
      ],
      activeTab: 'general',
    }
  },

  watch: {
    activeComponent (n, o) {
      if (!o || !n || n?.id !== o?.id) {
        this.activeTab = 'general'
      }
    }
  },

  computed: {
    ...mapState({
      activeComponent: state => state.constructor2.activeComponent,
      activeSize: state => state.constructor2.activeSize,
    }),

    activeComponentName () {
      return this.activeComponent
        ? this.activeComponent.displayName || this.activeComponent.name || ''
        : ''
    },

    tabsVisible () {
      return (
        this.activeComponent
          && !this.activeComponent.noHover
      )
    }
  },

  methods: {
    ...mapMutations({
      removeComponent: 'constructor2/removeComponent',
      updateActiveComponentProp: 'constructor2/updateActiveComponentProp',
    }),

    onRemove () {
      this.removeComponent(this.activeComponent)
    },

    onSaveComponent () {
      bus.$emit('save-component', this.activeComponent)
    },

    onToggleTriggered() {
      this.updateActiveComponentProp({
        prop: { ...this.activeComponent.props.triggered, key: 'triggered' },
        updatedProp: {
          ...this.activeComponent.props.triggered,
          value: { desktop: this.activeComponent.props.triggered.value?.desktop === 'on' ? 'off' : 'on' }
        }
      })
    }
  }
};
</script>

<style lang="scss" src="./index.scss"></style>
