<template>
  <tab-select
    class="parameter-tab-select"
    :value="{ id: value || placeholderValue, content: value || placeholderValue }"
    :items="items || prop.items.map(i => ({ id: i, content: i }))"
    @input="updateProp($event.id)"
  />
</template>

<script>
import parameterItem from "../mixins/parameterItem";
import TabSelect from '@/containers/Constructor/components/TabSelect';

export default {
  name: "ParameterTabSelect",

  props: {
    items: {
      type: Array,
      default: undefined
    }
  },

  components: {
    TabSelect,
  },

  mixins: [ parameterItem ]
}
</script>
