<template>
  <form
    class="stream-components-form"
    :class="{ 'stream-components-form--empty': empty }"
    @click.stop="$emit('click')"
    @mouseenter="$emit('mouseenter')"
    @mouseleave="$emit('mouseleave')"
  >
    <slot />
  </form>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "FormElement",

  props: {
    empty: {
      type: Boolean,
      default: false
    },
    component: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    ...mapState({
      activeSize: state => state.constructor2.activeSize
    }),
  }
};
</script>

<style lang="scss" src="./index.scss"></style>
