<template>
  <div class="number-input-select">
    <Input
      class="number-input-select__input"
      :value="inputValue"
      @change="$emit('change', $event)"
      :debounce-ms="250"
    />
    <Select
      :value="selectValue"
      :items="selectItems"
      @input="$emit('select-change', $event)"
    />
  </div>
</template>

<script>
import Select from '../Select'
import Input from '../Input'

export default {
  name: "NumberInputSelect",

  components: {
    Select,
    Input,
  },

  props: {
    inputValue: {
      type: String,
      default: ''
    },
    selectValue: {
      type: String,
      default: ''
    },
    selectItems: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
