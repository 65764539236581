<template>
  <Accordion block expanded-by-default>
    <template #top>
      Popover Settings
    </template>

    <div class="stream-editor-parameter-panel__prop">
      <div class="stream-editor-parameter-panel__prop-title">Trigger</div>
      <ParameterTabSelect :prop="{...activeComponent.props.trigger, key: 'trigger'}" :size="size" :active-size="size" :value-type="valueType" />
    </div>
  </Accordion>
</template>

<script>
import Accordion from '@/containers/Constructor/containers/Editor/components/Accordion'
import ParameterTabSelect
  from "@/containers/Constructor/containers/Editor/containers/ParametersPanel/containers/TabSelect";
import settings from "@/modules/constructor2/editor/parameters-panel/mixins/settings";

export default {
  name: "ParametersPanelSettingsPopover",

  mixins: [settings],

  components: {
    ParameterTabSelect,
    Accordion,
  },
}
</script>
