<template>
  <component :is="isComponent" :component="formattedComponent" v-if="isBaseComponent || isFragment">
    <template v-if="children.length">
      <WorkareaComponent
        v-for="(componentChild, index) in children"
        :key="`${componentChild.id}-${componentChild.parentId}-${componentChild.order}-${index}`"
        :component="componentChild"
        :dynamic-data-id="dynamicDataId || componentChild.dynamicDataId"
        :dynamic-order="dynamicOrder || componentChild.dynamicOrder"
      />
    </template>
  </component>
</template>

<script>
import { Fragment } from 'vue-frag'
import {BASE_COMPONENT_KEYS} from "@/store/modules/constructor2/components/consts";
import {
  extractFromJsonByString,
  getPropValueBySize,
} from "@/containers/Constructor/containers/Editor/utils";
import {mapState, mapGetters} from "vuex";
import findInUpperNestedComponents from "@/containers/Constructor/containers/Editor/mixins/findInUpperNestedComponents";
import hasPropValueInChildren from "@/containers/Constructor/containers/Editor/mixins/hasPropValueInChildren";
import BaseComponent from './containers/BaseComponent'
import dcopy from "deep-copy";

export default {
  name: "WorkareaComponent",

  components: {
    BaseComponent,
    Fragment
  },

  mixins: [findInUpperNestedComponents, hasPropValueInChildren],

  props: {
    component: {
      type: Object,
      default: null
    },
    dynamicDataId: {
      type: [String, Number],
      default: null
    },
    dynamicOrder: {
      type: [String, Number],
      default: null
    }
  },

  computed: {
    ...mapState({
      activeSize: state => state.constructor2.activeSize
    }),
    ...mapGetters({
      selectedDataSources: 'constructor2/selectedDataSources',
      addedComponents: 'constructor2/addedComponents',
    }),

    formattedComponent () {
      if (this.component?.isDynamic && this.component.dynamicType === 'DynamicDataReceiver') {
        const dynamicDataProvider = this.addedComponents[this.component.dynamicProviderIndex]
        const dynamicDataReceiver = this.addedComponents[this.component.dynamicIndex]
        const dataSourceId = getPropValueBySize(dynamicDataProvider.props.dataSource.value, this.activeSize)
          || (this.selectedDataSources ? (Object.keys(this.selectedDataSources)[0]) : undefined)
        const providedDataSourcesItem = this.selectedDataSources?.[dataSourceId]?.items?.[this.dynamicDataId]
        if (providedDataSourcesItem) {
          let content = getPropValueBySize(dynamicDataReceiver.props.content.value, this.activeSize) || ''
          const hideOnEmptyData = getPropValueBySize(dynamicDataReceiver.props.hideOnEmptyData.value, this.activeSize) === 'on' || false
          const fallbackOnEmptyData = getPropValueBySize(dynamicDataReceiver.props.fallbackOnEmptyData.value, this.activeSize) === 'on' || false
          const boundDataInstancesInContent = content.match(/\{\{(.*?)}}/g) || []
          let isDataEmpty = content === ''
          boundDataInstancesInContent.forEach(ins => {
            if (ins !== '{{}}') {
              const extractedData = extractFromJsonByString(
                providedDataSourcesItem,
                ins
                  .replace('{{', '')
                  .replace('}}', '')
              )
              if (extractedData === undefined || extractedData === null) {
                isDataEmpty = true
              } else {
                content = content.replaceAll(ins, extractedData)
              }
            }
          })
          if (isDataEmpty && hideOnEmptyData) {
            return []
          } else if (isDataEmpty && fallbackOnEmptyData) {
            return [{...this.component, dynamicOrder: this.dynamicOrder, isDynamic: false}]
          }
          const updatedProps = dcopy(this.component.props)
          switch (this.component.name) {
            case 'Text':
              updatedProps.content.value = content
              break
            case 'Image':
              updatedProps.src.value = content
              break
            case 'Video':
              updatedProps.src.value = content
              break
            case 'Youtube':
              updatedProps.youtubeId.value = content
              break
            case 'Link':
              updatedProps.url.value = content
              break
            default:
              break
          }
          return {...this.component, dynamicOrder: this.dynamicOrder, isDynamic: false, props: { ...updatedProps }}
        }
      }
      return (this.component && { ...this.component, dynamicOrder: this.dynamicOrder, children: this.children }) || undefined
    },

    isBaseComponent () {
      return this.formattedComponent.isBaseComponent || !this.formattedComponent.customRender && BASE_COMPONENT_KEYS.includes(this.formattedComponent?.name || '')
    },

    isFragment () {
      return this.formattedComponent?.isFragment ||
        (this.formattedComponent?.isDynamic
        && this.formattedComponent.dynamicType === 'DynamicDataProvider'
        && this.formattedComponent.name !== 'Slider')
    },

    isComponent () {
      if (this.isFragment) return 'fragment'
      if (this.isBaseComponent) return 'base-component'
      return 'div'
    },

    children () {
      if (this.component.isDynamic) {
        if (this.component.dynamicType === 'DynamicDataProvider') {
          const dataProviderComponent = this.addedComponents[this.component.dynamicIndex]
          const providedDataSourcesIds =
            getPropValueBySize(dataProviderComponent.props.providedDataSourceItems.value, this.activeSize) || []
          if (providedDataSourcesIds instanceof Array && providedDataSourcesIds.length) {
            const componentToDuplicate = this.component.name === 'Slider' ? this.component.children?.[0] : this.component
            const dataSourceId = getPropValueBySize(dataProviderComponent.props.dataSource.value, this.activeSize)
              || (this.selectedDataSources ? (Object.keys(this.selectedDataSources)[0]) : undefined)
            const providedDataSourcesItems = providedDataSourcesIds
              .map(sid => this.selectedDataSources?.[dataSourceId]?.items?.[sid]).filter(item => item) || []
            return providedDataSourcesItems.map((item, index) => ({
              ...componentToDuplicate,
              isDynamic: false,
              dynamicDataId: item.id,
              order: index + 1,
              dynamicOrder: index + 1
            }))
          } else {
            return [{
              ...this.component,
              dynamicOrder: this.dynamicOrder,
              isDynamic: false
            }]
          }
        }
      }
      return this.component.children || []
    }
  }
};
</script>
