<template>
  <div
    class="stream-components-form-checkbox"
    @click.stop="onInput"
    @mouseenter="$emit('mouseenter')"
    @mouseleave="$emit('mouseleave')"
  >
    <div class="stream-components-form-checkbox__content">
      <input
        class="stream-components-form-checkbox__input"
        type="checkbox"
        :name="name"
        v-model="localValue"
      />
      <div
        class="stream-components-form-checkbox__square"
        :style="squareStyle"
      >
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8 12L11 15L16 9" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
      </div>
      <workarea-component
        :component="{...component.children[0], customRender: false}"
        :id="`stream-components-form-error-${component.children[0].id}`"
      />
    </div>
    <slot />
  </div>
</template>

<script>
import {mapState} from "vuex";
import {getSizedPropValue} from "@/containers/Constructor/containers/Editor/utils";
import {debounce} from "lodash";
import validateFormItem from "@/modules/constructor2/editor/shared/utils/validate-form-item";

export default {
  name: "FormCheckboxElement",

  components: {
    WorkareaComponent: () => import("@/containers/Constructor/containers/Editor/containers/EditorPanel/containers/WorkareaComponents/containers/Component")
  },

  props: {
    component: {
      type: Object,
      default: () => {}
    },
    passedStyle: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      localValue: false
    };
  },

  computed: {
    ...mapState({
      activeSize: state => state.constructor2.activeSize
    }),

    name () {
      return getSizedPropValue(this.component?.props?.name, this.activeSize) || undefined
    },

    activeColor () {
      return getSizedPropValue(this.component?.props?.activeColor, this.activeSize) || undefined
    },

    inactiveColor () {
      return getSizedPropValue(this.component?.props?.inactiveColor, this.activeSize) || undefined
    },

    squareStyle() {
      return {
        borderRadius: getSizedPropValue(this.component?.props?.boxBorderRadius, this.activeSize) || undefined,
        border: getSizedPropValue(this.component?.props?.boxBorder, this.activeSize) || undefined,
        width: getSizedPropValue(this.component?.props?.boxWidth, this.activeSize) || undefined,
        height: getSizedPropValue(this.component?.props?.boxHeight, this.activeSize) || undefined,
        backgroundColor: this.localValue ? this.activeColor : this.inactiveColor,
        '--tick-color': getSizedPropValue(this.component?.props?.tickColor, this.activeSize) || undefined,
        '--tick-width': getSizedPropValue(this.component?.props?.tickWidth, this.activeSize) || undefined,
        '--tick-height': getSizedPropValue(this.component?.props?.tickHeight, this.activeSize) || undefined,
      }
    }
  },

  watch: {
    activeSize() {
      this.debouncedValidate()
    }
  },

  methods: {
    onInput() {
      this.$emit('click')
      this.localValue = !this.localValue
      this.debouncedValidate()
    },

    debouncedValidate: debounce(function () {
      validateFormItem(this.localValue, this.component, this.activeSize, 1)
    }, 400),
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
