<template>
  <div class="stream-editor-add-component-item">
    <div class="stream-editor-add-component-item__name" @click="add()" :title="component.customName ? componentName : undefined">
      {{ componentName }}
    </div>
    <button class="stream-editor-add-component-item__button" @click="add()" v-if="canBeAddedOutside">
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8 12L11 15L16 9" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
    </button>
    <button
      class="stream-editor-add-component-item__button stream-editor-add-component-item__button--arrow"
      @click="add(true)"
      v-if="canBeAddedInside"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="338.88" height="339.18" viewBox="0 0 338.88 339.18" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g id="g3744"><path id="path3746" d="m338.88 84.343v254.84h-254.84l-84.04-84.04h193.84l-190.45-190.45 61.674-61.676 189.78 189.77v-192.78l84.04 84.343z" style="fill: rgb(0, 0, 0);"></path></g></svg>
    </button>
    <button
      class="stream-editor-add-component-item__button stream-editor-add-component-item__button--trash"
      @click="removeCustomComponent(component.customName)"
      v-if="custom"
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M8 4C7.44772 4 7 4.44772 7 5V7.5H4C3.44772 7.5 3 7.94772 3 8.5C3 9.05228 3.44772 9.5 4 9.5H5.04501L5.82486 17.2985C5.97822 18.8321 7.26872 20 8.80998 20H15.19C16.7313 20 18.0218 18.8321 18.1751 17.2985L18.955 9.5H20C20.5523 9.5 21 9.05228 21 8.5C21 7.94772 20.5523 7.5 20 7.5H17V5C17 4.44772 16.5523 4 16 4H8ZM15 7.5V6H9V7.5H15ZM7.05499 9.5H16.945L16.1851 17.0995C16.1339 17.6107 15.7038 18 15.19 18H8.80998C8.29622 18 7.86606 17.6107 7.81494 17.0995L7.05499 9.5Z" fill="#8D9CA8"></path></svg>
    </button>
  </div>
</template>

<script>
import {mapState, mapMutations} from "vuex";
import {BASE_COMPONENT_KEYS, DYNAMIC_DATA_COMPONENT_KEYS_OBJ} from "@/store/modules/constructor2/components/consts";
import findInUpperNestedComponents from "@/containers/Constructor/containers/Editor/mixins/findInUpperNestedComponents";

export default {
  name: "AddComponentItem",

  mixins: [ findInUpperNestedComponents ],

  props: {
    component: {
      type: Object,
      default: () => {}
    },
    custom: Boolean
  },

  computed: {
    ...mapState({
      activeComponent: state => state.constructor2.activeComponent,
    }),

    requiredUpperElementsSatisfied() {
      if (this.activeComponent && this.component.requiredUpperElements) {
        const allRequiredExistInUpper = this.component.requiredUpperElements.every(el =>
          this.activeComponent.componentKey === el || !!this.findInUpperNestedComponents(el, this.activeComponent, 'componentKey'))
        return {
          inside: allRequiredExistInUpper,
          outside: allRequiredExistInUpper && this.component.requiredUpperElements.every(el => this.activeComponent.componentKey !== el)
        }
      } else if (this.component.requiredUpperElements) return {
        inside: false,
        outside: false
      }
      return {
        inside: true,
        outside: true
      }
    },

    prohibitedUpperElementsSatisfied() {
      if (this.activeComponent && this.component.prohibitedUpperElements) {
        const allProhibitedDontExistInUpper = this.component.prohibitedUpperElements.every(el =>
          !this.findInUpperNestedComponents(el, this.activeComponent, 'componentKey'))
        return {
          inside: allProhibitedDontExistInUpper && this.component.prohibitedUpperElements.every(el => this.activeComponent.componentKey !== el),
          outside: allProhibitedDontExistInUpper
        }
      }
      return {
        inside: true,
        outside: true
      }
    },

    canBeAddedInside () {
      return (this.requiredUpperElementsSatisfied.inside && this.prohibitedUpperElementsSatisfied.inside && this.activeComponent?.withChildren) || false
    },

    canBeAddedOutside () {
      if (this.requiredUpperElementsSatisfied.outside && this.prohibitedUpperElementsSatisfied.outside) {
        if (this.activeComponent) {
          let parentComponent = this.activeComponent.parentId ? this.addedComponents.find(c => c.id === this.activeComponent.parentId) : undefined
          if (parentComponent && DYNAMIC_DATA_COMPONENT_KEYS_OBJ[parentComponent.name])
            parentComponent = parentComponent.parentId ? this.addedComponents.find(c => c.id === parentComponent.parentId) : undefined
          if (parentComponent && !parentComponent.withChildren) {
            return false
          }
        }
        return true
      }
      return false
    },

    componentName() {
      return `${
        DYNAMIC_DATA_COMPONENT_KEYS_OBJ[this.component.name]
          ? this.component.children[0].displayName || this.component.children[0].name
          : this.component.displayName || this.component.name
       }${
        this.component.customName
          ? ` (${this.component.customName})`
          : ''
      }`
    }
  },

  methods: {
    ...mapMutations({
      addComponent: "constructor2/addComponent",
      removeCustomComponent: "constructor2/removeCustomComponent",
    }),

    add (inside = false) {
      if (!this.canBeAddedOutside && this.canBeAddedInside) inside = true
      if (this.canBeAddedOutside || this.canBeAddedInside)
        this.addComponent({
          customName: this.component.customName,
          isCustom: this.custom,
          componentKey: this.component.componentKey,
          insideActive: inside
        })
    },
  }
};
</script>

<style lang="scss" src="./index.scss"></style>
