<template>
  <div class="stream-epack-data">
    <div class="stream-epack-data__header">
      <div class="stream-epack-data__title">EPACK DATA</div>
      <div class="stream-epack-data__header-actions">
        <constructor2-change-owner
          v-if="$route.params.id !== 'untitled'"
          :epack-name="productName"
          :epack-id="$route.params.id"
          @after-transfer="onAfterTransferOwnership"
        />
      </div>
    </div>
    <div class="stream-epack-data__fields">
      <div class="stream-epack-data__fields-item">
        <div class="stream-epack-data__fields-item-label">Name</div>
        <Input :value="productName" @debounced-input="onInput($event, 'productName', true)" />
      </div>
      <div class="stream-epack-data__fields-item">
        <va-select
          class="input--epack-data"
          label="Brand"
          :clearable="false"
          :value="brandName"
          @input="onInput($event, 'brandName', true)"
          :options="brandsList"
          noClear
          searchable
        />
      </div>
      <div class="stream-epack-data__fields-item">
        <va-select
          class="input--epack-data"
          label="Type"
          :clearable="false"
          :value="type"
          @input="changeEpackDataType($event.key)"
          :options="AVAILABLE_EPACK_TYPES_LIST"
          :disabled="epackageExists"
          text-by="name"
          key-by="key"
          noClear
        />
      </div>
      <div class="stream-epack-data__fields-item" v-if="typeKey === AVAILABLE_EPACK_TYPES.sis.key">
        <div class="stream-epack-data__fields-item-label">Mapping ID</div>
        <Input :value="mappingId" @debounced-input="onInput($event, 'mappingId', true)" />
      </div>
      <template v-if="typeKey === AVAILABLE_EPACK_TYPES.minisite.key">
        <div class="stream-epack-data__fields-item">
          <div class="stream-epack-data__fields-item-label">EAN</div>
          <Input :value="ean" @debounced-input="onInput($event, 'EAN', true)" />
        </div>
        <div class="stream-epack-data__fields-item">
          <div class="stream-epack-data__fields-item-label">MPN</div>
          <Input :value="mpn" @debounced-input="onInput($event, 'MPN', true)" />
        </div>
      </template>
      <div class="stream-epack-data__fields-item">
        <div class="stream-epack-data__fields-item-label">Version</div>
        <Input :value="version" @debounced-input="onInput($event, 'version', false)" />
      </div>
      <div class="stream-epack-data__fields-item" v-if="typeKey === AVAILABLE_EPACK_TYPES.minisite.key">
        <div class="stream-epack-data__fields-item-label">Categories</div>
        <treeselect
          :value="categories"
          @input="onInput($event, 'categories', true)"
          clearable
          :close-on-select="false"
          async
          multiple
          isDefaultExpanded
          :load-options="loadSearchCategories"
          searchable
          value-consists-of="LEAF_PRIORITY"
          valueFormat="object"
        >
          <label slot="option-label" slot-scope="{ node, labelClassName }" :class="labelClassName" :title="node.label">
            {{ node.label }}
          </label>
        </treeselect>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import Constructor2ChangeOwner from "@/components/constructor2/ChangeOwner";
import Input from '../Editor/containers/ParametersPanel/components/Input';
import {AVAILABLE_EPACK_TYPES, AVAILABLE_EPACK_TYPES_LIST} from "@/store/modules/constructor2/consts";
import treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.min.css';
import API from "@/services/API/API";
import {debounce} from "lodash";

const listToTree = (array) => {
  const indexToDell = array.findIndex(elem => elem.id === 1);
  if(indexToDell !== -1) {
    array.splice(indexToDell,1);
  }
  array.sort((a, b) => {
    if (a.label.toLowerCase() > b.label.toLowerCase()) {
      return 1;
    }
    if (a.label.toLowerCase() < b.label.toLowerCase()) {
      return -1;
    }
    return 0;
  });

  array.forEach((i) => {
    if (i.id !== 1) {

      i.label = i.label ? i.label : i.name;
      i.children = array.filter((j) => j.parentId === i.id);
      if (!i.children.length) {
        delete i.children;
      }
    }
  });

  let parentIds = array.map((i) => i.id);
  return JSON.parse(
    JSON.stringify(array.filter((i) => !parentIds.includes(i.parentId)))
  );
}

export default {
  name: "ConstructorEpackData",

  components: {
    Constructor2ChangeOwner,
    Input,
    treeselect
  },

  computed: {
    ...mapState({
      epackData: (state) => state.constructor2.epackData,
    }),

    AVAILABLE_EPACK_TYPES() { return AVAILABLE_EPACK_TYPES },
    AVAILABLE_EPACK_TYPES_LIST() { return AVAILABLE_EPACK_TYPES_LIST },

    epackageExists() {
      return this.$route.params.id !== 'untitled'
    },

    brandsList: function () {
      const brands = JSON.parse(localStorage.getItem('ttlUserInfo')).brands;
      const brandlist = brands.map(elem =>(elem.name));
      brandlist.sort((a,b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1));
      return brandlist;
    },

    productName() {
      return this.epackData.manifest.productData.productName
    },

    brandName() {
      return this.epackData.manifest.productData.brandName
    },

    mappingId() {
      return this.epackData.manifest.productData.mappingId
    },

    version() {
      return this.epackData.manifest.version
    },

    ean() {
      return this.epackData.manifest.productData.EAN
    },

    mpn() {
      return this.epackData.manifest.productData.MPN
    },

    categories() {
      return this.epackData.manifest.productData.categories || []
    },

    type() {
      return AVAILABLE_EPACK_TYPES[this.epackData.type] || AVAILABLE_EPACK_TYPES.sis
    },

    typeKey() {
      return this.type?.key
    }
  },

  methods: {
    ...mapMutations({
      updateEpackDataManifest: 'constructor2/updateEpackDataManifest',
      changeEpackDataType: 'constructor2/changeEpackDataType',
      set: 'constructor2/set',
    }),

    onInput (value, propName, isProductData) {
      this.updateEpackDataManifest({value, propName, isProductData})
    },

    onAfterTransferOwnership () {
      this.$router.push('/panel/builder-pro')
    },

    loadSearchCategories({ action, searchQuery, callback }) {
      if (action === 'ASYNC_SEARCH') {
        this.handleLoadSearchCategories(searchQuery, callback);
      }
    },

    dFn: debounce((searchQuery, callback) => {
      API.APIGet(`${process.env.VUE_APP_API_URL}/category/tree-for-epackages?search=${searchQuery}`, {}, (response) => {
        const treeList = listToTree(response);
        callback(null, treeList);
      })
    },1500),

    handleLoadSearchCategories(searchQuery, callback) {
      this.dFn(searchQuery, callback);
    },
  }
};
</script>

<style lang="scss" src="./index.scss"></style>
