<template>
  <div
    class="stream-components-bubbles-modal"
    :class="[
      `stream-${position}`,
      isEmpty && ['stream--empty', 'stream-components-bubbles-modal--empty']
    ]"
  >
    <slot />
    <button class="stream-components-bubbles-modal__close" @click.stop="$emit('close')" />
  </div>
</template>

<script>
export default {
  name: "BubblesModalElement",

  props: {
    position: {
      type: String,
      default: 'left-bottom'
    }
  },

  computed: {
    isEmpty () {
      return !this.$slots.default
    },
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
