export default {
  methods: {
    getPreviewLink ({ activeLocale, activeTemplate, productName, epackId, epackType, page }) {
      return this.$router.resolve({
        name: "constructor2-preview",
        query: { activeLocale, activeTemplate, productName, epackId, epackType, __ttl__page__: page },
      }).href
    }
  }
}
