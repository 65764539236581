<template>
  <Input
    :value="value"
    :type="inputType"
    :min="minValue"
    :placeholder="placeholderValue"
    @change="updateProp($event)"
  />
</template>

<script>
import parameterItem from "../mixins/parameterItem";
import Input from "../components/Input";

export default {
  name: "ParameterInput",

  components: {
    Input,
  },

  mixins: [ parameterItem ],

  computed: {
    inputType () {
      return this.prop.inputType || 'text'
    },

    minValue () {
      return this.prop.minValue || undefined
    }
  }
}
</script>
