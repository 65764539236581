<template>
  <tab-select
    class="c2-select-tab"
    :items="availableTabs"
    :value="activeTab"
    @input="set({ stateProp: 'activeTab', value: $event.id })"
  />
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {AVAILABLE_TABS} from "@/store/modules/constructor2/consts";
import ControlItem from "../../components/ControlItem";
import TabSelect from '@/containers/Constructor/components/TabSelect';

export default {
  name: "SelectTab",

  components: {
    TabSelect
  },

  computed: {
    ...mapState({
      activeTabRaw: state => state.constructor2.activeTab
    }),

    activeTab () {
      return this.availableTabs.find(t => t.id === this.activeTabRaw)
    },

    availableTabs: () => Object.entries(AVAILABLE_TABS).map(([id, content]) => ({ content, id }))
  },

  methods: {
    ...mapMutations({
      set: 'constructor2/set'
    })
  }
};
</script>

<style lang="scss" src="./index.scss"></style>
